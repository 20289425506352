import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useInvoices } from '~/api/invoices';
import { ErrorBoundaryMessage, ErrorBoundaryWrapper } from '~/components';
import { Breadcrumbs, PageTitle } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

export const InvoiceDetailErrorBoundary = () => {
  const { data: invoices } = useInvoices();
  const { invoiceId } = useParams<Params<typeof routes.showInvoice>>();
  const invoice = invoices.find((invoice) => invoice.id === Number(invoiceId));

  const { t } = useTranslation(['common', 'invoices']);

  return (
    <ErrorBoundaryWrapper>
      <Breadcrumbs>
        <Breadcrumbs.Item to={routes.invoices}>{t('common:breadcrumbs.invoices')}</Breadcrumbs.Item>
        <Breadcrumbs.Item>{t('common:breadcrumbs.invoiceDetails')}</Breadcrumbs.Item>
      </Breadcrumbs>

      <PageTitle title={invoice?.title ?? t('common:breadcrumbs.invoiceDetails')} />

      <ErrorBoundaryMessage>{t('invoices:details.errorBoundaryTitle')}</ErrorBoundaryMessage>
    </ErrorBoundaryWrapper>
  );
};
