import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import type { Address, OrganisationClient } from '~/api/clients/types';

import { CountryFlag } from '~/components';
import { Flex, Span } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { formatVatNumber } from '~/utils/string';

import { styles } from './OrganisationInfoCard.styles';

export const OrganisationInfoCard = ({
  organisation,
}: {
  organisation: {
    address: Omit<Address, 'type'> | null;
    companyName: OrganisationClient['name'];
    otherNumber: string | null;
    vatNumber: string | null;
  };
}) => {
  const { displayNameOfCountry } = useIntl();
  const { t } = useTranslation(['clients']);

  return (
    <article {...stylex.props(styles.article)}>
      <div {...stylex.props(styles.generalInfo)}>
        <Span weight="semiBold">{organisation.companyName}</Span>
        {organisation.vatNumber && <Span>{formatVatNumber(organisation.vatNumber)}</Span>}
        {organisation.otherNumber && <Span>{organisation.otherNumber}</Span>}
      </div>

      {organisation.address && (
        <div {...stylex.props(styles.addressInfo)}>
          <Span weight="semiBold">{t('clients:addressType.headquarters')}</Span>
          <Span>
            {organisation.address.street} {organisation.address.number}
          </Span>
          <Span>
            {organisation.address.zipCode} {organisation.address.city}
          </Span>
          <Flex alignItems="center" gap="xxsmall">
            <CountryFlag countryCode={organisation.address.countryCode} />
            <Span styles={styles.addressInfoCountryName}>{displayNameOfCountry(organisation.address.countryCode)}</Span>
          </Flex>
        </div>
      )}
    </article>
  );
};
