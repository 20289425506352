import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type { Brand } from '~/types/user';

import { useUpdateBrand, useUser } from '~/api/user';
import { DropdownMenu } from '~/components';
import { AnchorTargets } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { routes } from '~/providers/RouterProvider/router.routes';

import { EditBrandModal } from './CreateEditBrandModal/EditBrandModal';
import { DeleteBrandModal } from './DeleteBrandModal/DeleteBrandModal';

export const BrandsTableOptions = ({ brand }: { brand: Brand }) => {
  const { data: user } = useUser();
  const mutation = useUpdateBrand(brand.id);

  const editBrandModal = useModal();
  const deleteBrandModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'settings']);

  const onUpdateFavoriteBrand = (isFavorite: boolean) => {
    mutation.mutate(
      {
        companyName: brand.companyName,
        email: brand.email,
        color: brand.color,
        logo: null,
        isFavorite,
      },
      {
        onSuccess: () => toast.success(t('settings:alerts.brands.successFavorite')),
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        isLoading={mutation.isPending}
        items={
          brand.id === 0
            ? [
                {
                  iconName: user.logo ? 'Edit' : 'Add',
                  text: user.logo ? t('settings:profile.brands.logoEdit') : t('settings:profile.brands.logoAdd'),
                  onClick: () => navigate({ pathname: routes.settingsGeneral, hash: AnchorTargets.GENERAL_INVOICE_THEME }),
                },
              ]
            : [
                { iconName: 'Edit', text: t('common:edit'), onClick: editBrandModal.open },
                {
                  iconName: 'Star',
                  text: brand.isFavorite
                    ? t('settings:profile.brands.favorite.removeFavorite')
                    : t('settings:profile.brands.favorite.markAsFavorite'),
                  onClick: () => onUpdateFavoriteBrand(!brand.isFavorite),
                },
                { iconName: 'Delete', text: t('common:delete'), onClick: deleteBrandModal.open },
              ]
        }
      />

      {editBrandModal.isOpen && <EditBrandModal brand={brand} onClose={editBrandModal.close} />}
      {deleteBrandModal.isOpen && <DeleteBrandModal brand={brand} onClose={deleteBrandModal.close} />}
    </>
  );
};
