import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type { Invoice } from '~/types/invoice';

import { BusinessConflict, BusinessConflictError } from '~/api/errors';
import { useAcquireInvoiceLock, useDownloadInvoice, useDuplicateInvoice, usePreviewInvoice } from '~/api/invoices';
import { SearchParamKeys } from '~/constants/url';
import { useAwaitableModal, useModalWithData } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

export const useOnShowInvoice = (invoice: Invoice) => {
  const navigate = useNavigate();

  const onShow = useCallback(() => navigate(r(routes.showInvoice, { invoiceId: invoice.id })), [invoice.id, navigate]);

  return useMemo(() => ({ onShow }), [onShow]);
};

export const useOnEditInvoice = (invoice: Invoice) => {
  const mutation = useAcquireInvoiceLock();
  const { mutate } = mutation;

  const confirmEditModal = useAwaitableModal<boolean, Invoice>();
  const alreadyApprovedModal = useModalWithData<Invoice>();

  const navigate = useNavigate();
  const { t } = useTranslation(['invoices']);

  const onEdit = useCallback(async () => {
    if (!(await confirmEditModal.open(invoice))) return;

    mutate(invoice.id, {
      onSuccess: () => navigate(r(routes.editInvoice, { invoiceId: invoice.id })),
      onError: (error) => {
        if (error instanceof BusinessConflictError && error.errorCode === BusinessConflict.ApprovedInvoiceCannotBeLockedByUser) {
          return alreadyApprovedModal.open(invoice);
        }

        return toast.error(t('invoices:overview.rowActions.edit.error'));
      },
    });
  }, [alreadyApprovedModal, confirmEditModal, invoice, mutate, navigate, t]);

  return useMemo(
    () => ({ onEdit, mutation, confirmEditModal, alreadyApprovedModal }),
    [confirmEditModal, alreadyApprovedModal, mutation, onEdit],
  );
};

export const useOnPreviewInvoice = (invoice: Invoice) => {
  const mutation = usePreviewInvoice();
  const { mutate } = mutation;

  const { t } = useTranslation(['common']);

  const onPreview = useCallback(
    () =>
      mutate(invoice.id, {
        onError: () => toast.error(t('common:error')),
      }),
    [invoice.id, mutate, t],
  );

  return useMemo(() => ({ onPreview, mutation }), [onPreview, mutation]);
};

export const useOnDuplicateInvoice = (invoice: Invoice) => {
  const mutation = useDuplicateInvoice();
  const { mutate } = mutation;

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const onDuplicate = useCallback(
    () =>
      mutate(invoice.id, {
        onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
        onError: () => toast.error(t('common:error')),
      }),
    [invoice.id, mutate, navigate, t],
  );

  return useMemo(() => ({ onDuplicate, mutation }), [mutation, onDuplicate]);
};

export const useOnDownloadInvoice = (invoice: Invoice) => {
  const mutation = useDownloadInvoice();
  const { mutate } = mutation;

  const { t } = useTranslation(['common']);

  const onDownload = useCallback(() => {
    mutate(invoice.id, {
      onError: () => toast.error(t('common:error')),
    });
  }, [invoice.id, mutate, t]);

  return useMemo(() => ({ onDownload, mutation }), [onDownload, mutation]);
};

export const useOnCreditInvoice = (invoice: Invoice) => {
  const navigate = useNavigate();

  const onCredit = useCallback(
    () => navigate({ pathname: routes.createCreditnote, search: qs({ [SearchParamKeys.INVOICE_ID]: invoice.id }) }),
    [invoice.id, navigate],
  );

  return useMemo(() => ({ onCredit }), [onCredit]);
};
