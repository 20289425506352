import type { Path, To } from 'react-router';

import { Navigate, useLocation } from 'react-router';

type Props = {
  state?: Record<PropertyKey, unknown>;
  to: To;
};

export const Redirect = ({ state, to }: Props) => {
  const location = useLocation();

  const destination: Partial<Path> =
    typeof to === 'string'
      ? { pathname: to, search: location.search, hash: location.hash }
      : { pathname: to.pathname, search: to.search ?? location.search, hash: location.hash };

  return <Navigate replace state={{ blockable: false, ...state }} to={destination} />;
};
