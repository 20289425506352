export const dashboard = {
  charts: {
    labels: {
      commission: 'Rémunérations Creative Shelter',
      copyright: 'Partie droits d’auteur',
      currentQ: 'Trimestre en cours (K{{ quarter }})',
      expired: 'Echu(e)',
      nettoBenefit: 'Bénéfice net',
      notExpired: 'Non expiré',
      paid: 'Factures payées',
      performance: 'Partie revenus professionnels',
      previousQ: 'Trimestres précédents',
      total: 'Nombre total de factures',
      unpaid: 'Factures impayées',
    },
    netAdvantage: 'Bénéfice net',
    overview: 'Aperçu',
    table: {
      amount: 'Nombre de',
      description: 'Description',
      value: 'Montant',
    },
    title: 'Aperçu annuel',
    totalBilled: 'Total facturé',
  },
  frameworkAgreement: {
    checkboxError: 'Acceptez le contrat-cadre pour continuer.',
    checkboxLabel: "J'accepte le contrat-cadre.",
    subtitle: 'Pas de petits caractères, juste des accords clairs.\n',
    title: 'Lisez le contrat-cadre.',
  },
  header: {
    actionBlock: {
      button: 'Créer une facture',
      dropdown: {
        client: 'Créer un client',
        creditnote: 'Créer une note de crédit',
        quotation: 'Créer une offre',
      },
    },
    description: 'Bienvenue dans votre tableau de bord Creative Shelter !',
  },
  progression: {
    hide: 'cacher',
    optional: {
      accountant: 'Transférez vos documents automatiquement !',
      community: 'Rejoignez la Craft Community !',
      completed: "Vous utilisez toutes les options d'extension (facultatives)",
      invite: "Inviter quelqu'un à utiliser la plateforme",
      plan: 'Améliorez votre plan tarifaire.',
      title: 'Étendez votre compte',
    },
    required: {
      completed: 'Votre compte Creative Shelter est à jour !',
      create: 'Créer et activer votre compte.',
      iban: 'Lier votre IBAN à votre compte Creative Shelter.',
      signAddendum2024: "Signez l'avenant à votre contrat-cadre avec Creative Shelter",
      signContract: 'Signer votre contrat-cadre',
      signContractModalTitle: 'Signez votre contrat-cadre Creative Shelter',
      title: 'Compléter votre compte Creative Shelter',
      vatRegulation: 'Indiquez votre régime de TVA',
    },
  },
  royaltiesReformAnnouncement: {
    messageIt:
      "<p>Une nouvelle année et, pour la première fois, un nouvel avenant à votre contrat-cadre. Vous le trouverez dans le tableau de bord, sous « Complétez votre compte Creative Shelter ». Depuis cette année, la clé de répartition est 60/40, revenus professionnels/droits d'auteur. Une fois signé, vous pouvez facturer.\n<br></p>\n<p>Les programmes informatiques se feront désormais sans rémunération en droits d'auteur. Vous pouvez l'utiliser, mais vous ne bénéficierez plus de l'avantage fiscal.\n<br></p>\n<p>Et les plans ont été adaptés ! Uniquement Premium et Classic. Vous obtenez plus pour le même prix, et vous payez moins pour le même service. Vous <0> trouverez les modifications et les nouveaux contenus ici. </0></p>",
    messageNonIt:
      "<p>Une nouvelle année et pour la première fois un nouveau contrat-cadre. Depuis cette année, la clé de répartition est 60/40, revenus professionnels/droits d'auteur. Une fois signé, vous pouvez facturer.\n<br></p>\n<p>Et les plans ont été adaptés ! Ceux-ci ont été réduits aux plans tarifaires Premium et Classic. De cette façon, vous obtenez plus pour la même compensation, vous payez moins, pour la même service. Vous <0> trouverez les modifications et les nouveaux contenus ici. </0></p>",
    titleIt: '<span className="text-secondary-500">2024</span>, qu’est-ce qui change exactement ?',
    titleNonIt: '<span className="text-secondary-500">2024</span>, qu’est-ce qui change exactement ?',
  },
  stats: {
    copyrightAverage: {
      label: "Moyenne droits d'auteur",
      tooltip:
        'Moyenne sur quatre ans que vous avez reçue en rémunération de droits d\'auteur via Creative Shelter.<br> Pourquoi c\'est important et comment est-ce calculé<a href="https://knowledge-creativeshelter-be.translate.goog/hoe-wordt-jouw-gemiddelde-auteursrechten-berekend-op-het-dashboard?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank"> lisez plus ici !</a>',
    },
    copyrightThisYear: {
      label: "Droits d'auteur {{year}}",
      tooltip:
        'Montant total de votre rémunération en droits d\'auteur de toutes les factures payées cette année.<br>Vous pouvez lire tout ce qui concerne la limite annuelle de votre rémunération en droits d\'auteur ici. <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank"></a>',
    },
    overdueInvoices: 'Factures échues',
    pendingInvoices: 'Factures pour approbation',
    pendingQuotations: 'Offres pour approbation',
  },
};
