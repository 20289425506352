import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

import { tokens } from './ColorPicker.stylex';

const isFocused = ':is([data-focus])';

const outlineBounce = stylex.keyframes({
  '0%': { outlineOffset: '6px' },
  '100%': { outlineOffset: '2px' },
});

export const styles = stylex.create({
  wrapper: {
    position: 'relative',
    width: tokens.buttonSize,
    height: tokens.buttonSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  circleButton: {
    display: 'block',
    width: tokens.circleSize,
    height: tokens.circleSize,
    padding: 0,
    position: 'relative',
    borderWidth: 1,
    borderColor: neutralColors.white,
    borderRadius: radius.full,
    outlineStyle: {
      default: 'none',
      [isFocused]: 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: '2px',
    outlineColor: systemColors.outline,
    animationName: {
      default: null,
      [isFocused]: outlineBounce,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
    cursor: 'pointer',
    isolation: 'isolate',

    // White divider
    '::before': {
      content: '',
      position: 'absolute',
      zIndex: 1,
      insetInline: 0,
      borderBottomWidth: 1,
      borderColor: neutralColors.white,
      transform: `translateY(calc(${tokens.circleSize} / 2 - 1.5px)) rotate(-45deg)`,
    },

    // Increased hitbox
    '::after': {
      content: '',
      position: 'absolute',
      inset: tokens.hitboxInset,
    },
  },

  mainCircleButton: {
    // Increased hitbox
    '::after': {
      content: '',
      position: 'absolute',
      inset: tokens.bigHitboxInset,
    },
  },

  rotated: {
    width: '100%',
    height: '100%',
    transform: 'rotate(-45deg)',
  },

  half: {
    width: '100%',
    height: '50%',
  },

  topHalf: {
    borderTopLeftRadius: radius.full,
    borderTopRightRadius: radius.full,
    backgroundColor: brandColors.primary500,
  },

  bottomHalf: {
    borderBottomLeftRadius: radius.full,
    borderBottomRightRadius: radius.full,
    backgroundColor: brandColors.secondary500,
  },

  itemsContainer: {
    padding: spacing.xxxsmall,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.xxsmall,
    borderRadius: radius.full,
    backgroundColor: brandColors.primary100,
    outlineStyle: 'none',
  },
});
