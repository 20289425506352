import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useClients } from '~/api/clients';
import { useDownloadInvoices, useInvoices } from '~/api/invoices';
import { Button } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useIntl } from '~/hooks/useIntl';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { exportToExcel } from '~/utils/excel';

import { getInvoiceStatus } from '../../utils';
import { useInvoiceSearchParamFilters, useInvoicesTableColumns, useInvoicesTableFilters } from './InvoicesTable.hooks';

export const InvoicesTable = () => {
  const { data: invoices } = useInvoices();
  const { data: clients } = useClients();

  const downloadInvoicesMutation = useDownloadInvoices();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'invoices']);
  const { compareForSort } = useIntl();

  const data = useMemo(
    () => invoices.filter((invoice) => !invoice.draft).sort((a, b) => compareForSort(b.entryNumber, a.entryNumber)),
    [compareForSort, invoices],
  );

  const exportInvoicesToExcel = async () => {
    const selectedIds = table.getSelectedRowIds();
    const filteredInvoices = selectedIds.length > 0 ? data.filter((invoice) => selectedIds.includes(invoice.id)) : data;

    const heading = [
      t('invoices:fields.entryNumber.label'),
      t('invoices:fields.title.label'),
      t('invoices:fields.description.label'),
      t('invoices:fields.clientId.label'),
      t('invoices:overview.columns.date'),
      t('invoices:overview.columns.dueDate'),
      t('invoices:overview.columns.status.title'),
      t('invoices:overview.columns.total'),
      t('invoices:overview.columns.saved'),
      t('invoices:overview.excel.paidDate'),
      t('invoices:overview.excel.copyright6'),
      t('invoices:overview.excel.copyright21'),
      t('invoices:overview.excel.performance'),
      t('invoices:overview.excel.vatCopyright6'),
      t('invoices:overview.excel.vatCopyright21'),
      t('invoices:overview.excel.vatPerformance'),
    ];

    const excelData = filteredInvoices.map((invoice) => ({
      entryNumber: invoice.entryNumber,
      title: invoice.title,
      description: invoice.description,
      clientName: clients.find((client) => client.id === invoice.clientId)?.name ?? '',
      docDate: invoice.docDate,
      dueDate: invoice.paymentDueDate,
      status: t(`invoices:overview.columns.status.options.${getInvoiceStatus(invoice)}`),
      total: invoice.calculationData.fcExclVat,
      netBenefit: invoice.legacyCalculationData.netBenefit,
      paidDate: invoice.paidDate,
      copyright6: invoice.legacyCalculationData.copyrightFc6,
      copyright21: invoice.legacyCalculationData.copyrightFc21,
      performance: invoice.calculationData.fcPerformanceExclVat,
      vatCopyright6: invoice.legacyCalculationData.vatCopyrightFc6,
      vatCopyright21: invoice.legacyCalculationData.vatCopyrightFc21,
      vatPerformance: invoice.legacyCalculationData.vatPerformanceFc,
    }));

    try {
      await exportToExcel(excelData, heading, 'invoices-export.xlsx');
    } catch {
      toast.error(t('common:error'));
    }
  };

  const downloadInvoices = () => {
    downloadInvoicesMutation.mutate(table.getSelectedRowIds(), {
      onError: () => toast.error(t('common:error')),
    });
  };

  const columns = useInvoicesTableColumns();

  const searchParamFilters = useInvoiceSearchParamFilters();

  const [table, Table] = useTable({
    data,
    columns,
    initialState: {
      columnVisibility: {
        [ColumnIds.invoicesBrand]: false,
        [ColumnIds.invoicesContact]: false,
        [ColumnIds.invoicesDocYear]: false,
        [ColumnIds.invoicesDocQuarter]: false,
      },
      sorting: [
        {
          id: ColumnIds.invoicesDocDate,
          desc: true,
        },
      ],
      columnFilters: [
        ...(searchParamFilters.year !== null ? [{ id: ColumnIds.invoicesDocYear, value: [searchParamFilters.year] }] : []),
        ...(searchParamFilters.quarter !== null ? [{ id: ColumnIds.invoicesDocQuarter, value: [searchParamFilters.quarter] }] : []),
        ...(searchParamFilters.status !== null ? [{ id: ColumnIds.invoicesStatus, value: [searchParamFilters.status] }] : []),
      ],
    },
    enableRowSelection: (row) => !!row.original.allowed,
    enableSortingRemoval: false,
  });

  const filterOptions = useInvoicesTableFilters(data);

  const selectedRowsCount = table.getSelectedRowIds().length;

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <div className="flex flex-grow gap-4">
          <Table.Search />
          <Table.FiltersToggle />
        </div>

        {selectedRowsCount > 0 && (
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Button onClick={exportInvoicesToExcel}>{`${t('common:export')} (${selectedRowsCount})`}</Button>
            <Button disabled={downloadInvoicesMutation.isPending} onClick={downloadInvoices}>
              {`${t('common:datatable.download')} (${selectedRowsCount})`}
            </Button>
          </div>
        )}
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.invoicesClient]: filterOptions.clients,
          [ColumnIds.invoicesBrand]: filterOptions.brands,
          [ColumnIds.invoicesDocYear]: filterOptions.docYears,
          [ColumnIds.invoicesDocQuarter]: filterOptions.docQuarters,
          [ColumnIds.invoicesStatus]: filterOptions.statuses,
        }}
      >
        <Table.Filter columnId={ColumnIds.invoicesClient} type="combobox" />
        {filterOptions.brands.length > 1 && <Table.Filter columnId={ColumnIds.invoicesBrand} type="select" />}
        <Table.Filter columnId={ColumnIds.invoicesDocYear} type="multiselect" />
        <Table.Filter columnId={ColumnIds.invoicesDocQuarter} type="multiselect" />
        <Table.Filter columnId={ColumnIds.invoicesStatus} type="multiselect" />
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Selection />
        <div className="contents" {...(data.length > 0 && { ['data-pf-id']: 'invoice-table' })}>
          <Table.Body>
            {(row) => <Table.Row onClick={({ id }) => navigate(r(routes.showInvoice, { invoiceId: id }))} row={row} />}
          </Table.Body>
        </div>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
