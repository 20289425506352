import { useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useClients } from '~/api/clients';
import { Redirect } from '~/components/Functional';
import { routes } from '~/providers/RouterProvider/router.routes';

import { CreateContactForm } from './CreateContactForm';

export const CreateContact = () => {
  const { data: clients } = useClients();

  const { clientId } = useParams<Params<typeof routes.createContact>>();

  const client = clients.find((client) => client.id === Number(clientId));

  if (!client || client.clientType !== 'organisation') return <Redirect to={routes.clients} />;

  return <CreateContactForm client={client} />;
};
