export const settings = {
  accountCompletionModal: {
    action: {
      complete: 'Finish',
      next: 'Next',
    },
    description: 'Complete your details so you can start billing quickly.',
    financialInfo: {
      check: 'Check',
      description: 'Please enter your IBAN number below.',
      error: 'Something went wrong while saving your IBAN bank account number.',
      tab: 'Bank details',
    },
    frameworkAgreement: {
      description: "Review the agreement and agree by entering 'Read and approved' in the field below.",
      descriptionAddendum2024:
        'Since the start of the new year, the distribution key has changed to 60/40, professional income/copyrights. Below is the addendum to your framework agreement with Creative Shelter. By typing “Read and Approved” in the field below, you agree and can continue billing.',
      error: 'Something went wrong while signing your agreement.',
      tab: 'Framework agreement',
    },
    successModal: {
      action: 'Let’s get started!',
      description: 'You are now ready to send your first invoice or quotation!',
      title: 'Account replenished',
    },
    title: 'Complete your account',
    vatRegulation: {
      error: 'Something went wrong when saving your VAT status.',
      tab: 'VAT-status',
    },
  },
  alerts: {
    brands: {
      successCreated: 'Your trademark has been created!',
      successDeleted: 'Your trademark has been removed!',
      successFavorite: 'Your favorite trade name has been successfully changed!',
      successUpdated: 'Your trademark has been changed!',
    },
    community: {
      errorProfilePictureDeleted:
        'Something went wrong while deleting your Community profile picture. If the problem persists, please contact service@creativeshelter.be.',
      errorProfilePictureTooLarge: 'The image you want to upload must not exceed 5 Mb.',
      errorProfilePictureUploaded:
        'Something went wrong while uploading your Community profile picture. If the problem persists, please contact service@creativeshelter.be.',
      errorUpdated:
        'Something went wrong while updating your Community profile. If the problem persists, please contact service@creativeshelter.be.',
      successProfilePictureDeleted: 'Your Community profile picture has been removed!',
      successProfilePictureUploaded: 'Your Community profile picture has been changed!',
      successUpdated: 'Your Community profile has been changed!',
    },
    customConditions: {
      successDeleted: 'The custom terms and conditions have been successfully removed!',
    },
    integrations: {
      successAccountantLinked: 'Your accountant was successfully added!',
      successAccountantUnlinked: 'Your accountant has been removed successfully!',
      successBillitPaired: 'Your accounting package was added successfully!',
      successCashacaPaired: 'Cashaca has been connected successfully!',
      successForwardingEmailLinked: 'The e-mail address was added successfully!',
      successForwardingEmailUnlinked: 'The e-mail address was successfully removed!',
    },
    numberingSeries: {
      duplicateFormat: 'You have already used the format of this numbering range, please choose a new one.',
      successCreated: 'Your numbering range was created successfully!',
      successDeleted: 'Your numbering range has been successfully removed!',
      successUpdated: 'Your numbering range has been successfully changed!',
    },
    successUpdated: 'The settings were saved successfully!',
  },
  documents: {
    description: 'Overview of all personal documents.',
    headers: {
      name: 'File name',
      type: 'Type',
    },
    title: 'Documents',
  },
  edit: 'Edit',
  fields: {
    accountantEmail: {
      label: 'E-mail address of your accountant',
      placeholder: 'E-mail address',
    },
    activities: {
      other: {
        label: 'Enter your extra (creative) activities here',
      },
      primary: {
        label: 'Main activity',
      },
      secondary: {
        label: 'Additional activities',
      },
    },
    annex: {
      label: 'Addition',
      placeholder: 'Addition',
    },
    box: {
      label: 'Box',
      placeholder: 'Box',
    },
    brandId: {
      label: 'Trademark',
    },
    brandName: {
      label: 'Trade name',
      placeholder: 'Your trademark',
    },
    city: {
      label: 'City or municipality',
      placeholder: 'City or municipality',
    },
    color: {
      label: 'Color',
    },
    communityDescription: {
      label: 'Brief description about you/your activities',
    },
    communityLinks: {
      label: 'Links',
      placeholders: {
        behance: 'https://www.behance.net/',
        facebook: 'https://www.facebook.com/',
        instagram: 'https://www.instagram.com/',
        linkedIn: 'https://www.linkedin.com/in/',
        website: 'https://',
      },
    },
    communityOpenToWork: {
      label: 'I am available for assignments',
    },
    communityRegion: {
      label: 'Region',
      placeholder: 'Not specified',
    },
    companyName: {
      label: 'Company Name',
    },
    currentPassword: {
      label: 'Current password',
    },
    email: {
      label: 'Email address',
      placeholder: 'E-mail address',
    },
    firstName: {
      label: 'First name',
      placeholder: 'Name',
    },
    forwarding: {
      alerts: {
        billtobox: "Please add 'administratie@creativeshelter.be' as a known email address in Billtobox. Then everything will go smoothly!",
      },
      frequency: {
        label: 'Frequency',
        options: {
          instant: 'Per invoice or credit note (.pdf/.xml)',
          monthly: 'Per month (.zip)',
          quarterly: 'Per quarter (.zip)',
        },
        tooltip: 'If you entered an accounting package, choose the option to forward a pdf and xml file per invoice or credit note.',
      },
      platformEmail: {
        label: 'E-mail address of your Accountable account',
      },
    },
    lang: {
      label: 'Language',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    logo: {
      error: {
        delete: 'Something went wrong while deleting the logo. Please try again.',
        upload: 'Something went wrong while uploading the logo.',
      },
      label: 'Trade name logo',
      tooltip:
        'If you select this trade name when preparing a document, this logo will be used instead of the logo of your main trade name.',
    },
    mailText: {
      default:
        'Hello #NAME#<br/><br/>Freelancers are here to help and support each other. And for that very reason, today I would like to introduce you to the Creative Shelter tool. I myself have already benefited and gained a lot from this.<br/><br/>Have you ever heard of Creative Shelter? Well, Creative Shelter offers an online platform that, thanks to copyrights, helps me earn more from my freelance mission. Quick and easy and on top of that legally completely in order.<br/><br/>Maybe this is also something for you?<br/><br/>Sincerely,<br/>{{ firstName }} {{ lastName }}',
      label: 'Email',
      placeholder: 'Write your message here',
    },
    mainOccupation: {
      label: 'Self-employed',
      options: {
        '0': 'Secondary occupation',
        '1': 'Main occupation',
      },
    },
    newPassword: {
      label: 'New password',
    },
    newPasswordConfirmation: {
      label: 'Confirm new password',
    },
    number: {
      label: 'House number',
      placeholder: '#',
    },
    numberingSeries: {
      format: {
        amountOfDigits: {
          label: 'Number of digits',
          option: '{{amountOfDigits}} digits',
        },
        lengthWarning: 'We recommend limiting the length of the number to {{limit}} characters.',
        nextNumber: 'The next number in this range is <strong>{{number}}</strong>.',
        prefixText: {
          label: 'Prefix',
        },
        separator: {
          label: 'Separator',
          none: 'None',
        },
        suffixText: {
          label: 'Suffix',
        },
        useSuffix: 'Use suffix',
        year: {
          label: 'Year/month',
          options: {
            YY: 'Year short ({{yearMonth}})',
            YYMM: 'Year short + month ({{yearMonth}})',
            YYYY: 'Year ({{yearMonth}})',
            YYYYMM: 'Year + month ({{yearMonth}})',
            none: 'None',
          },
        },
      },
      name: {
        label: 'Name',
        placeholder: 'for ex. Sales invoices',
        tooltip: 'Name your numbering range, that way you will quickly recognise it.',
      },
      resetInterval: {
        description: 'Reset count automatically to 1.',
        label: 'Restart count',
        options: {
          monthly: 'Monthly',
          never: 'Never',
          yearly: 'Yearly',
        },
      },
      startAt: {
        label: 'Starting number',
      },
      type: {
        label: 'Type',
        options: {
          both: 'Invoices and credit notes',
          credit_notes: 'Credit notes',
          invoices: 'Invoices',
        },
        tooltip:
          "In your accounting there are two main types of documents, which are your invoices or credit notes. It's up to you to choose one numbering range that covers both, but a split is also possible. We leave that up to you.",
      },
    },
    phone: {
      label: 'Mobile number',
      placeholder: '+32 499 99 99 99',
    },
    street: {
      label: 'Street name',
      placeholder: 'Street name',
    },
    vatID: {
      label: 'VAT number',
      placeholder: 'BE1233456789',
    },
    zipcode: {
      label: 'Postal/ZIP Code',
      placeholder: 'Postal/ZIP Code',
    },
  },
  general: {
    customConditions: {
      create: 'New conditions',
      delete: {
        action: 'Remove',
        description: 'Are you sure you want to remove the custom terms and conditions ({{name}})?',
        title: 'Remove custom terms and conditions',
      },
      description: 'Check or create your tailor-made general terms and conditions here',
      table: {
        actions: {
          creatingPdf: 'Generating PDF...',
          delete: 'Remove',
          title: 'Actions',
          view: 'View',
        },
        name: 'Name',
      },
      title: 'Tailor-made general terms and conditions',
      wizard: {
        already_agreement: {
          question: 'Have you already agreed with your client about how your work can be used?',
        },
        already_agreement_no: {
          question: 'No worries! Based on the following questions, you decide what your client can do with your work.',
        },
        already_agreement_no_paper: {
          question: 'No problem! With the following questions we will put them down on paper.',
        },
        already_agreement_paper: {
          question: 'Do you have those agreements on paper?',
        },
        already_agreement_uploaded: {
          question: 'Thanks for uploading your contract. We will come back to you shortly!',
        },
        already_agreement_yes_paper: {
          question:
            "Perfect, as good agreements make good friends. Please check the agreement box in your client's profile and send us a copy of your agreement via email to (<a href='mailto:administratie@creativeshelter.be'>administratie@creativeshelter.be</a>). We will review your agreement and will have to countersign it as a third party.",
        },
        common_answers: {
          close: 'OK, close the wizard',
          next: 'OK, next question',
          no: 'No',
          ok: 'OK',
          previous: 'Previous question',
          start_questions: 'OK, start the questions',
          yes: 'Yes',
        },
        modify_how: {
          options: {
            '0': 'Adapt/modify by adding and/or removing elements',
            '1': 'Translation/transfer',
          },
          question: 'By which means can your work be adapted or modified?',
        },
        overview: {
          error:
            'Something went wrong while creating your tailor-made general terms and conditions. Please try again later or send us a message.',
          finish: 'OK, please create my tailor-made general terms and conditions.',
          name: {
            label:
              'Please name your tailor-made general terms and conditions (e.g. name of your client or a specific detail of your tailor-made GT&C).',
            placeholder: "For example 'User right Benelux 1 year'",
          },
          question: 'Thanks for completing all the questions. Please find the overview:',
        },
        reproduce_how: {
          options: {
            '0': 'By any means, in all different forms, with any technique and on all different supports.',
            '1': 'In a specific form',
            '2': 'In electronic form on a fixed support',
            '3': 'In electronic form via a online support',
          },
          other_placeholder: 'Specify in which form',
          question: 'By which means can your work be reproduced?',
        },
        reproduce_how_many: {
          options: {
            '0': 'Unlimited',
            '1': 'Limited',
          },
          other_placeholder: 'Specify the number of copies',
          question: 'Reproduction at how many copies?',
        },
        share_how: {
          options: {
            '0': 'Publication in/on',
            '1': 'Availability via ...',
            '2': 'Publication in a book, a catalogue, ...',
            '3': 'As promo material and for commercial use',
          },
          other_placeholder: 'Specify',
          question: 'How can your work be published?',
        },
        start: {
          description: 'Follow the wizard below to create your tailor-made general terms and conditions',
          ok: 'OK, start the wizard',
          title: 'Create your tailor-made general terms and conditions',
        },
        thanks: {
          close: 'Close the wizard',
          explanation:
            "Create your invoice and select the name of your tailor-made GT&Cs under the 'copyrights regimes' menu. Your tailor-made GT&Cs will automatically be attached to the invoice we will send to your client. These are mentioned in article 8 (8.1 to 8.3) and referred to on the invoice in the field 'special conditions'.",
          question:
            'We are creating your tailor-made general terms and conditions which will be available soon to be used when creating your invoice.',
          subtitle: 'How does it work?',
        },
        transfer_copyright: {
          question: 'Do you want to transfer your copyrights on your work to your client?',
        },
        transfer_copyright_no: {
          question: 'OK, perfect! Next step is to determine how your client can use your work.',
        },
        transfer_copyright_yes: {
          question: "OK, perfect! All you need to do is to select 'transfer of copyrights' when creating your quotation or invoice.",
        },
        use_how: {
          multiple: 'multiple options possible',
          options: {
            '0': 'Reproduce',
            '1': 'Publish',
            '2': 'Adapt/modify',
          },
          question: 'How can your client use your work?',
        },
        use_how_long: {
          options: {
            '0': 'For the entire time',
            '1': 'Other duration',
          },
          other_placeholder: 'Specify the duration',
          question: 'For how long?',
        },
        use_where: {
          options: {
            '0': 'Belgium',
            '1': 'Europe',
            '2': 'All over the world',
            '3': 'Other',
          },
          other_placeholder: 'e.g. Benelux',
          question: 'Where can your client use your work?',
        },
      },
    },
    description: 'Change the layout and functioning of your Craft platform.',
    email: {
      bcc: {
        description:
          'This means that you are in bcc for all emails we send to your clients, from new quotations, invoices and credit notes to invoice payment reminders.',
        title: 'Put me in bcc on all emails to my clients',
      },
      creationReminder: {
        description: 'thanks to this reminder, never send a belated invoice again.',
        options: {
          '0': 'No reminder',
          '1': 'On the last day of the month',
          '2': '1 week before the end of the month',
          '3': '2 weeks before the end of the month',
        },
        title: 'Reminder for creating invoices',
      },
      description: 'Choose here which emails and notifications you wish to receive',
      followUpEnabled: {
        description: 'Receive a notification if there is an update on the payment follow-up of an overdue invoice',
        title: 'Update payment follow-ups',
      },
      invitationReminder: {
        description: 'Get a reminder when the freelancer colleague you invited also gets a reminder to register.',
        title: 'Reminder for sent invitations',
      },
      nettoSaved: {
        description:
          'Get an overview of your monthly savings by using Creative Shelter. This way you can clearly see your extra savings by redeeming your copyrights.',
        title: 'Monthly net savings email',
      },
      title: 'Emails and notifications',
    },
    numberingSeries: {
      add: 'New numbering range',
      delete: {
        description: 'Are you sure you wish to delete "{{numberingSeries}}"?',
        title: 'Delete',
      },
      description:
        'Configuration for automatic numbering of invoices and credit notes. Questions, more info can be found <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">here</a>.',
      edit: {
        format: 'Format/change options',
        name: 'Change name/type',
      },
      headers: {
        name: 'Name',
        nextNumber: 'Next number',
        type: 'Type',
      },
      title: 'Numbering range',
    },
    themeInvoice: {
      colorTheme: {
        helpText: 'Choose a colour combination for the documents to your clients.',
        subtitle: 'Colour theme',
      },
      description: 'Choose the theme of the invoices for your client.',
      logo: {
        helpText: "Upload your sole trader's logo here.",
        subtitle: 'Logo',
      },
      title: 'Invoice theme',
    },
    title: 'Settings',
  },
  integrations: {
    accountant: {
      current: 'The accountant below has been added to your account:',
      description:
        'Adding your accountant will give them access to your invoices and documents via <a href=https://api.creativeshelter.be/accountants/login target="_blank">the Creative Shelter credit platform</a>.',
      pair: 'Connect',
      requested: 'The accountant mentioned below has requested access to your account:',
      title: 'Give your accountant access',
      unpair: 'Remove',
    },
    description: 'Link your Creative Shelter account with external services here.',
    externalSystems: {
      description: 'By linking with an external system, you expand the functionality of your account to the external system.',
      pairWith: 'Link with {{ system }}',
      paired: 'Linked',
      systems: {
        billit: {
          description:
            'Do you also create non-creative invoices in addition to creative invoices? This integration makes it possible to easily create and link those non-creative invoices to your Creative Shelter account.',
          name: 'Billit',
        },
        cashaca: {
          description: 'Cashaca simplifies accounting. Ideal if you are self-employed with a turnover of less than € 500,000.',
          modal: {
            apiToken: 'Cashaca API code',
            description:
              'Complete your personal API code here.<br/>No Cashaca account yet? <a href="https://app.cashaca.be/gebruikers/registreer?utm_source=creativeshelter" target="_blank">Register here</a>.',
          },
          name: 'Cashaca',
        },
      },
      title: 'Link to external systems',
    },
    forwarding: {
      deleteModal: {
        confirm: "Yes, I'm sure.",
        description: 'Are you sure you no longer want to forward your invoices and credit notes to {{email}}?',
        title: 'Stop automatic forwarding',
      },
      description: 'Automatically forward your invoices and credit notes to your accountant or accounting package.',
      title: 'Automatic forwarding',
    },
    pair: 'Connect',
    processing: 'We are retrieving your data',
    title: 'Accounting & Integrations',
  },
  invitations: {
    description: 'View and manage your invitations.',
    discountsCard: {
      active: 'Active',
      commissionSaved: '<strong>{{amount}}</strong> saved',
      discount: '{{percentage}} discount',
      endedAt: 'Ended on {{endDate}}',
      expired: 'Expired',
      invite: 'Invite someone!',
      noDiscounts: "You haven't received any rewards (for now) yet.",
      startedAt: 'Started on {{startDate}}',
      title: 'Rewards',
    },
    intro: {
      disclaimer:
        '* This discount is valid for one year and takes effect as soon as an invoice is sent by your fellow freelancer, within 30 days of creating an account. This includes the person you invited, the Premium plan at a Classic price, which will be automatically activated.',
      paragraphs: [
        {
          body: "You are a fan and you sometimes advertise for us. That's great, and we want to reward that. Not by putting yourself in the limelight - even if we wish to do so - but by giving you a discount*.",
          subtitle: 'Invite someone to invoice through us!',
        },
        {
          body: "That is 25% on your current compensation. One-fourth, that you simply receive as a gift from us. And the more people you invite, the more discount you get! So, if you invite 4 new invoicing users, you'll pay nothing at all and you'll invoice FREE OF CHARGE.",
          subtitle: 'And how does that discount work?',
        },
        {
          body: "We'll pamper you, and welcome your fellow freelancer with open arms! He/she gets the Premium tariff plan at a Classic price for one year. So you will both enjoy the coming year even more.",
          subtitle: 'Does the invitee also receive something?',
        },
      ],
      title: 'Fancy a discount and earn even more?',
    },
    invitationsCard: {
      noInvitations: "You haven't sent any invitations yet.",
      noMoreInvitations: 'You no longer have any pending invitations.',
      status: {
        accepted: 'Registered',
        declined: 'Rejected',
        pending: 'Pending',
      },
      title: 'Invitations',
    },
    invite: {
      error: {
        userAlreadyExists: 'The user you want to invite is already billing via the Craft platform.',
      },
      success: 'The invitation has been sent successfully and is now on its way to {{ name }}',
      title: 'Invite someone',
    },
    steps: {
      copied: 'Copied',
      sendInvitationButton: 'Earn 25% discount',
      stepOne: 'You invite someone who is eligible, with your personal code.',
      stepThree: 'Congratulations, time for a 25% discount! One year. 365 days of savings.',
      stepTwo: 'Your fellow freelancer creates an account and invoices within 30 days.',
      title: 'How do you get a 25% discount?',
    },
    title: 'Invitations',
  },
  nps: {
    sendFeedback: 'Send your feedback',
    titleStepOne: 'To what extent would you recommend Creative Shelter to your fellow freelancers?',
    titleStepThree: 'Thanks! We are working on it!',
    titleStepTwo: 'How can we improve our services?',
  },
  paymentPlan: {
    changePlanModal: {
      confirm: 'Change plan',
      descriptionDowngrade:
        "Are you sure you want to downgrade your {{currentPlan}} to {{newPlan}}? If you are still unsure about the different plans and compensations, be sure to contact us at <a href='mailto:service@creativeshelter.be'>service@creativeshelter.be</a>, we will be happy to help you.",
      descriptionUpgrade: 'Fancy more? Upgrade your {{currentPlan}} to {{newPlan}}!',
      title: 'Change plan',
    },
    commission: {
      currentCommission: 'Current compensation',
      description: {
        creative:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Creative performances</a> at {{percentage}}%',
        creativeFree:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Creative performances</a> free of charge, woo-hoo !',
        nonCreative:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Non-creative performances</a> at {{percentage}}%',
        nonCreativeFree:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Non-creative performances</a> free of charge!',
        subcontractingFree:
          '<a href="https://knowledge.creativeshelter.be/onderaanneming-wat-is-dat-precies" target="_blank">Subcontracting</a> free of charge',
      },
      details: {
        baseCommission: 'Standard compensation',
        bracket: {
          '1': 'First tranche',
          '2': 'Second tranche',
          '3': 'Third tranche',
        },
        invite: 'Fancy more discount? Invite someone!',
        referral: 'Referral discount (- {{amount}}%)',
        toggle: 'Details',
        total: 'New fee',
      },
      disclaimer: {
        adminOverride: 'Your compensation differs from the standard rate; the scale principle does not apply to you.',
        adminOverrideFixed: 'Your compensation differs from the standard rate. The scale principle does not apply to you.',
        copyrightLimitExceeded:
          "You've exceeded your copyrights limit for this year. Because all your services are now professional income, you can invoice for free until the end of the year.",
      },
      planName: {
        '4': 'Early Adopter',
        '5': 'Classic plan',
        '6': 'Premium plan',
      },
    },
    earlyAdopter: {
      description: "As an Early Adopter, you'll enjoy all the features of our Premium tariff plan at the most affordable price.",
      features: [
        {
          text: 'Immediate copyrights payment',
        },
        {
          text: 'Choice of payment term',
        },
        {
          text: 'Follow-up of unpaid invoices including via bailiff',
        },
        {
          text: 'Customized general terms and conditions',
        },
        {
          text: 'Linking with external tools',
          tooltip: 'Billit and/or Cashaca',
        },
      ],
      title: 'Tariff plan',
    },
    freeInvitationCTA: {
      text_one: 'Your promo is still active for {{count}} day. So today you invoice for free as the very last day.',
      text_other: 'Your promo is still active for {{count}} days. So you are billing absolutely free.',
    },
    header: {
      description: 'This is where you check your current fee and payment plan.',
      title: 'Tariff plan',
    },
    invitationCTA: {
      link: 'Click here to send an invitation.',
      text: 'Get 25% off your compensation for every fellow freelancer you invite! ',
    },
    picker: {
      commissionText: 'per creative performance',
      contact: 'Contact us',
      current: 'Current plan',
      disclaimer: 'All rates exclude discount',
      features: {
        '5': [
          {
            text: 'Access to the Craft platform',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">Automatic reminder emails </a> for unpaid invoices',
            tooltip: 'Sent 2 and 8 days after the due date of your invoice',
          },
          {
            text: 'Immediate payment of your invoice as soon as your client has paid',
            tooltip: 'Both your professional income and your copyrights',
          },
          {
            text: 'Your invoices\n<a href="https://knowledge.creativeshelter.be/kan-ik-mijn-facturen-automatisch-doorsturen-naar-mijn-accountant" target="_blank"> automatic forwarding</a> to your accountant/accounting package',
            tooltip: 'As a PDF or XML file',
          },
          {
            text: 'Withholding tax and preparation of the mandatory <a href="https://knowledge.creativeshelter.be/wat-is-de-fiscale-fiche-281.45-1" target="_blank"> annual tax sheet 281.45 </a>',
          },
          {
            text: 'Free legal assistance and professional liability insurance',
            tooltip:
              'Read more <a href="https://knowledge.creativeshelter.be/een-ba-verzekering-bescherm-jezelf-extra-dankzij-creative-shelter" target="_blank"> here</a>!',
          },
        ],
        '6': [
          {
            text: 'All the benefits of the Classic plan',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">Extra follow-up unpaid invoices </a> up to and including bailiff',
            tooltip: 'Bailiff is only appointed within Belgium',
          },
          {
            link: '/settings/general #custom -conditions',
            text: 'Tailor-made general terms and conditions',
          },
          {
            text: 'Link with external tools',
            tooltip: 'Billit and/or Cashaca',
          },
          {
            text: 'Free Billit licence',
            tooltip: "If you don't have a paid license yet",
          },
          {
            text: 'Priority support',
          },
        ],
        label: 'For this plan, you get:',
      },
      hideDetails: 'Hide details',
      select: 'Choose this plan',
      showDetails: 'Show details',
      subtitle: {
        '5': 'For those for whom the basics are enough',
        '6': 'For those who want the best service',
      },
    },
    plans: {
      sectionDescription: 'An overview of the different rate plans and the associated benefits and compensations.',
      sectionTitle: 'Pricing plans',
    },
    progress: {
      disclaimer: 'Progress is reset annually on {{startDate}}. The compensation above is without any discounts.',
      title: 'Total invoiced',
      tooltip: 'Subcontracting is not added to the total amount.',
      zeroProgress: 'Nothing yet, so let&#39;s start invoicing so you can fill your counter!',
    },
    requestPlanChangeModal: {
      cancel: 'Keep plan',
      confirmationDescription: "We'll contact you as soon as possible to review your change of plan.",
      confirmationDismiss: 'Understood!',
      confirmationTitle: 'Request sent',
      description:
        'You are currently using the {{CurrentPlan}} at a different (cheaper) rate. If you change your plan, this benefit will be lost. Are you sure you want to change your plan to {{NewPlan}}? Then request a change so that we can further review this with you.',
      requestChange: 'Request a change',
      title: 'Change plan',
    },
    tags: {
      PremiumForClassic: 'Premium at a Classic rate',
      PremiumForFree: '{{daysValid}} days free',
      copyrightLimitExceeded: 'Adjusted compensation',
      customPercentage: 'Adjusted compensation',
      discount: 'Referral discount',
      mostPopular: 'Most popular plan',
    },
  },
  profile: {
    activities: {
      description: 'Indicate here in which creative sector you operate.',
      modal: {
        description:
          'Have you checked whether your activities are still correct? You can find these settings afterward under your profile.',
        next: 'Next',
        previous: 'Previous',
        title: 'Confirm activities',
      },
      title: 'Activities',
      warnings: {
        stylingAndInterior: {
          body: 'From 2024, not all creative styling/interior design performances are still eligible for compensation of copyrights. The interpretation of the law is stricter than the law itself. Are you unsure whether your performance qualifies? <a href="mailto:administratie@creativeshelter.be?subject=Komen mijn prestaties voor styling/interieur nog in aanmerking?" target="_blank">Mail us </a> and we\'ll pick it up with you.\n\nYou remain eligible for other creative performances. ',
          title: 'Important! Stylist/interior designer, please read on.',
        },
      },
    },
    brands: {
      add: 'Add trademark',
      delete: {
        description: 'Are you sure you want to delete "{{brand}}"?',
        title: 'Remove trademark',
      },
      description: 'View, create and edit your trademarks',
      edit: 'Edit trademark',
      favorite: {
        markAsFavorite: 'Mark as favorite',
        removeFavorite: 'Remove as favorite',
      },
      headers: {
        color: 'Trademark color',
        email: 'E-mail address',
        logo: 'Logo',
        name: 'Your trademark name',
      },
      logoAdd: 'Add your logo',
      logoEdit: 'Edit your logo',
      mainBrand: 'Main trademark',
      title: 'Trademarks',
    },
    community: {
      blocker: 'Are you sure you want to leave the page? The changes to your Community profile have not been saved yet.',
      communityHidden:
        'Access to the Craft Community has been disabled for you. For more information, you can always send us an email </a> <a href="mailto:admin@creativeshelter.be?subject= Geblokkeerde toegang Craft Community" target="_blank">.',
      description: 'Edit your Community profile here',
      disableCommunityProfile: 'Delete your Community profile',
      enableCommunityProfile: 'Make your profile public in the Community',
      title: 'Community',
    },
    description: 'Change your Creative Shelter account settings',
    financial: {
      bank: 'Bank name',
      bic: 'BIC/Swift',
      description: 'Your banking and financial details',
      edit: 'Edit',
      iban: 'IBAN',
      title: 'Financial details',
    },
    info: {
      language: {
        en: 'English',
        fr: 'French',
        nl: 'Dutch',
      },
      refreshKboInformation: {
        action: 'Refresh information',
        lastUpdated: 'Updated {{timeAgo}} from the CBE',
        tooltip:
          'We retrieve the data directly from the <kboLink>Crossroads Bank for Enterprises</kboLink> (CBE for short). Are they no longer up-to-date? Then you can retrieve the latest data here. Only possible if the data is older than 7 days.',
      },
      subsections: {
        address: 'Address details',
        kbo: 'Company details',
        personal: 'Personal details',
      },
      title: 'Profile information',
    },
    password: {
      edit: 'Change password',
      title: 'Change password',
    },
    title: 'Profile',
    vatRegulation: {
      description: "Below you'll find additional information about your current VAT status.",
      edit: 'Edit',
      help: 'Indicate which VAT status you use. This determines whether VAT will be charged on your invoices. Small businesses with a turnover below €25,000 are entitled to the exemption regime and can apply for this themselves. More information can be found in our <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=en-US&_x_tr_pto=wapp" target="_blank">Knowledge Base</a>.',
      info: {
        none: "You haven't specified your VAT status yet.",
        vatExempt:
          'You make use of the <strong>VAT exemption regime</strong>. Invoices between you and Creative Shelter are made up without VAT. Follow up below to see how far you are from the limit.',
        vatNormal:
          'You make use of the <strong>regular VAT status</strong>. Invoices between you and Creative Shelter are issued with VAT.',
      },
      title: 'VAT status',
      toggle: {
        vatExempt: 'VAT-exempt',
        vatNormal: 'Subject to VAT',
      },
      tooltip: 'If your turnover exceeds € 25,000, you may no longer use the VAT exemption regime.',
      totalRevenueInYear: 'Total sales {{year}}',
    },
  },
  prompts: {
    verifyAddress: {
      change: {
        actions: {
          back: 'Back',
          mail: 'Mail us',
        },
        description: "Moved? Let us know! Send an email with your latest address details and we'll change it in your profile.",
        kboMessage:
          'Tip! Also provide your latest address details to the Crossroads Bank for Enterprises (via <myEnterpriseLink>My Enterprise</myEnterpriseLink>).',
        mailLink: 'mailto:administratie@creativeshelter.be?subject=I have a new address&body=Hey%0D%0A%0D%0AMy new address is',
        title: 'Please change address!',
      },
      verify: {
        actions: {
          confirm: 'Yes, correct!',
          edit: 'Not right yet, please adjust!',
          refresh: 'Retrieve data again',
        },
        description: 'Is your address still correct? The details below are entered in your profile, please double-check?',
        errors: {
          refreshKboFailed: 'Your data cannot currently be retrieved from the Crossroads Bank for Enterprises.',
        },
        title: 'Check your address quickly',
      },
    },
  },
};
