import { useMatch, useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useInvoices } from '~/api/invoices';
import { Redirect } from '~/components/Functional';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { getInvoiceStatus, InvoiceStatus } from '../utils';
import { CreateEditInvoiceForm } from './CreateEditInvoiceForm';

export const CreateEditInvoice = () => {
  const { data: invoices } = useInvoices();

  const { invoiceId } = useParams<Params<typeof routes.editInvoice>>();
  const invoice = invoices.find((invoice) => invoice.id === Number(invoiceId));

  const isCreateRoute = !!useMatch(routes.createInvoice);
  const isEditRoute = !!useMatch(routes.editInvoice);

  if (!invoice) return <Redirect to={routes.invoices} />;

  const status = getInvoiceStatus(invoice);
  const canCreate = invoice.draft;
  const canEdit = [InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status);

  if ((isCreateRoute && !canCreate) || (isEditRoute && !canEdit)) return <Redirect to={r(routes.showInvoice, { invoiceId: invoice.id })} />;

  return <CreateEditInvoiceForm invoice={invoice} isEdit={isEditRoute} />;
};
