import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import type { routes } from '~/providers/RouterProvider/router.routes';
import type { Params } from '~/providers/RouterProvider/router.types';
import type { InvoiceQuotationFormLine } from '~/types/invoiceQuotation';

import { useClients } from '~/api/clients';
import { useConditions } from '~/api/conditions';
import { useUser } from '~/api/user';
import { CommandMenuItem } from '~/providers/CommandMenuProvider/CommandMenuItem';
import { CreativeWork } from '~/types/app';

import type { CreateEditQuotationFormType } from './types';

const getRandom = <T,>(array: T[]): T | undefined => array[Math.floor(Math.random() * array.length)];

export const QuotationFormCommandMenuItems = () => {
  const { quotationId } = useParams<Params<typeof routes.createQuotation | typeof routes.editQuotation>>();

  const { data: clients } = useClients();
  const { data: conditions } = useConditions();

  const { data: user } = useUser();

  const { setValue } = useFormContext<CreateEditQuotationFormType>();

  return (
    <CommandMenuItem
      group="Quotation"
      label="Fill input fields"
      onClick={() => {
        const options = { shouldDirty: true, shouldTouch: true, shouldValidate: true };

        const title = `Quotation #${quotationId}`;
        const entryNumber = `OFF_${dayjs().unix()}`;
        const clientId = getRandom(clients.filter(({ deleted }) => !deleted))?.id ?? null;
        const contactId =
          getRandom(clients.find(({ id }) => id === clientId)?.contacts.filter(({ deleted }) => !deleted) ?? [])?.id ?? null;
        const expirationDate = dayjs()
          .add(Math.ceil(Math.random() * 30) + 14, 'days')
          .toDate();
        const brandId = getRandom([{ id: 0 }, ...user.brands])?.id ?? null;
        const conditionId = getRandom(conditions)?.id ?? null;
        const lines = Array.from({ length: Math.ceil(Math.random() * 3) }, (_, i) => {
          const creativeWork =
            i === 0
              ? CreativeWork.CREATIVE
              : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getRandom([CreativeWork.CREATIVE, CreativeWork.NO_CREATIVE, CreativeWork.REUSAGE, CreativeWork.SUBCONTRACTING])!;

          const price = `${Math.ceil(Math.random() * 50) * 10}`;
          const quantity = `${Math.ceil(Math.random() * 3)}`;

          return {
            type: 'entry',
            description: `Line ${i + 1}`,
            creativeWork,
            price,
            discountType: null,
            discountValue: null,
            quantity,
            unit: null,
            productId: null,
            productName: null,
            productStatus: null,
          } satisfies InvoiceQuotationFormLine;
        });
        const sections = [{ name: '', isPhotoAlbum: false, lines }];

        setValue('title', title, options);
        setValue('entryNumber', entryNumber, options);
        setValue('clientId', clientId, options);
        setValue('contactId', contactId, options);
        setValue('expirationDate', expirationDate, options);
        setValue('brandId', brandId, options);
        setValue('condition', conditionId ? { id: conditionId, type: 'condition' } : null, options);
        setValue('sections', sections, options);
      }}
    />
  );
};
