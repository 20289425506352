import * as stylex from '@stylexjs/stylex';

import { animationSpeed, fontSize, lineHeight, neutralColors, radius, spacing } from '~/styles/tokens.stylex';

const fadeInAnimation = stylex.keyframes({
  from: { opacity: 0, transform: 'scale(0.95)' },
  to: { opacity: 1, transform: 'scale(1)' },
});

export const styles = stylex.create({
  tooltip: {
    maxWidth: 'min(28rem, 90vw)',
    position: 'relative',
    padding: spacing.xsmall,
    borderRadius: radius.xxxsmall,
    color: neutralColors.white,
    backgroundColor: neutralColors.gray700,
    fontSize: fontSize.small,
    lineHeight: lineHeight.snug,
    textAlign: 'center',
    textWrap: 'balance',
    overflowWrap: 'break-word',
    isolation: 'isolate',
    animationName: fadeInAnimation,
    animationTimingFunction: 'ease-in',
    animationDuration: animationSpeed.veryFast,
  },

  arrow: {
    width: 12,
    height: 12,
    position: 'absolute',
    borderTopLeftRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: neutralColors.gray700,
    transform: 'rotate(45deg)',
    pointerEvents: 'none',
    zIndex: -1,
  },

  arrowHorizontal: {
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
  },
});
