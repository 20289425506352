import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import type { CreateOrganisationClientPayload, EuropeanVatNumber } from '~/api/clients/types';
import type { EuVatCountryCode } from '~/api/common/types';

import { useFetchVatInformation } from '~/api/clients';
import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput, Label } from '~/components';
import { Blocker } from '~/components/Functional';
import { europeanUnionVatCountryCodes } from '~/constants/countries';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { includes } from '~/utils/arrays';

import type {
  CreateEuropeanOrganisationWithVatNumberProps,
  EuropeanOrganisationWithVatNumberFormType,
  CreateEuropeanOrganisationWithVatNumberFormProps as Props,
  SearchEuVatNumberFormType,
} from './types';

import { FormAddressCity } from '../../shared/FormAddressCity';
import { FormAddressNumber } from '../../shared/FormAddressNumber';
import { FormAddressStreet } from '../../shared/FormAddressStreet';
import { FormAddressZipCode } from '../../shared/FormAddressZipCode';
import { FormCompanyName } from '../../shared/FormCompanyName';
import { mapVatCountryCodeToCountryCode } from '../../utils';
import { useCreateClientContext } from '../context';
import { useCreateOrganisationContext } from './context';
import { useGetDuplicateClient, useIsBlocked } from './hooks';
import { FormVatNumberField, ReadOnlyVatNumberField } from './shared/FormVatNumberField';
import { SwitchNumberType } from './shared/SwitchNumberType';
import { VatCountryCodeSelect } from './shared/VatCountryCodeSelect';

export const CreateEuropeanOrganisationWithVatNumber = ({ switchToOtherNumber }: CreateEuropeanOrganisationWithVatNumberProps) => {
  const fetchVatInformation = useFetchVatInformation();
  const { organisation, resetClientContext, setOrganisation } = useCreateClientContext();
  const { vatCountryCode } = useCreateOrganisationContext();

  invariant(includes(europeanUnionVatCountryCodes, vatCountryCode), 'Expected selected country to be part of the European Union');

  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation(['clients', 'validation']);

  const { control, getValues, handleSubmit, setError } = useForm<SearchEuVatNumberFormType>({
    defaultValues: {
      vatNumberWithoutCountryCode: organisation?.vatNumber?.slice(2) ?? '',
    },
  });

  const isBlocked = useIsBlocked(control);

  const getDuplicateClient = useGetDuplicateClient();

  const onSubmit = handleSubmit((data) => {
    if (fetchVatInformation.isPending) return;

    fetchVatInformation.mutate(`${vatCountryCode}${data.vatNumberWithoutCountryCode}`, {
      onSuccess: ({ vatValid }) => {
        if (vatValid) return;

        setError('vatNumberWithoutCountryCode', { type: 'validVat' });
        fetchVatInformation.reset();
      },
      onError: () => {
        toast.error(t('clients:alerts.errorViesInformation'));
      },
    });
  });

  if ((fetchVatInformation.isSuccess && fetchVatInformation.data.source === 'VIES') || organisation) {
    const { data: vatInformation } = fetchVatInformation;

    if (vatInformation) invariant(vatInformation.vatValid, 'Expected valid VAT number');

    return (
      <CreateEuropeanOrganisationForm
        onCancel={() => {
          fetchVatInformation.reset();
          resetClientContext();
        }}
        onNext={(data) => {
          setOrganisation({ clientType: 'organisation', ...data });
          navigate({ pathname: routes.createClientContactStep, search }, { state: { blockable: false } });
        }}
        organisation={
          vatInformation
            ? {
                vatNumber: vatInformation.vatNumber,
                companyName: vatInformation.companyName,
                address: {
                  street: null,
                  number: null,
                  zipCode: null,
                  city: null,
                  countryCode: mapVatCountryCodeToCountryCode(vatCountryCode),
                },
              }
            : (organisation as CreateOrganisationClientPayload['client'] & { vatNumber: EuropeanVatNumber })
        }
      />
    );
  }

  return (
    <>
      <Blocker isBlocked={isBlocked} message={t('clients:create.blocker')} />

      <form className="grid gap-4 grid-cols-3" onSubmit={onSubmit}>
        <div className="col-span-full lg:col-span-1">
          <VatCountryCodeSelect />
        </div>

        <div className="col-span-full lg:col-span-2 flex flex-col lg:flex-row gap-4">
          <div className="flex-grow">
            <FormControl
              control={control}
              name="vatNumberWithoutCountryCode"
              rules={{ required: true, validate: { unique: (value) => !getDuplicateClient(vatCountryCode, value) } }}
            >
              <FormLabel>{t('clients:fields.vatNumber.label')}</FormLabel>
              <FormVatNumberField countryCode={vatCountryCode} />
              <FormErrorMessage
                required={t('validation:required')}
                unique={() => {
                  const duplicateClient = getDuplicateClient(vatCountryCode, getValues('vatNumberWithoutCountryCode'));

                  if (!duplicateClient) return null;

                  return (
                    <Trans
                      components={[
                        <Link key={null} state={{ blockable: false }} to={r(routes.createContact, { clientId: duplicateClient.id })} />,
                      ]}
                      i18nKey="validation:vatNumber.unique"
                    />
                  );
                }}
                validVat={t('validation:vatNumber.invalid')}
              />
            </FormControl>
          </div>

          <Button
            extraClasses="lg:mt-[30px] h-10 self-end lg:self-start"
            hasSpinner
            icon="Search"
            isLoading={fetchVatInformation.isPending}
            isSubmit
          >
            {t('clients:create.company.searchCompanyInformation')}
          </Button>
        </div>
      </form>

      <SwitchNumberType i18nKey="clients:create.company.switchToOtherNumber" onSwitch={switchToOtherNumber} />
    </>
  );
};

const CreateEuropeanOrganisationForm = ({ onCancel, onNext, organisation }: Props) => {
  const { control, handleSubmit } = useForm<EuropeanOrganisationWithVatNumberFormType>({
    defaultValues: {
      vatNumber: organisation.vatNumber,
      otherNumber: organisation.otherNumber ?? '',
      companyName: organisation.companyName ?? '',
      address: {
        street: organisation.address.street ?? '',
        number: organisation.address.number ?? '',
        zipCode: organisation.address.zipCode ?? '',
        city: organisation.address.city ?? '',
        countryCode: organisation.address.countryCode,
      },
    },
  });

  const { t } = useTranslation(['clients', 'validation']);

  return (
    <div>
      <Blocker isBlocked message={t('clients:create.blocker')} />

      <div className="grid gap-4 grid-cols-3">
        <div className="col-span-full lg:col-span-1">
          <VatCountryCodeSelect disabled />
        </div>

        <div className="col-span-full lg:col-span-2">
          <Label>{t('clients:fields.vatNumber.label')}</Label>
          <ReadOnlyVatNumberField
            countryCode={organisation.vatNumber.slice(0, 2) as EuVatCountryCode}
            vatNumber={organisation.vatNumber.slice(2)}
          />
        </div>
      </div>

      <div className="my-6">
        <Button icon="Undo" onClick={onCancel} type="tertiary">
          {t('clients:create.company.changeVatNumber')}
        </Button>
      </div>

      <form className="grid gap-4 grid-cols-3" onSubmit={handleSubmit(onNext)}>
        <FormCompanyName className="col-span-full lg:col-span-2" control={control} />
        <div className="col-span-full lg:col-span-1">
          <FormControl control={control} name="otherNumber">
            <FormLabel optional>{t('clients:fields.otherNumber.label')}</FormLabel>
            <FormTextInput />
          </FormControl>
        </div>
        <FormAddressStreet className="col-span-full lg:col-span-2" control={control} />
        <FormAddressNumber className="col-span-full lg:col-span-1" control={control} />
        <FormAddressZipCode className="col-span-full lg:col-span-1" control={control} />
        <FormAddressCity className="col-span-full lg:col-span-2" control={control} />

        <div className="col-span-full justify-self-end">
          <Button isSubmit>{t('clients:create.next')}</Button>
        </div>
      </form>
    </div>
  );
};
