import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  addressWrapper: {
    marginBlockEnd: spacing.xxsmall,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
  },

  address: {
    padding: spacing.small,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
  },

  lastUpdated: {
    textAlign: 'right',
    color: systemColors.textMuted,
  },
});
