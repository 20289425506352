import { Trans, useTranslation } from 'react-i18next';

import { Button } from '~/components/Button/Button';
import { Modal } from '~/components/Modal/Modal';
import { ExternalLink, Text } from '~/components/UI';
import { noop } from '~/utils/functions';

type Props = {
  onConfirm: () => void;
  onGoBack: () => void;
};

export const RequestAddressChangeModal = ({ onConfirm, onGoBack }: Props) => {
  const { t } = useTranslation(['settings']);

  return (
    <Modal modal onClose={noop}>
      <Modal.Title>{t('settings:prompts.verifyAddress.change.title')}</Modal.Title>

      <Text>{t('settings:prompts.verifyAddress.change.description')}</Text>

      <Text>
        <Trans
          components={{ myEnterpriseLink: <ExternalLink href="https://www.myenterprise.be/" /> }}
          i18nKey="settings:prompts.verifyAddress.change.kboMessage"
        />
      </Text>

      <Modal.Actions>
        <Button onClick={onGoBack} type="secondary">
          {t('settings:prompts.verifyAddress.change.actions.back')}
        </Button>
        <Button
          onClick={() => {
            window.open(t('settings:prompts.verifyAddress.change.mailLink'), '_blank');
            onConfirm();
          }}
        >
          {t('settings:prompts.verifyAddress.change.actions.mail')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
