import { useNavigate } from 'react-router';

import type { User } from '~/types/user';

import { CommandMenuItem } from '~/providers/CommandMenuProvider/CommandMenuItem';
import { routes } from '~/providers/RouterProvider/router.routes';
import { Activity } from '~/types/user';

import type {
  RegistrationAccountInformation,
  RegistrationBusinessInformation,
  RegistrationReferralCodeInformation,
  RegistrationVatInformation,
} from '../Registration.context';

import { useRegistrationContext } from '../Registration.context';
import { availableActivities } from '../RegistrationBusinessInformation.utils';

const dummyVatInformation = {
  source: 'KBO',
  vatNumber: 'BE0123456789',
  vatValid: true,
  vatActive: true,
  companyName: 'Creative Freelancer',
  enterpriseType: 0,
  establishmentUnitsCount: 1,
  establishmentUnits: [
    {
      type: 'headquarters',
      street: 'Adolf Baeyensstraat',
      number: '134/F',
      zipCode: '9040',
      city: 'Gent',
      countryCode: 'BE',
    },
  ],
  founder: {
    firstName: 'Creative',
    lastName: 'Freelancer',
  },
  hasEligibleNacebelCodes: true,
  fetchedAt: Date.now(),
} satisfies RegistrationVatInformation;

const dummyAccountInformation = {
  emailAddress: 'test@example.com',
  password: 'password',
  callingCode: '32',
  phoneNumber: '0412345678',
} satisfies RegistrationAccountInformation;

const dummyBusinessInformation = {
  mainOccupation: true,
  primaryActivity: Activity.PHOTOGRAPHY,
  secondaryActivities: Object.fromEntries(availableActivities.map((activity) => [`a${activity}`, false])) as Record<
    `a${Activity}`,
    boolean
  >,
  otherActivityInformation: '',
} satisfies RegistrationBusinessInformation;

const dummyReferralCodeInformation = {
  code: 'TEST123',
  invitorName: 'John Doe',
  invitorType: 'user',
} as RegistrationReferralCodeInformation;

const dummyEligibleUser = {
  email: 'test@eample.com',
  eligible: true,
} as User; // Only the fields used in RegistrationSuccess

const dummyIneligibleUser = {
  ...dummyEligibleUser,
  eligible: false,
};

export const RegistrationCommandMenuItems = () => {
  const {
    clearRegistrationInformation,
    setAccountInformation,
    setBusinessInformation,
    setReferralCodeInformation,
    setUser,
    setVatInformation,
  } = useRegistrationContext();

  const navigate = useNavigate();

  return (
    <>
      <CommandMenuItem
        group="Registration"
        label="Go to step 1"
        onClick={() => {
          setVatInformation(dummyVatInformation);
          navigate(routes.register);
        }}
        shortcut="1"
      />

      <CommandMenuItem
        group="Registration"
        label="Go to step 2"
        onClick={() => {
          setVatInformation(dummyVatInformation);
          navigate(routes.registerAccountInformation);
        }}
        shortcut="2"
      />

      <CommandMenuItem
        group="Registration"
        label="Go to step 3"
        onClick={() => {
          setVatInformation(dummyVatInformation);
          setAccountInformation(dummyAccountInformation);
          navigate(routes.registerBusinessInformation);
        }}
        shortcut="3"
      />

      <CommandMenuItem
        group="Registration"
        label="Go to success page (eligible)"
        onClick={() => {
          setVatInformation(dummyVatInformation);
          setAccountInformation(dummyAccountInformation);
          setBusinessInformation(dummyBusinessInformation);
          setUser(dummyEligibleUser);

          navigate(routes.registerSuccess);
        }}
      />

      <CommandMenuItem
        group="Registration"
        label="Go to success page (ineligible)"
        onClick={() => {
          setVatInformation(dummyVatInformation);
          setAccountInformation(dummyAccountInformation);
          setBusinessInformation(dummyBusinessInformation);
          setUser(dummyIneligibleUser);

          navigate(routes.registerSuccess);
        }}
      />

      <CommandMenuItem
        group="Registration"
        label="Apply referral code"
        onClick={() => {
          setReferralCodeInformation(dummyReferralCodeInformation);
        }}
      />

      <CommandMenuItem
        group="Registration"
        label="Reset information"
        onClick={() => {
          clearRegistrationInformation();
          navigate(routes.register);
        }}
      />
    </>
  );
};
