import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useRefreshKboInformation, useUser } from '~/api/user';
import { Button } from '~/components/Button/Button';
import { Modal } from '~/components/Modal/Modal';
import { Alert, AlertTitle, Text } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { selectBestRelativeTimeFormat } from '~/utils/dates';
import { noop } from '~/utils/functions';

import { styles } from './VerifyAddressModal.styles';

type Props = {
  onConfirm: () => void;
  onReject: () => void;
};

export const VerifyAddressModal = ({ onConfirm, onReject }: Props) => {
  const { data: user } = useUser();
  const mutation = useRefreshKboInformation();

  const { t } = useTranslation(['settings']);
  const { formatRelativeTime } = useIntl();

  const mayRefreshKboInformation = dayjs().diff(user.kboInformationLastUpdatedAt, 'days') >= 7;

  return (
    <Modal modal onClose={noop}>
      <Modal.Title>{t('settings:prompts.verifyAddress.verify.title')}</Modal.Title>

      <Text>{t('settings:prompts.verifyAddress.verify.description')}</Text>

      <div {...stylex.props(styles.addressWrapper)}>
        <div {...stylex.props(styles.address)}>
          <Text>
            {user.address.street} {user.address.number}
          </Text>
          <Text>
            {user.address.zipCode} {user.address.city}
          </Text>
        </div>

        <Text italic size="tiny" styles={styles.lastUpdated}>
          <time dateTime={user.kboInformationLastUpdatedAt}>
            {t('settings:profile.info.refreshKboInformation.lastUpdated', {
              timeAgo: formatRelativeTime(...selectBestRelativeTimeFormat(user.kboInformationLastUpdatedAt)),
            })}
          </time>
        </Text>
      </div>

      {mutation.isError && (
        <Alert variant="error">
          <AlertTitle>{t('settings:prompts.verifyAddress.verify.errors.refreshKboFailed')}</AlertTitle>
        </Alert>
      )}

      <Modal.Actions>
        {mayRefreshKboInformation && !mutation.isError ? (
          <Button hasSpinner icon="Sync" isLoading={mutation.isPending} onClick={() => mutation.mutate()} type="secondary">
            {t('settings:prompts.verifyAddress.verify.actions.refresh')}
          </Button>
        ) : (
          <Button icon="Edit" onClick={onReject} type="secondary">
            {t('settings:prompts.verifyAddress.verify.actions.edit')}
          </Button>
        )}

        <Button icon="Check" onClick={onConfirm}>
          {t('settings:prompts.verifyAddress.verify.actions.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
