import * as amplitude from '@amplitude/analytics-browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router';

import type { Activity } from '~/types/user';

import { useFetchInvitations, useFetchReferralCodeInformation } from '~/api/auth';
import { env } from '~/constants/env';
import { SearchParamKeys } from '~/constants/url';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { removeSearchParams } from '~/utils/searchParams';

import type { RegistrationReferralCodeInformation } from './Registration.context';
import type { AccountInformationFormFields } from './RegistrationAccountInformation.types';
import type { BusinessInformationFormFields } from './RegistrationBusinessInformation.types';

import { availableActivities } from './RegistrationBusinessInformation.utils';

export const useAmplitude = () => {
  useEffect(() => {
    if (!env.IS_PRODUCTION_ENV || !env.AMPLITUDE_API_KEY) return;

    amplitude.init(env.AMPLITUDE_API_KEY, {
      defaultTracking: {
        attribution: true,
        pageViews: { trackHistoryChanges: 'pathOnly' },
        sessions: true,
        formInteractions: true,
        fileDownloads: false,
      },
    });
    amplitude.setOptOut(false);

    return () => {
      // Stop tracking when the leaving the registration
      amplitude.setOptOut(true);
    };
  }, []);
};

export const useVatInformation = () => {
  const [vatInformation, setVatInformation] = useCraftLocalStorage('registrationVatInformation');

  const clearVatInformation = useCallback(() => {
    setVatInformation(null);
  }, [setVatInformation]);

  return useMemo(
    () => ({
      vatInformation,
      setVatInformation,
      clearVatInformation,
    }),
    [clearVatInformation, setVatInformation, vatInformation],
  );
};

const defaultAccountInformation = {
  emailAddress: '',
  password: '',
  callingCode: '32',
  phoneNumber: '',
} satisfies AccountInformationFormFields;

export const useAccountInformation = () => {
  const [searchParams] = useSearchParams();
  const searchParamsEmailAddress = searchParams.get(SearchParamKeys.REGISTRATION_EMAIL_ADDRESS);
  const searchParamsPhoneNumber = searchParams.get(SearchParamKeys.REGISTRATION_PHONE_NUMBER);

  const [storedAccountInformation, setStoredAccountInformation] = useCraftLocalStorage('registrationAccountInformation');
  const [accountInformation, setAccountInformation] = useState<AccountInformationFormFields>({
    emailAddress: storedAccountInformation?.emailAddress || searchParamsEmailAddress || defaultAccountInformation.emailAddress,
    password: defaultAccountInformation.password,
    callingCode: storedAccountInformation?.callingCode || defaultAccountInformation.callingCode,
    phoneNumber:
      storedAccountInformation?.phoneNumber ||
      (searchParamsPhoneNumber?.startsWith('+32') ? searchParamsPhoneNumber.slice(3).trim() : searchParamsPhoneNumber) ||
      defaultAccountInformation.phoneNumber,
  });

  const clearAccountInformation = useCallback(() => {
    setAccountInformation(defaultAccountInformation);
    setStoredAccountInformation(null);
  }, [setStoredAccountInformation]);

  useEffect(() => {
    if (accountInformation !== defaultAccountInformation) {
      const { password, ...rest } = accountInformation;
      setStoredAccountInformation(rest);
    }
  }, [accountInformation, setStoredAccountInformation]);

  return useMemo(
    () => ({
      accountInformation,
      setAccountInformation,
      clearAccountInformation,
    }),
    [accountInformation, clearAccountInformation],
  );
};

const defaultBusinessInformation = {
  mainOccupation: null,
  primaryActivity: null,
  secondaryActivities: Object.fromEntries(availableActivities.map((activity) => [`a${activity}`, false])) as Record<
    `a${Activity}`,
    boolean
  >,
  otherActivityInformation: '',
} satisfies BusinessInformationFormFields;

export const useBusinessInformation = () => {
  const [storedBusinessInformation, setStoredBusinessInformation] = useCraftLocalStorage('registrationBusinessInformation');
  const [businessInformation, setBusinessInformation] = useState<BusinessInformationFormFields>({
    mainOccupation: (storedBusinessInformation ?? defaultBusinessInformation).mainOccupation,
    primaryActivity: (storedBusinessInformation ?? defaultBusinessInformation).primaryActivity,
    secondaryActivities: (storedBusinessInformation ?? defaultBusinessInformation).secondaryActivities,
    otherActivityInformation: (storedBusinessInformation ?? defaultBusinessInformation).otherActivityInformation,
  });

  const clearBusinessInformation = useCallback(() => {
    setBusinessInformation(defaultBusinessInformation);
    setStoredBusinessInformation(null);
  }, [setStoredBusinessInformation]);

  useEffect(() => {
    if (businessInformation !== defaultBusinessInformation) setStoredBusinessInformation(businessInformation);
  }, [businessInformation, setStoredBusinessInformation]);

  return useMemo(
    () => ({
      businessInformation,
      setBusinessInformation,
      clearBusinessInformation,
    }),
    [businessInformation, clearBusinessInformation],
  );
};

export const useReferralCode = (emailAddress: string) => {
  const { isSuccess: isFetchReferralCodeInformationSuccess, mutate: fetchReferralCodeInformation } = useFetchReferralCodeInformation();
  const { mutate: fetchInvitations } = useFetchInvitations();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsReferralCode = searchParams.get(SearchParamKeys.REGISTRATION_REFERRAL_CODE);

  const [referralCodeInformation, setReferralCodeInformation] = useCraftLocalStorage('registrationReferralCodeInformation');

  const clearReferralCodeInformation = useCallback(() => {
    setReferralCodeInformation(null);
  }, [setReferralCodeInformation]);

  // Fetch referral code information from search param
  useEffect(() => {
    if (!searchParamsReferralCode) return;

    // Ensure the mutation only runs once. If not, the mutation would run again when the user deletes the applied referral code.
    if (isFetchReferralCodeInformationSuccess) return;

    fetchReferralCodeInformation(searchParamsReferralCode, {
      onSuccess: (referralCodeInformation) => setReferralCodeInformation((prev) => prev ?? referralCodeInformation),
      onError: () => setSearchParams(removeSearchParams(SearchParamKeys.REGISTRATION_REFERRAL_CODE)),
    });
  }, [
    fetchReferralCodeInformation,
    isFetchReferralCodeInformationSuccess,
    referralCodeInformation,
    searchParamsReferralCode,
    setReferralCodeInformation,
    setSearchParams,
  ]);

  // Fetch the invitations for the provided email adress
  useEffect(() => {
    if (!emailAddress) return;

    // Prevent overlap with the search param referral code.
    // If the search param referral code is invalid, it will be deleted and this effect will run.
    if (searchParamsReferralCode) return;

    fetchInvitations(emailAddress, {
      onSuccess: (invitations) => {
        const mostRecentInvitation = invitations[0];
        if (mostRecentInvitation) setReferralCodeInformation((prev) => prev ?? mostRecentInvitation.referralCode);
      },
    });
  }, [emailAddress, fetchInvitations, searchParamsReferralCode, setReferralCodeInformation]);

  return useMemo(
    () => ({
      referralCodeInformation,
      setReferralCodeInformation,
      clearReferralCodeInformation,
    }),
    [clearReferralCodeInformation, referralCodeInformation, setReferralCodeInformation],
  );
};

export const useReferralCodeForm = (referralCodeInformation: RegistrationReferralCodeInformation | null) => {
  const [showReferralCodeForm, setShowReferralCodeForm] = useState(false);

  // On step 1 and 2 of the registration, we don't want to show the add referral code button,
  // unless there already was a referral code active and the user removed it.
  const [hadReferralCode, setHadReferralCode] = useState(!!referralCodeInformation);

  const location = useLocation();

  // Close the form when navigating between steps
  useEffect(() => {
    setShowReferralCodeForm(false);
  }, [location.pathname]);

  return useMemo(
    () => ({ showReferralCodeForm, setShowReferralCodeForm, hadReferralCode, setHadReferralCode }),
    [hadReferralCode, showReferralCodeForm],
  );
};
