import { Link } from 'react-router';

import type { BreadcrumbProps as Props } from './types';

import styles from './Breadcrumbs.module.scss';

export const Breadcrumb = ({ children, to }: Props) => {
  if (!to) return <li className={styles.Breadcrumb}>{children}</li>;

  return (
    <li className={styles.Breadcrumb}>
      <Link to={to}>{children}</Link>
    </li>
  );
};
