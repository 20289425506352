import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import type { MutuallyExclusive } from '~/types/utils';

import { useCreateCreditNote, useCreditNotes } from '~/api/creditNotes';
import { useInvoices } from '~/api/invoices';
import { useUser } from '~/api/user';
import {
  Button,
  FloatingActionButton,
  FormCombobox,
  FormControl,
  FormErrorMessage,
  FormHelpText,
  FormLabel,
  FormSelect,
  FormTextArea,
  PageHeader,
  Warning,
} from '~/components';
import { AnchorTargets, SearchParamKeys, SearchParamModals } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { useAwaitableModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';
import { generateEntryNumber } from '~/utils/string';

import type { CreditNoteConfirmationData } from '../Modals/types';
import type { CreateCreditNoteFormType, CreateCreditNoteFormProps as Props } from './types';

import { CreditNoteConfirmationModal } from '../Modals/CreditNoteConfirmationModal';
import { isDuplicateEntryNumber } from '../utils';

export const CreateCreditNoteForm = ({ invoiceId, nonCreditedInvoices }: Props) => {
  const { data: user } = useUser();
  const { numberingSeries } = user;
  const { data: invoices } = useInvoices();
  const { data: creditNotes } = useCreditNotes();
  const mutation = useCreateCreditNote();

  // i18n
  const { t } = useTranslation(['common', 'creditnotes', 'settings', 'validation']);

  // React router
  const navigate = useNavigate();

  // React hook form
  const { control, handleSubmit, setValue, watch } = useForm<CreateCreditNoteFormType>({
    defaultValues: {
      invoiceId,
      numberingSeriesId: numberingSeries.find((numberingSeries) => numberingSeries.enabledForCreditNotes)?.id || null,
      reason: '',
    },
  });
  const watchInvoiceId = watch('invoiceId');
  const watchNumberingSeriesId = watch('numberingSeriesId');

  // Invoice paid amounts
  const invoicePaidAmounts = invoices.find((invoice) => invoice.id === watchInvoiceId)?.paidAmounts ?? [];
  const { formatConjunctiveList, formatCurrency } = useIntl();

  // Numbering series
  const invoiceNumberingSeriesId = invoices.find((invoice) => invoice.id === watchInvoiceId)?.numberingSeriesId;
  const invoiceNumberingSeries = numberingSeries.find((series) => series.id === invoiceNumberingSeriesId);
  const selectedNumberingSeries = numberingSeries.find((series) => series.id === watchNumberingSeriesId);

  // Local state
  const numberingSeriesOptions = useMemo(
    () => numberingSeries.filter((series) => series.enabledForCreditNotes).map((series) => ({ value: series.id, label: series.name })),
    [numberingSeries],
  );
  const confirmationModal = useAwaitableModal<MutuallyExclusive<{ confirmed: true } & CreditNoteConfirmationData, { confirmed: false }>>();

  /** Auto select the numbering series id based on the selected invoice */
  useEffect(() => {
    if (invoiceNumberingSeries?.enabledForCreditNotes) setValue('numberingSeriesId', invoiceNumberingSeries.id);
  }, [invoiceNumberingSeries, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    const { confirmed, entryNumber, nextNumber } = await confirmationModal.open();

    if (!confirmed) return;

    if (isDuplicateEntryNumber(entryNumber, invoices, creditNotes)) {
      return toast.error(t('creditnotes:alerts.duplicateEntryNumber'));
    }

    invariant(data.invoiceId, 'Expected invoiceId to be defined.');
    invariant(data.numberingSeriesId, 'Expected numberingSeriesId to be defined.');

    mutation.mutate(
      {
        invoiceId: data.invoiceId,
        numberingSeriesId: data.numberingSeriesId,
        reason: data.reason,
        entryNumber,
        nextNumber,
      },
      {
        onSuccess: (creditNote) => {
          toast.success(t('creditnotes:alerts.successCreated'));
          // Technically, this redirect is not necessary. Creating the credit note triggers an invoice refetch,
          // which updates the invoice status to credited. This in turn triggers a redirect in <CreateCreditNote />.
          navigate(r(routes.showInvoice, { invoiceId: creditNote.invoiceId }));
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <>
      <PageHeader backRoute={routes.invoices} title={t('creditnotes:create.title')}>
        <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit onClick={onSubmit}>
          {t('creditnotes:create.submit')}
        </Button>
      </PageHeader>

      <form autoComplete="off" onSubmit={onSubmit}>
        <div className="form-grid-2 grid-flow-row-dense">
          {/* Invoice id */}
          <FormControl control={control} name="invoiceId" rules={{ required: true }}>
            <FormLabel>{t('creditnotes:fields.invoiceId.label')}</FormLabel>
            <FormCombobox
              options={nonCreditedInvoices.map((invoice) => ({ value: invoice.id, label: invoice.entryNumber }))}
              placeholder={t('creditnotes:fields.invoiceId.placeholder')}
            />
            <FormErrorMessage required={t('validation:required')} />
          </FormControl>

          {/* Partial payments warning */}
          {invoicePaidAmounts.length > 0 && (
            <div className="col-span-full">
              <Warning title={t('creditnotes:create.partialPaymentsWarning.title')}>
                {t('creditnotes:create.partialPaymentsWarning.message', {
                  count: invoicePaidAmounts.length,
                  payment: formatConjunctiveList(invoicePaidAmounts.map(({ amount }) => formatCurrency(amount))),
                })}
              </Warning>
            </div>
          )}

          {/* Numbering series */}
          <FormControl control={control} name="numberingSeriesId" rules={{ required: true }}>
            <FormLabel
              tooltip={
                <Trans
                  components={{ a: <a /> }}
                  i18nKey={
                    numberingSeriesOptions.length > 0
                      ? 'creditnotes:fields.numberingSeriesId.labelTooltip'
                      : 'creditnotes:fields.numberingSeriesId.tooltipCreate'
                  }
                />
              }
            >
              {t('creditnotes:fields.numberingSeriesId.label')}
            </FormLabel>
            <FormSelect
              Footer={
                <Button
                  extraClasses="relative after:absolute after:inset-x-0 after:-inset-y-2" // Increase vertical hitbox to cover padding area
                  icon="Add"
                  onClick={() =>
                    navigate({
                      pathname: routes.settingsGeneral,
                      search: qs({ [SearchParamKeys.OPEN_MODAL]: SearchParamModals.ADD_NUMBERING_SERIES }),
                      hash: AnchorTargets.GENERAL_NUMBERING_SERIES,
                    })
                  }
                  type="tertiary"
                >
                  {t('settings:general.numberingSeries.add')}
                </Button>
              }
              options={numberingSeriesOptions}
              placeholder={t('creditnotes:fields.numberingSeriesId.placeholder')}
            />
            {selectedNumberingSeries && (
              <FormHelpText tooltip={t('creditnotes:fields.numberingSeriesId.tooltip')}>
                <Trans
                  components={[<strong className="text-dark-gray" key={null} />]}
                  i18nKey="creditnotes:fields.numberingSeriesId.helpText"
                  values={{ entryNumber: generateEntryNumber(selectedNumberingSeries) }}
                />
              </FormHelpText>
            )}
            <FormErrorMessage required={t('validation:required')} />
          </FormControl>

          {/* Reason */}
          <div className="col-span-full">
            <FormControl control={control} name="reason" rules={{ required: true, maxLength: 2000 }}>
              <FormLabel>{t('creditnotes:fields.reason.label')}</FormLabel>
              <FormTextArea placeholder={t('creditnotes:fields.reason.placeholder')} rows={1} />
              <FormErrorMessage
                maxLength={t('validation:maxLength', { attribute: t('creditnotes:fields.reason.label'), max: 2000 })}
                required={t('validation:required')}
              />
            </FormControl>
          </div>
        </div>
      </form>

      {/* Confirmation modal */}
      {confirmationModal.isOpen && selectedNumberingSeries && (
        <CreditNoteConfirmationModal
          disabled={mutation.isPending}
          numberingSeries={selectedNumberingSeries}
          onClose={() => confirmationModal.closeWithResult({ confirmed: false })}
          onConfirm={(data) => confirmationModal.closeWithResult({ confirmed: true, ...data })}
        />
      )}

      {/* FAB */}
      <FloatingActionButton
        icon="Send"
        options={[
          {
            text: t('creditnotes:create.submit'),
            onClick: onSubmit,
            disabled: mutation.isPending,
          },
        ]}
      />
    </>
  );
};
