import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useInvoices } from '~/api/invoices';
import { PlanId } from '~/api/plans/constants';
import { Button, CollapsibleSection, DropdownMenu, FloatingActionButton, Modal, PreviewIFrame } from '~/components';
import { Redirect } from '~/components/Functional';
import { AdvantageSimulationModal } from '~/components/Templates/InvoiceQuotation';
import { Breadcrumbs, CoDocumentStatus, PageTitle } from '~/components/UI';
import { SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';
import { routes } from '~/providers/RouterProvider/router.routes';
import { includes, insertIf } from '~/utils/arrays';

import type { InnerInvoiceDetailsProps as Props } from './types';

import { useOnCreditInvoice, useOnDownloadInvoice, useOnDuplicateInvoice, useOnEditInvoice, useOnPreviewInvoice } from '../invoices.hooks';
import { InvoiceAlreadyApprovedModal } from '../Modals/InvoiceAlreadyApprovedModal/InvoiceAlreadyApprovedModal';
import { InvoiceConfirmEditModal } from '../Modals/InvoiceConfirmEditModal/InvoiceConfirmEditModal';
import { getInvoiceStatus, InvoiceStatus } from '../utils';
import { ClientInfoCard } from './ClientInfoCard/ClientInfoCard';
import { InvoiceHistory } from './InvoiceHistory/InvoiceHistory';
import { InvoiceStatusInfo } from './InvoiceStatusInfo/InvoiceStatusInfo';
import { StartFollowUpModal } from './Modals/StartFollowUpModal';
import { PaymentFollowUpDetails } from './PaymentFollowUpDetails/PaymentFollowUpDetails';
import { CoPaymentInfoCard } from './PaymentInfoCard/CoPaymentInfoCard';
import { FcPaymentInfoCard } from './PaymentInfoCard/FcPaymentInfoCard';

export const InvoiceDetailGuard = () => {
  const { data: invoices } = useInvoices();
  const { invoiceId } = useParams<Params<typeof routes.showInvoice>>();

  const invoice = invoices.find((invoice) => invoice.id === Number(invoiceId));

  if (!invoice || invoice.draft) return <Redirect to={routes.invoices} />;

  return <InvoiceDetail invoice={invoice} />;
};

const InvoiceDetail = ({ invoice }: Props) => {
  const { alreadyApprovedModal, confirmEditModal, mutation: acquireLockMutation, onEdit } = useOnEditInvoice(invoice);
  const { mutation: previewMutation, onPreview } = useOnPreviewInvoice(invoice);
  const { mutation: duplicateMutation, onDuplicate } = useOnDuplicateInvoice(invoice);
  const { mutation: downloadMutation, onDownload } = useOnDownloadInvoice(invoice);
  const { onCredit } = useOnCreditInvoice(invoice);

  const status = getInvoiceStatus(invoice);

  const canStartPaymentFollowUp =
    [InvoiceStatus.EXPIRED, InvoiceStatus.CO_PARTIALLY_PAID].includes(status) &&
    dayjs().subtract(3, 'weeks').isAfter(invoice.paymentDueDate) &&
    !invoice.paymentFollowUp &&
    includes([PlanId.earlyAdopter, PlanId.premium], invoice.planId);

  const advantageModal = useModal();
  const startFollowUpModal = useModal(useShouldOpenModal(SearchParamModals.START_FOLLOW_UP) && canStartPaymentFollowUp);

  const { t } = useTranslation(['common', 'invoices']);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Item to={routes.invoices}>{t('common:breadcrumbs.invoices')}</Breadcrumbs.Item>
        <Breadcrumbs.Item>{t('common:breadcrumbs.invoiceDetails')}</Breadcrumbs.Item>
      </Breadcrumbs>

      <PageTitle subtitle={invoice.entryNumber} title={invoice.title}>
        <PageTitle.Items breakpoint="lg">
          <CoDocumentStatus status={status}>
            {invoice.paymentFollowUp !== null
              ? t('invoices:overview.columns.status.options.PaymentInFollowUp')
              : t(`invoices:overview.columns.status.options.${status}`)}
          </CoDocumentStatus>
        </PageTitle.Items>

        <PageTitle.Items>
          {[InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status) ? (
            <Button hasSpinner icon="Edit" isLoading={acquireLockMutation.isPending} onClick={onEdit}>
              {t('invoices:overview.rowActions.edit.label')}
            </Button>
          ) : canStartPaymentFollowUp ? (
            <Button icon="Gavel" onClick={startFollowUpModal.open}>
              {t('invoices:details.followUp.start')}
            </Button>
          ) : (
            <Button hasSpinner icon="Visibility" isLoading={previewMutation.isPending} onClick={onPreview}>
              {t('invoices:overview.rowActions.preview')}
            </Button>
          )}

          <DropdownMenu
            dropdownClass="text-sm"
            isLoading={
              ([InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status) && previewMutation.isPending) ||
              duplicateMutation.isPending ||
              downloadMutation.isPending
            }
            items={[
              ...insertIf([InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
                iconName: 'Visibility',
                text: t('invoices:overview.rowActions.preview'),
                onClick: onPreview,
              }),
              {
                iconName: 'ContentCopy',
                text: t('invoices:overview.rowActions.duplicate'),
                onClick: onDuplicate,
              },
              ...insertIf(![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
                iconName: 'Download',
                text: t('invoices:overview.rowActions.download'),
                onClick: onDownload,
              }),
              ...insertIf([InvoiceStatus.SENT, InvoiceStatus.EXPIRED, InvoiceStatus.CO_PARTIALLY_PAID].includes(status), {
                iconName: 'Receipt',
                text: t('invoices:overview.rowActions.credit'),
                onClick: onCredit,
              }),
              ...insertIf(![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
                iconName: 'Shelter',
                text: t('invoices:overview.rowActions.advantage'),
                onClick: advantageModal.open,
              }),
            ]}
          />
        </PageTitle.Items>
      </PageTitle>

      <div className="mb-12 grid lg:grid-cols-[2fr_1fr] gap-x-12 gap-y-8 items-start">
        {/* Status */}
        <InvoiceStatusInfo invoice={invoice} />

        {/* Client */}
        <ClientInfoCard clientId={invoice.clientId} contactId={invoice.contactId} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12">
        {/* Payment follow-up */}
        {invoice.paymentFollowUp && (
          <CollapsibleSection title={t('invoices:details.paymentInfoDetail.title')}>
            <PaymentFollowUpDetails paymentFollowUp={invoice.paymentFollowUp} />
          </CollapsibleSection>
        )}

        {/* Payments */}
        <CollapsibleSection title={t('invoices:details.paymentInfo.title')}>
          {![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status) ? (
            <>
              {invoice.csNumber && <CoPaymentInfoCard invoice={invoice} />}
              <FcPaymentInfoCard invoice={invoice} />
            </>
          ) : (
            <p className="italic text-medium-gray">{t('invoices:details.paymentInfo.notAvailebleYet')}</p>
          )}
        </CollapsibleSection>

        {/* History */}
        <CollapsibleSection title={t('invoices:details.history.title')}>
          <InvoiceHistory id={invoice.id} />
        </CollapsibleSection>
      </div>

      {/* Confirm edit modal */}
      {confirmEditModal.isOpen && (
        <InvoiceConfirmEditModal isLocked={status === InvoiceStatus.LOCKED_BY_USER} onClose={confirmEditModal.closeWithResult} />
      )}

      {/* Already approved modal */}
      {alreadyApprovedModal.isOpen && (
        <InvoiceAlreadyApprovedModal invoice={alreadyApprovedModal.data} onClose={alreadyApprovedModal.close} />
      )}

      {/* Preview modal */}
      {previewMutation.data && (
        <Modal onClose={previewMutation.reset} variant="preview">
          <PreviewIFrame srcDoc={previewMutation.data} />
        </Modal>
      )}

      {/* Advantage modal */}
      {advantageModal.isOpen && <AdvantageSimulationModal calculationData={invoice.calculationData} onClose={advantageModal.close} />}

      {/* Start payment follow-up modal */}
      {startFollowUpModal.isOpen && <StartFollowUpModal invoiceId={invoice.id} onClose={startFollowUpModal.close} />}

      {/* FAB */}
      <FloatingActionButton
        options={[
          ...insertIf([InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
            icon: 'Edit',
            text: t('invoices:overview.rowActions.edit.label'),
            onClick: onEdit,
          }),
          {
            icon: 'Visibility',
            text: t('invoices:overview.rowActions.preview'),
            onClick: onPreview,
          },
          {
            icon: 'ContentCopy',
            text: t('invoices:overview.rowActions.duplicate'),
            onClick: onDuplicate,
          },
          ...insertIf(![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
            icon: 'Download',
            text: t('invoices:overview.rowActions.download'),
            onClick: onDownload,
          }),
          ...insertIf([InvoiceStatus.SENT, InvoiceStatus.EXPIRED, InvoiceStatus.CO_PARTIALLY_PAID].includes(status), {
            icon: 'Receipt',
            text: t('invoices:overview.rowActions.credit'),
            onClick: onCredit,
          }),
          ...insertIf(![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
            icon: 'Shelter',
            text: t('invoices:overview.rowActions.advantage'),
            onClick: advantageModal.open,
          }),
        ]}
      />
    </>
  );
};
