import type { UserCommunityProfile } from '~/api/community/types';
import type { AvailablePlan, EarlyAdopterPlan } from '~/api/plans/types';
import type { ColorTheme } from '~/api/user/types';

import type { Language } from './app';

// TYPES

export type User = {
  accountant: Accountant | null;
  activities: {
    extraInformation: string | null;
    id: Activity;
    isPrimaryActivity: boolean;
  }[];
  addendumSigned: boolean;
  address: Address;
  appSettings: AppSettings;
  brands: Brand[];
  communityProfile: UserCommunityProfile;
  companyName: string;
  contractSigned: boolean;
  copyright: {
    averageReceivedPastYears: number;
    totalReceivedThisYear: number;
  };
  documents: Doc[];
  eligible: boolean;
  email: string;
  emailSettings: EmailSettings;
  financial: {
    bank: string;
    bic: string;
    iban: string;
  };
  firstName: string;
  hasApprovedInvoice: boolean;
  id: number;
  integrations: {
    billit: boolean;
    cashaca: boolean;
  };
  invitations: Invitation[];
  inviteeDiscount: InviteeDiscount | null;
  invitorDiscounts: {
    id: number;
    invitationId: number | null;
    inviteeName: string;
    isActive: boolean;
    percentage: number;
    validFrom: string; // ISO 8601 format
    validUntil: string; // ISO 8601 format
  }[];
  invoiceForwardingSettings: InvoiceForwardingSetting[];
  kboInformationLastUpdatedAt: string; // ISO 8601 format
  lang: Language;
  lastApprovedInvoiceDocDate: string | null;
  lastName: string;
  logo?: string;
  mainOccupation: MainOccupation;
  numberingSeries: NumberingSeries[];
  phone: string;
  referralCode: string;
  registeredAt: string; // ISO 8601 format
  showNps: boolean;
  showOptionalSteps: boolean;
  showRequiredSteps: boolean;
  startDateForRevenue: string; // ISO 8601 format
  totalInvoicedAmount: number;
  vatExempt: boolean | null;
  vatNumber: string;
  yearlyCopyrightLimitExceeded: boolean;
} & UserPlanInfo;

type UserPlanInfo =
  | {
      commissionPercentages: {
        adminOverride: boolean;
        percentagesCreative: Record<1 | 2 | 3, number>;
        percentagesNotCreative: Record<1 | 2 | 3, number>;
        revenueAmounts: Record<1 | 2, number>;
      };
      planId: AvailablePlan['id'];
    }
  | {
      commissionPercentages: {
        adminOverride: boolean;
        percentagesCreative: Record<1, number>;
        percentagesNotCreative: Record<1, number>;
        revenueAmounts: Record<1, number>;
      };
      planId: EarlyAdopterPlan['id'];
    };

type Accountant = {
  approved: boolean;
  data: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

type Address = {
  city: string;
  number: string;
  street: string;
  zipCode: string;
};

export type InviteeDiscount = {
  daysActive: number;
  daysValid: number;
  referralCode: string;
  type: 'PremiumForClassic' | 'PremiumForFree' | null;
};

export type AppSettings = {
  addressLastVerifiedAt: string;
  hasSeenInvitationsPage: boolean;
  show2024Announcement: boolean;
  showActivityModal: boolean;
  theme: {
    appColor?: ColorTheme;
    invoiceColor?: ColorTheme;
  } | null;
};

export type Brand = {
  color: string;
  companyName: string;
  email: string;
  id: number;
  isFavorite: boolean;
  logo: string | null;
};

export type Doc = {
  extension: string;
  filename: string;
  id: number;
  title: string;
  url: string;
};

export type EmailSettings = {
  bcc: boolean;
  creationReminder: CreationReminder;
  followUpEnabled: boolean;
  invitationReminder: boolean;
  nettoSaved: boolean;
};

export type Invitation = {
  email: string;
  id: number;
  name: string;
  status: 'pending' | 'accepted' | 'declined';
  statusDate: string; // ISO 8601 format
};

export type InvoiceForwardingSetting = {
  frequency: InvoiceForwardingFrequency;
  id: number;
  platformEmail: string;
  receiverEmail: string;
};

export type InvoiceForwardingFrequency = 'instant' | 'monthly' | 'quarterly';

export type NumberingSeries = {
  enabledForCreditNotes: boolean;
  enabledForInvoices: boolean;
  format: {
    amountOfDigits: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null;
    prefixSeparator: NumberingSeparator | null;
    prefixText: string | null;
    prefixYearMonth: NumberingYearMonthFormat | null;
    suffixSeparator: NumberingSeparator | null;
    suffixText: string | null;
    suffixYearMonth: NumberingYearMonthFormat | null;
  };
  id: number;
  name: string;
  nextNumber: number;
  resetInterval: NumberingResetInterval;
  userId: number;
};

export type NumberingSeparator = '_' | '-' | '.' | '/';

// ENUMS

export enum CreationReminder {
  NO_REMINDERS = 0,
  LAST_DAY = 1,
  WEEK_BEFORE = 2,
  TWO_WEEKS_BEFORE = 3,
}

export enum MainOccupation {
  NO = 0,
  YES = 1,
}

export enum Activity {
  VISUAL_COMMUNICATION = 1,
  DIGITAL_DESIGN = 2,
  PHOTOGRAPHY = 3,
  AUDIOVISUAL = 4,
  LANGUAGE_AND_COMMUNICATION = 5,
  IT_DEVELOPMENT = 6,
  EDUCATION = 7,
  PERFORMING_ARTISTS = 8,
  STYLING_AND_INTERIOR = 9,
  ONLINE_CONTENT_CREATION = 10,
  OTHER = 11,
}

export enum NumberingYearMonthFormat {
  FullYear = 'YYYY',
  ShortYear = 'YY',
  FullYearMonth = 'YYYYMM',
  ShortYearMonth = 'YYMM',
}

export enum NumberingResetInterval {
  Never = 'never',
  Yearly = 'yearly',
  Monthly = 'monthly',
}
