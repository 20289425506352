import dayjs from 'dayjs';
import { useState } from 'react';

import { useUpdateAppSettings } from '~/api/user';

import { RequestAddressChangeModal } from './RequestAddressChangeModal';
import { VerifyAddressModal } from './VerifyAddressModal';

export const VerifyAddress = () => {
  const mutation = useUpdateAppSettings();

  const [isAddressCorrect, setIsAddressCorrect] = useState(true);

  const onConfirm = () => mutation.mutate({ appSettings: { addressLastVerifiedAt: dayjs().toISOString() } });
  const onReject = () => setIsAddressCorrect(false);
  const onGoBack = () => setIsAddressCorrect(true);

  return isAddressCorrect ? (
    <VerifyAddressModal onConfirm={onConfirm} onReject={onReject} />
  ) : (
    <RequestAddressChangeModal onConfirm={onConfirm} onGoBack={onGoBack} />
  );
};
