import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

import { AnchorTargets } from '~/constants/url';
import { includes } from '~/utils/arrays';

export const useScrollReset = () => {
  const { hash, pathname } = useLocation();

  useLayoutEffect(() => {
    const id = includes(Object.values(AnchorTargets), hash.slice(1)) ? hash : null;
    const anchor = id ? document.querySelector(id) : null;

    if (anchor) {
      anchor.scrollIntoView();
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [hash, pathname]);
};
