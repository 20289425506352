import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useCreateInvoice, useInvoices } from '~/api/invoices';
import { useCreateQuotation, useQuotations } from '~/api/quotations';
import { useUpdateAppSettings, useUser } from '~/api/user';
import { Announcement, DropdownButton, PageHeader } from '~/components';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { useScroll } from '~/hooks/useScroll';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { getGreetingMessage } from '~/utils/dates';
import { isItRelated } from '~/utils/user';

import { AccountCompletion } from './AccountCompletion/AccountCompletion';
import './Dashboard.scss';
import { Charts } from './Charts/Charts';
import { StatsSummary } from './StatsSummary/StatsSummary';

export const Dashboard = () => {
  const { data: user } = useUser();
  const { data: invoices } = useInvoices();
  const { data: quotations } = useQuotations();
  const createInvoiceMutation = useCreateInvoice();
  const createQuotationMutation = useCreateQuotation();
  const updateAppSettingsMutation = useUpdateAppSettings();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'dashboard']);
  const screen = useScreenWidth();
  const { isTop } = useScroll();

  const hasInvoicesThatCanBeCredited = invoices.some((invoice) => invoice.allowed && !invoice.paid && !invoice.credited);

  const createDraftInvoice = () => {
    createInvoiceMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  const createDraftQuotation = () => {
    createQuotationMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  const closeAnnouncement = () => updateAppSettingsMutation.mutate({ appSettings: { show2024Announcement: false } });

  return (
    <>
      <PageHeader
        description={t('dashboard:header.description')}
        title={!screen.isMobile && !isTop ? t('common:sidebar.dashboard') : `${getGreetingMessage(new Date())} ${user.firstName}`}
      >
        <DropdownButton
          data-pf-id="dashboard-header-create-invoice"
          hasSpinner
          icon="RequestQuote"
          isLoading={createInvoiceMutation.isPending || createQuotationMutation.isPending}
          items={[
            {
              text: t('dashboard:header.actionBlock.dropdown.quotation'),
              onClick: createDraftQuotation,
              disabled: createQuotationMutation.isPending,
              'data-pf-id': 'dashboard-header-create-quotation',
            },
            ...insertIf(hasInvoicesThatCanBeCredited, {
              text: t('dashboard:header.actionBlock.dropdown.creditnote'),
              onClick: () => navigate(routes.createCreditnote),
              'data-pf-id': 'dashboard-header-create-credit-note',
            }),
            {
              text: t('dashboard:header.actionBlock.dropdown.client'),
              onClick: () => navigate(routes.createClient),
              'data-pf-id': 'dashboard-header-create-client',
            },
          ]}
          onClick={createDraftInvoice}
          text={t('dashboard:header.actionBlock.button')}
        />
      </PageHeader>

      {user.appSettings.show2024Announcement && (
        <div className="-mt-4 mb-6">
          <Announcement
            onClose={closeAnnouncement}
            title={
              <Trans
                components={{ span: <span /> }}
                i18nKey={`dashboard:royaltiesReformAnnouncement.${isItRelated(user) ? 'titleIt' : 'titleNonIt'}`}
              />
            }
          >
            <Trans
              components={[<Link key={null} to={routes.settingsPaymentPlan} />]}
              i18nKey={`dashboard:royaltiesReformAnnouncement.${isItRelated(user) ? 'messageIt' : 'messageNonIt'}`}
            />
          </Announcement>
        </div>
      )}

      {/* Account completion */}
      <AccountCompletion />

      {/* Stats summary */}
      <StatsSummary invoices={invoices} quotations={quotations} />

      {/* Charts */}
      <Charts invoices={invoices} user={user} />
    </>
  );
};
