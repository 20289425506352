import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useClients } from '~/api/clients';
import { Button } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useIntl } from '~/hooks/useIntl';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { exportToExcel } from '~/utils/excel';

import { useClientsTableColumns, useClientsTableFilterOptions } from './ClientsTable.hooks';

export const ClientsTable = () => {
  const { data: clients } = useClients();

  const navigate = useNavigate();
  const { t } = useTranslation(['clients', 'common']);
  const { displayNameOfCountry } = useIntl();

  const handleExportToExcel = () => {
    const heading = [
      t('clients:fields.clientType.label'),
      t('clients:fields.companyName.label'),
      t('clients:contact'),
      t('clients:fields.function.label'),
      t('clients:fields.emailAddresses.label'),
      t('clients:fields.telephone.label'),
      t('clients:fields.address.label'),
      t('clients:fields.countryCode.label'),
      t('clients:fields.vatOrOtherNumber.label'),
    ];

    const data = clients
      .filter(({ deleted }) => !deleted)
      .flatMap((client) =>
        client.contacts
          .filter(({ deleted }) => !deleted)
          .map(
            (contact) =>
              ({
                type: t(`clients:type.${client.clientType}`),
                companyName: client.clientType === 'organisation' ? client.name : '',
                contact: contact.fullName,
                function: contact.function ?? '',
                emails: contact.emails
                  .filter(({ type }) => type === 'client')
                  .map(({ email }) => email)
                  .join(', '),
                phone: contact.phoneNumber ?? '',
                address: `${contact.address.street} ${contact.address.number}, ${contact.address.zipCode} ${contact.address.city}`,
                country: displayNameOfCountry(contact.address.countryCode),
                vatOrOtherNumber: client.vatNumber ?? client.otherNumber ?? '',
              }) satisfies Record<string, string>,
          ),
      );

    exportToExcel(data, heading, 'clients-export.xlsx');
  };

  const data = useMemo(() => clients.filter(({ deleted }) => !deleted), [clients]);
  const columns = useClientsTableColumns();

  const [table, Table] = useTable({
    data,
    columns,
    initialState: {
      sorting: [
        {
          id: ColumnIds.clientsName,
          desc: false,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  const filterOptions = useClientsTableFilterOptions(data);

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
        <Table.FiltersToggle />
        <Button onClick={handleExportToExcel}>{t('common:export')}</Button>
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.clientsName]: filterOptions.names,
          [ColumnIds.clientsType]: filterOptions.clientTypes,
        }}
      >
        <Table.Filter columnId={ColumnIds.clientsName} type="select" />
        <Table.Filter columnId={ColumnIds.clientsType} type="select" />
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Body>
          {(row) => (
            <Table.Row
              onClick={(client) =>
                navigate(
                  client.clientType === 'organisation'
                    ? r(routes.showClient, { clientId: client.id })
                    : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                )
              }
              row={row}
            />
          )}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
