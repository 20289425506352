import type { ReactNode } from 'react';
import type { To } from 'react-router';

export type AccountCompletionCardProps = {
  completedDescription: string;
  hideButton?: JSX.Element;
  onClose: () => void;
  steps: AccountCompletionCardSteps[];
  style: AccountCompletionCardStyle;
  title: string;
};

export type AccountCompletionCardSteps = {
  action?: () => void;
  body: string;
  checked: boolean;
  link?: To;
  tooltip?: ReactNode;
};

export enum AccountCompletionCardStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
