import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';

import { useLogin, useLogout } from '~/api/auth';
import { Language } from '~/types/app';
import { getAccessToken } from '~/utils/auth';

import { routes } from '../RouterProvider/router.routes';
import { CommandMenuItem } from './CommandMenuItem';

export const AppCommandMenuItems = () => {
  const loginMutation = useLogin();
  const logoutMutation = useLogout();
  const emailAddress = (import.meta.env.VITE_LOCAL_DEV_EMAIL as string) || null;
  const password = (import.meta.env.VITE_LOCAL_DEV_PASSWORD as string) || null;

  const { i18n } = useTranslation();
  const [originalLanguage, setOriginalLanguage] = useState(i18n.language);

  const isCiMode = i18n.language === 'cimode';
  const languages = [Language.DUTCH, Language.FRENCH, Language.ENGLISH];
  const nextLanguage = languages[(languages.indexOf(i18n.language as Language) + 1) % languages.length];

  const navigate = useNavigate();
  const isDesignSystemRoute = !!useMatch(routes.designSystem);

  return (
    <>
      <CommandMenuItem
        group="General"
        label={isCiMode ? 'Hide translation keys' : 'Show translation keys'}
        onClick={() => {
          if (!isCiMode) setOriginalLanguage(i18n.language);
          i18n.changeLanguage(isCiMode ? originalLanguage : 'cimode');
        }}
        shortcut="K"
      />

      <CommandMenuItem
        group="General"
        label={`Switch language (${nextLanguage})`}
        onClick={() => i18n.changeLanguage(nextLanguage)}
        shortcut="L"
      />

      <CommandMenuItem
        group="General"
        label={isDesignSystemRoute ? 'Go to Craft' : 'Go to design system'}
        onClick={() => navigate(isDesignSystemRoute ? routes.dashboard : routes.designSystem)}
        shortcut={isDesignSystemRoute ? 'C' : 'D'}
      />

      {getAccessToken() === '' && emailAddress && password && (
        <CommandMenuItem
          group="General"
          label="Log in"
          onClick={() =>
            loginMutation.mutate({ emailAddress, password }, { onSuccess: () => navigate(routes.dashboard, { replace: true }) })
          }
          shortcut="X"
        />
      )}

      {getAccessToken() !== '' && (
        <CommandMenuItem
          group="General"
          label="Log out"
          onClick={() => logoutMutation.mutate(undefined, { onSuccess: () => navigate(routes.login) })}
          shortcut="X"
        />
      )}

      {isCiMode && (
        <style>
          {`
            * {
              word-break: break-all !important;
              text-transform: none !important;
            }
          `}
        </style>
      )}
    </>
  );
};
