import type { Address, Client, Contact, OrganisationClient, OrganisationWithVatNumberClient } from '~/api/clients/types';
import type { CountryCode, VatCountryCode } from '~/api/common/types';

import { insertIf } from '~/utils/arrays';

import type { ContactFormType } from './types';

export const isClientEditable = (client: Client): client is OrganisationClient =>
  client.clientType === 'organisation' && !client.vatNumber?.startsWith('BE');

export const isClientRefreshable = (client: Client): client is OrganisationWithVatNumberClient =>
  client.clientType === 'organisation' && !!client.vatNumber?.startsWith('BE');

export const mapContactFormDataToPayload = (data: ContactFormType, useOrganisationAddress: boolean, addresses: Address[]) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  function: data.function ?? null,
  language: data.language,
  phoneNumber: data.phoneNumber ?? '',
  organisationName: data.organisationName ?? '',
  address: useOrganisationAddress ? addresses.map(({ type, ...address }) => address)[data.addressIndex] : data.address,
  emails: data.emails.map((email) => ({
    type: email.type,
    email: email.emailAddress,
    messages: [
      ...insertIf(email.enableForQuotations, 'quotations'),
      ...insertIf(email.enableForInvoicesAndCreditNotes, 'invoices & credit notes'),
      ...insertIf(email.enableForPaymentReminders, 'reminders'),
    ],
  })),
  customAgreement: data.customAgreement,
  comments: data.comments ?? null,
});

export const mapContactResponseToFormData = (contact: Contact, client: Client): ContactFormType => {
  const clientAddressIndex = client.addresses.findIndex(
    (address) =>
      address.type === contact.address.type &&
      address.street === contact.address.street &&
      address.number === contact.address.number &&
      address.zipCode === contact.address.zipCode &&
      address.city === contact.address.city &&
      address.countryCode === contact.address.countryCode,
  );

  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    function: contact.function ?? '',
    language: contact.language,
    phoneNumber: contact.phoneNumber ?? '',
    organisationName: client.isInformalAssociation ? client.name : '',
    useOrganisationAddress: contact.address.type !== 'contact',
    addressIndex: contact.address.type === 'contact' || clientAddressIndex === -1 ? 0 : clientAddressIndex,
    address:
      contact.address.type === 'contact'
        ? {
            street: contact.address.street,
            number: contact.address.number,
            zipCode: contact.address.zipCode,
            city: contact.address.city,
            countryCode: contact.address.countryCode,
          }
        : {
            street: '',
            number: '',
            zipCode: '',
            city: '',
            countryCode: client.addresses.find(({ type }) => type === 'headquarters')?.countryCode ?? 'BE',
          },
    emails: contact.emails.map((emailConfig) => ({
      type: emailConfig.type,
      emailAddress: emailConfig.email,
      enableForQuotations: emailConfig.messages.includes('quotations'),
      enableForInvoicesAndCreditNotes: emailConfig.messages.includes('invoices & credit notes'),
      enableForPaymentReminders: emailConfig.messages.includes('reminders'),
    })),
    customAgreement: contact.hasCustomAgreement,
    comments: contact.comments ?? '',
  };
};

export const mapCountryCodeToVatCountryCode = (countryCode: CountryCode): VatCountryCode => (countryCode === 'GR' ? 'EL' : countryCode);
export const mapVatCountryCodeToCountryCode = (countryCode: VatCountryCode): CountryCode => (countryCode === 'EL' ? 'GR' : countryCode);
