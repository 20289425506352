import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { SubmittedQuotation } from '~/api/quotations/types';

import { useQuotations } from '~/api/quotations';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { getQuotationStatus, QuotationStatus } from '../utils';
import { useQuotationsTableColumns, useQuotationsTableFilters } from './QuotationsTable.hooks';

export const QuotationsTable = () => {
  const { data: quotations } = useQuotations();

  const navigate = useNavigate();

  const data = useMemo(() => quotations.filter((quotation): quotation is SubmittedQuotation => quotation.allowed), [quotations]);
  const columns = useQuotationsTableColumns();

  const [table, Table] = useTable({
    data,
    columns,
    initialState: {
      columnVisibility: {
        [ColumnIds.quotationsBrand]: false,
        [ColumnIds.quotationsContact]: false,
        [ColumnIds.quotationsDocYear]: false,
        [ColumnIds.quotationsDocQuarter]: false,
      },
      sorting: [
        {
          id: ColumnIds.quotationsDocDate,
          desc: true,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  const filterOptions = useQuotationsTableFilters(data);

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
        <Table.FiltersToggle />
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.quotationsClient]: filterOptions.clients,
          [ColumnIds.quotationsBrand]: filterOptions.brands,
          [ColumnIds.quotationsDocYear]: filterOptions.docYears,
          [ColumnIds.quotationsDocQuarter]: filterOptions.docQuarters,
          [ColumnIds.quotationsStatus]: filterOptions.statuses,
        }}
      >
        <Table.Filter columnId={ColumnIds.quotationsClient} type="combobox" />
        {filterOptions.brands.length > 1 && <Table.Filter columnId={ColumnIds.quotationsBrand} type="select" />}
        <Table.Filter columnId={ColumnIds.quotationsDocYear} type="multiselect" />
        <Table.Filter columnId={ColumnIds.quotationsDocQuarter} type="multiselect" />
        <Table.Filter columnId={ColumnIds.quotationsStatus} type="multiselect" />
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Body>
          {(row) => (
            <Table.Row
              onClick={
                ![QuotationStatus.ALLOWED, QuotationStatus.DECLINED].includes(getQuotationStatus(row.original))
                  ? () => navigate(r(routes.editQuotation, { quotationId: row.id }))
                  : undefined
              }
              row={row}
            />
          )}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
