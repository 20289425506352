import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router';

import { Button, FloatingActionButton, PageHeader } from '~/components';
import { Blocker, Redirect } from '~/components/Functional';
import { routes } from '~/providers/RouterProvider/router.routes';
import { Language } from '~/types/app';

import type { ContactFormType } from '../../types';

import { ContactAddressFormSection } from '../../shared/ContactAddressFormSection';
import { ContactCommentsFormSection } from '../../shared/ContactCommentsFormSection';
import { ContactCustomAgreementFormSection } from '../../shared/ContactCustomAgreementFormSection';
import { ContactEmailsFormSection } from '../../shared/ContactEmailsFormSection';
import { ContactPersonalInfoFormSection } from '../../shared/ContactPersonalInfoFormSection';
import { OrganisationInfoCard } from '../../shared/OrganisationInfoCard';
import { mapContactFormDataToPayload } from '../../utils';
import { useCreateClientContext } from '../context';
import { FormProgress } from '../FormProgress/FormProgress';
import { useOrganisationAddresses, useSaveContactFormData } from './hooks';

export const CreateContactStep = () => {
  const { t } = useTranslation(['common', 'clients', 'validation']);

  const isOrganisationContactRoute = !!useMatch(routes.createClientContactStep);
  const addresses = useOrganisationAddresses();
  const { contactFormData, isLoading, onCreate, organisation } = useCreateClientContext();

  const form = useForm<ContactFormType>({
    defaultValues: {
      firstName: contactFormData?.firstName ?? '',
      lastName: contactFormData?.lastName ?? '',
      function: contactFormData?.function ?? '',
      language: contactFormData?.language ?? Language.DUTCH,
      phoneNumber: contactFormData?.phoneNumber ?? '',
      useOrganisationAddress: contactFormData?.useOrganisationAddress ?? !!isOrganisationContactRoute,
      addressIndex: contactFormData?.addressIndex ?? 0,
      address: {
        street: contactFormData?.address?.street ?? '',
        number: contactFormData?.address?.number ?? '',
        zipCode: contactFormData?.address?.zipCode ?? '',
        city: contactFormData?.address?.city ?? '',
        countryCode: contactFormData?.address?.countryCode ?? addresses.find(({ type }) => type === 'headquarters')?.countryCode ?? 'BE',
      },
      emails: contactFormData?.emails ?? [
        {
          type: 'client',
          emailAddress: '',
          enableForQuotations: true,
          enableForInvoicesAndCreditNotes: true,
          enableForPaymentReminders: true,
        },
      ],
      customAgreement: false,
      comments: contactFormData?.comments ?? '',
    },
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
  } = form;

  useSaveContactFormData(getValues);

  const { search } = useLocation();

  const onSubmit = handleSubmit((data) => {
    onCreate(mapContactFormDataToPayload(data, getValues('useOrganisationAddress'), addresses));
  });

  if (isOrganisationContactRoute && !organisation) {
    return <Redirect to={routes.createClient} />;
  }

  return (
    <>
      <PageHeader
        backRoute={{ pathname: isOrganisationContactRoute ? routes.createClientOrganisationStep : routes.createClient, search }}
        title={organisation?.companyName ?? t('clients:create.title')}
      >
        <Button hasSpinner icon={isOrganisationContactRoute ? 'DomainAdd' : 'PersonAdd'} isLoading={isLoading} onClick={onSubmit}>
          {t('clients:create.submit')}
        </Button>
      </PageHeader>

      <Blocker
        isBlocked={({ nextLocation }) =>
          isOrganisationContactRoute ? nextLocation.pathname !== routes.createClientOrganisationStep : isDirty
        }
        message={t('clients:create.blocker')}
      />

      {isOrganisationContactRoute && <FormProgress />}

      {isOrganisationContactRoute && organisation && (
        <div className="mb-12">
          <OrganisationInfoCard
            organisation={{
              companyName: organisation.companyName,
              vatNumber: organisation.vatNumber ?? null,
              otherNumber: organisation.otherNumber ?? null,
              address: organisation.address,
            }}
          />
        </div>
      )}

      <form onSubmit={onSubmit}>
        <ContactPersonalInfoFormSection form={form} />
        <ContactAddressFormSection addresses={addresses} form={form} isPrivateClient={!isOrganisationContactRoute} />
        <ContactEmailsFormSection form={form} />
        {organisation && <ContactCustomAgreementFormSection form={form} />}
        <ContactCommentsFormSection form={form} />
      </form>

      <FloatingActionButton
        icon={isOrganisationContactRoute ? 'DomainAdd' : 'PersonAdd'}
        options={[{ text: t('clients:create.submit'), onClick: onSubmit }]}
      />
    </>
  );
};
