export const invitations = {
  benefits: {
    disclaimer:
      "La réduction de 25 % est valable pendant un an et commence dès que votre collègue freelance envoie une première facture, dans les 30 jours suivant la création d'un compte. Pour la personne que vous avez invitée, la réduction commence immédiatement après l'inscription.",
    invitee: {
      benefit: 'pouvez facturer 60 jours <bold>gratuitement</bold>.',
      title: 'Votre collègue...',
    },
    invitor: {
      benefit: 'recevez <bold>25 % de réduction</bold> par freelance invité.',
      title: 'Vous...',
    },
    title: 'Les avantages',
  },
  cta: {
    buttons: {
      copyLinkTooltip: 'Copié',
      email: 'E-mail',
      share: 'Partager',
    },
    share: {
      data: {
        text: 'Bonjour ! Connaissez-vous déjà Creative Shelter ? Je facture mes droits d’auteur par leur intermédiaire et je garde ainsi plus d’argent. Voulez-vous l’essayer ? Facturez gratuitement pendant 60 jours. (Il vous en reste donc encore plus !)',
        title: 'Het Craft-platform van Creative Shelter',
      },
      permissionDenied: "Vous devez donner l'autorisation de partager votre lien d'invitation.",
    },
    socials: {
      twitter: {
        hashtags: '#droitsdauteur #gagnerplus #creativeshelter',
        text: 'Bonjour ! Connaissez-vous déjà Creative Shelter ? Je facture mes droits d’auteur par leur intermédiaire et je garde ainsi plus d’argent. Voulez-vous l’essayer ? Facturez gratuitement pendant 60 jours. (Il vous en reste donc encore plus !)',
      },
    },
    title: "Invitez un collègue freelance et bénéficiez d'une réduction de 25 %.",
  },
  navigation: {
    label: 'Invitations',
  },
  overview: {
    discounts: {
      commissionSaved: '<bold>{{amount}}</bold> sauvegardé',
      endedAt: 'Terminé le {{endDate}}',
      inviteButton: "Invitez quelqu'un !",
      noDiscounts: "Vous n'avez pas encore reçu de récompenses (pour l'instant).",
      startedAt: 'Commencé le {{startDate}}',
      subtitleActive: 'Actif',
      subtitleExpired: 'Expiré',
      title: 'Récompenses',
    },
    pendingInvitations: {
      noInvitations: "Vous n'avez pas encore envoyé d'invitations.",
      noMoreInvitations: "Vous n'avez plus d'invitations en attente.",
      status: {
        accepted: 'Enregistré',
        declined: 'Refusé',
        pending: 'En attente',
      },
      title: 'Invitations',
    },
    title: 'Aperçu',
  },
  pageHeader: {
    description: 'Affichez et gérez vos invitations.',
    title: 'Invitations',
  },
  progressBar: {
    label100Percent: '100 %',
    label1Invitation: '1',
    label25Percent: '25 %',
    label2Invitations: '2',
    label3Invitations: '3',
    label4InvitationsOrMore: '4+',
    label50Percent: '50 %',
    label75Percent: '75 %',
    labelDiscount: 'Remise',
    labelInvitations: 'Invitations',
    title: "Obtenez jusqu'à 100 % de réduction gratuitement !",
  },
  sendInvitationDialog: {
    errors: {
      noDoubleInvite: 'Vous avez déjà envoyé une invitation à cette adresse e-mail.',
      noSelfInvite: 'Vous ne pouvez pas vous inviter vous-même !',
      userAlreadyExists: "Cette personne facture déjà par l'intermédiaire de Creative Shelter.",
    },
    form: {
      inviteeEmailAddressField: {
        label: 'Adresse e-mail',
      },
      inviteeFirstNameField: {
        label: 'Prénom',
      },
      inviteeLanguageField: {
        label: 'Langue',
      },
      inviteeLastNameField: {
        label: 'Nom de famille',
      },
      submitButton: 'Envoyer',
    },
    success: "L'invitation a été envoyée avec succès et est en route vers {{ name }}",
    title: "Inviter quelqu'un",
  },
  sidebar: {
    cta: 'Gagnez 25 % réduction',
  },
};
