import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export type TMenuItem = {
  group: string;
  id: string;
  label: string;
  onClick: () => void;
  shortcut?: string;
};

export const CommandMenuContext = createContext<{
  addMenuItem: (menuItem: TMenuItem) => void;
  menuItems: TMenuItem[];
  removeMenuItem: (id: TMenuItem['id']) => void;
  updateMenuItem: (menuItem: TMenuItem) => void;
} | null>(null);

export const useCommandMenuContext = () => {
  const context = useContext(CommandMenuContext);

  invariant(context, 'CommandMenuProvider is missing');

  return context;
};
