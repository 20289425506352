import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';

import { routes } from '~/providers/RouterProvider/router.routes';

import styles from './FormProgress.module.scss';

export const FormProgress = () => {
  const isContactRoute = !!useMatch(routes.createClientContactStep);
  const navigate = useNavigate();

  const { t } = useTranslation(['clients']);

  return (
    <nav className={styles.Nav}>
      <ul className={styles.List}>
        <li
          className={classNames(styles.Item, isContactRoute && styles.Interactable)}
          onClick={() => isContactRoute && navigate(routes.createClientOrganisationStep)}
        >
          {t('clients:create.type.organisation')}
        </li>
        <li className={classNames(styles.Item, !isContactRoute && styles.Disabled)}>{t('clients:create.type.contact')}</li>
      </ul>
    </nav>
  );
};
