import type { FormEvent } from 'react';
import type { Control } from 'react-hook-form';

import dayjs from 'dayjs';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { toast } from 'react-toastify';

import type { Contact } from '~/api/clients/types';
import type { Quotation, SubmittedQuotation } from '~/api/quotations/types';

import { useClients } from '~/api/clients';
import { useConditions, useCustomConditions } from '~/api/conditions';
import {
  useQuotationAttachment,
  useQuotations,
  useRemoveQuotationAttachment,
  useUpdateDraftQuotation,
  useUploadQuotationAttachment,
} from '~/api/quotations';
import { useUser } from '~/api/user';
import { SearchParamKeys } from '~/constants/url';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { useStableRef } from '~/hooks/useStableRef';
import { Language } from '~/types/app';
import { groupLinesInFormSections } from '~/utils/invoicesQuotations';
import { getObjectYear } from '~/utils/objects';
import { removeSearchParams } from '~/utils/searchParams';

import type { CreateEditQuotationFormType } from './types';

import { mapQuotationFormDataToDraftPayload } from './utils';

/**
 * Get the default values for the quotation form
 */
export const useQuotationDefaultValues = (quotation: Quotation): CreateEditQuotationFormType => {
  const { data: clients } = useClients();
  const { data: conditions } = useConditions();
  const { data: customConditions } = useCustomConditions();

  const {
    data: { brands },
  } = useUser();

  const { t } = useTranslation(['quotations']);

  // Get initial values from the URL search params
  const [searchParams, setSearchParams] = useSearchParams();
  const newClientId = searchParams.get(SearchParamKeys.CLIENT_ID);
  const newContactId = searchParams.get(SearchParamKeys.CONTACT_ID);

  // Verify existence of id's
  const brandId =
    quotation.brandId === 0
      ? 0
      : (brands.find(({ id }) => id === quotation.brandId)?.id ?? brands.find(({ isFavorite }) => isFavorite)?.id ?? 0);
  const conditionId = conditions.find(({ id }) => id === quotation.conditionId)?.id ?? null;
  const customConditionId = customConditions.find(({ id }) => id === quotation.customConditionId)?.id ?? null;

  const existingClients = clients.filter(({ deleted }) => !deleted);
  const client =
    (newContactId
      ? existingClients.find(({ contacts }) => contacts.filter(({ deleted }) => !deleted).some(({ id }) => id === +newContactId))
      : newClientId
        ? existingClients.find(({ id }) => id === +newClientId)
        : existingClients.find(({ id }) => id === quotation.clientId)) ?? null;
  const clientId = client?.id ?? null;

  const existingContacts = client?.contacts.filter(({ deleted }) => !deleted) ?? [];
  const contact =
    (newContactId
      ? existingContacts.find(({ id }) => id === +newContactId)
      : existingContacts.find(({ id }) => id === quotation.contactId)) ?? (existingContacts.length === 1 ? existingContacts[0] : null);
  const contactId = contact?.id ?? null;

  const customAgreementId = contact?.customAgreements?.find(({ id }) => id === quotation.customAgreementId)?.id ?? null;

  const hasCondition = conditionId || customConditionId || customAgreementId;

  // Clean up the URL after reading the search params
  useEffect(() => {
    if (newClientId) setSearchParams(removeSearchParams(SearchParamKeys.CLIENT_ID), { replace: true });
    if (newContactId) setSearchParams(removeSearchParams(SearchParamKeys.CONTACT_ID), { replace: true });
  }, [newClientId, newContactId, setSearchParams]);

  return {
    brandId,
    clientId,
    condition: hasCondition
      ? {
          id: customAgreementId ? 0 : (conditionId ?? customConditionId ?? 0),
          type: customAgreementId ? 'customAgreement' : customConditionId ? 'customCondition' : 'condition',
        }
      : null,
    customAgreementId,
    contactId,
    description: quotation.description ?? '',
    entryNumber: quotation.entryNumber ?? '',
    expirationDate: quotation.expirationDate ? dayjs(quotation.expirationDate).toDate() : null,
    introduction: quotation.introduction ?? '',
    mail: quotation.mail || t('quotations:fields.mail.default'),
    sections: groupLinesInFormSections(quotation.quotationLines),
    specialConditions: quotation.specialConditions ?? '',
    title: quotation.title ?? '',
  };
};

/**
 * Save the draft quotation when input changes (debounced)
 */
export function useAutoSave(id: number, control: Control<CreateEditQuotationFormType>, isEnabled = true) {
  const { isPending, mutate: updateDraftQuotation } = useUpdateDraftQuotation(id);

  const values = useWatch({ control });
  const [debouncedValues] = useDebouncedState(values);

  useEffect(() => {
    if (!isEnabled) return;

    updateDraftQuotation(mapQuotationFormDataToDraftPayload(debouncedValues as CreateEditQuotationFormType));
  }, [debouncedValues, isEnabled, updateDraftQuotation]);

  return isPending;
}

/**
 * Get the mail content based on the contact's language
 */
export const useMailInContactLanguage = (contact: Contact | null, onMailLanguageChange: (mail: string) => void) => {
  const stableOnMailLanguageChange = useStableRef(onMailLanguageChange);

  // Keep track of this in state so it can be used to compare with the newly selected contact
  // Mail content should only be changed if the previous contact has a different language
  const [language, setLanguage] = useState(contact?.language ?? Language.DUTCH);

  // Workaround to trigger an update in the RichTextEdtior state, as it is not possible to turn it into a controlled component
  const [contactLanguageChanged, setContactLanguageChanged] = useState(false);

  useEffect(() => {
    const newLanguage = contact?.language ?? Language.DUTCH;

    if (newLanguage !== language) {
      const contactT = i18next.getFixedT(newLanguage);
      const mail = contactT('quotations:fields.mail.default');

      stableOnMailLanguageChange.current(mail);
      setLanguage(newLanguage);
      setContactLanguageChanged(true);
    }
  }, [contact, language, stableOnMailLanguageChange]);

  return [contactLanguageChanged, setContactLanguageChanged] as const;
};

/**
 * Get the entry number of the last submitted quotation
 */
export const useLastQuotationEntrynumber = (id: number) => {
  const { data: quotations } = useQuotations();

  const lastQuotation = quotations
    .filter(
      (quotation): quotation is SubmittedQuotation =>
        quotation.allowed && quotation.id !== id && !!quotation.entryNumber && getObjectYear(quotation) === dayjs().year(),
    )
    .sort((a, b) => dayjs(b.docDate).unix() - dayjs(a.docDate).unix())[0];

  return lastQuotation?.entryNumber ?? null;
};

/**
 * Check if the entry number is not a duplicate
 */
export const useValidateQuotationEntryNumber = () => {
  const { data: quotations } = useQuotations();

  return useCallback(
    (entryNumber: string, ownId: number) =>
      !quotations.some((quotation) => quotation.id !== ownId && !quotation.draft && quotation.entryNumber.trim() === entryNumber.trim()),
    [quotations],
  );
};

/**
 * Handle the quotation attachment
 */
export const useAttachment = (id: number) => {
  const { data: attachment } = useQuotationAttachment(id);
  const { isPending: isUploading, mutate: uploadAttachment } = useUploadQuotationAttachment(id);
  const { isPending: isRemoving, mutate: removeAttachment } = useRemoveQuotationAttachment(id);

  const { t } = useTranslation(['quotations']);

  const upload = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const fileList = event.currentTarget.files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      const formData = new FormData();
      formData.append('file', file, file.name);

      uploadAttachment(formData, {
        onError: () => toast.error(t('quotations:createEdit.attachments.error.upload')),
      });
    },
    [t, uploadAttachment],
  );

  const remove = useCallback(() => {
    removeAttachment(undefined, {
      onError: () => toast.error(t('quotations:createEdit.attachments.error.delete')),
    });
  }, [t, removeAttachment]);

  const open = useCallback(() => {
    if (!attachment) return;

    window.open(URL.createObjectURL(attachment), '_blank');
  }, [attachment]);

  return useMemo(
    () => ({
      exists: !!attachment,
      upload,
      isUploading,
      remove,
      isRemoving,
      open,
    }),
    [attachment, upload, isUploading, remove, isRemoving, open],
  );
};
