import dayjs from 'dayjs';

import { useUser } from '~/api/user';
import { isAdminLogin } from '~/utils/auth';

import { ActivitiesModal } from '../../Templates/Activities';
import { Nps } from './Nps/Nps';
import { VerifyAddress } from './VerifyAddressModal/VerifyAddress';

export const Prompts = () => {
  const { data: user } = useUser();

  if (isAdminLogin()) return null;

  // Prompt address verification after November 1st
  const firstOfNovember = dayjs().month(10).date(1);
  const addressVerificationDate = firstOfNovember.isAfter(dayjs()) ? firstOfNovember.subtract(1, 'year') : firstOfNovember;

  if (dayjs(user.appSettings.addressLastVerifiedAt).isBefore(addressVerificationDate)) return <VerifyAddress />;
  if (user.appSettings.showActivityModal) return <ActivitiesModal />;
  if (user.showNps) return <Nps />;

  return null;
};
