import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';

import { useUpdateContact } from '~/api/clients';
import { Button, FloatingActionButton, PageHeader } from '~/components';
import { Blocker } from '~/components/Functional';
import { SearchParamKeys } from '~/constants/url';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import type { ContactFormType } from '../types';
import type { EditContactFormProps as Props } from './types';

import { ContactAddressFormSection } from '../shared/ContactAddressFormSection';
import { ContactCommentsFormSection } from '../shared/ContactCommentsFormSection';
import { ContactCustomAgreementFormSection } from '../shared/ContactCustomAgreementFormSection';
import { ContactEmailsFormSection } from '../shared/ContactEmailsFormSection';
import { ContactPersonalInfoFormSection } from '../shared/ContactPersonalInfoFormSection';
import { OrganisationActions } from '../shared/OrganisationActions';
import { OrganisationInfoCard } from '../shared/OrganisationInfoCard';
import { mapContactFormDataToPayload, mapContactResponseToFormData } from '../utils';

export const EditContactForm = ({ client, contact }: Props) => {
  const isOrganisation = client.clientType === 'organisation';

  const mutation = useUpdateContact(client.id, contact.id);

  const form = useForm<ContactFormType>({
    defaultValues: mapContactResponseToFormData(contact, client),
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
  } = form;

  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const redirectPath = searchParams.get(SearchParamKeys.REDIRECT_PATH);
  const { t } = useTranslation(['clients', 'common']);

  const onSubmit = handleSubmit((data) => {
    if (mutation.isPending) return;

    mutation.mutate(mapContactFormDataToPayload(data, getValues('useOrganisationAddress'), client.addresses), {
      onSuccess: () => {
        toast.success(
          isOrganisation ? t('clients:alerts.successContactEditedOrganisation') : t('clients:alerts.successContactEditedPrivate'),
        );

        const redirectRoute = redirectPath ?? (isOrganisation ? r(routes.showClient, { clientId: client.id }) : routes.clients);
        navigate(redirectRoute, { state: { blockable: false } });
      },
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <>
      <PageHeader
        backRoute={redirectPath ?? (isOrganisation ? r(routes.showClient, { clientId: client.id }) : routes.clients)}
        title={isOrganisation ? `${client.name} – ${contact.fullName}` : `${contact.fullName}`}
      >
        <Button hasSpinner icon="Edit" isLoading={mutation.isPending} onClick={onSubmit}>
          {t('clients:editContact.submit')}
        </Button>
      </PageHeader>

      <Blocker isBlocked={isDirty} message={t('clients:editContact.blocker')} />

      {isOrganisation && (
        <div className="mb-12 space-y-4">
          <OrganisationInfoCard
            organisation={{
              companyName: client.name,
              vatNumber: client.vatNumber,
              otherNumber: client.otherNumber,
              address: client.addresses.find((address) => address.type === 'headquarters') ?? client.addresses[0],
            }}
          />
          <OrganisationActions client={client} />
        </div>
      )}

      <form onSubmit={onSubmit}>
        <ContactPersonalInfoFormSection form={form} />
        <ContactAddressFormSection addresses={client.addresses} form={form} isPrivateClient={!isOrganisation} />
        <ContactEmailsFormSection form={form} />
        {client.clientType === 'organisation' && <ContactCustomAgreementFormSection form={form} />}
        <ContactCommentsFormSection form={form} />
      </form>

      <FloatingActionButton icon="Edit" options={[{ text: t('clients:editContact.submit'), onClick: onSubmit }]} />
    </>
  );
};
