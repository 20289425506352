import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import type { TSearchParamModals } from '~/constants/url';

import { SearchParamKeys } from '~/constants/url';
import { removeSearchParams } from '~/utils/searchParams';

/**
 * Check if the given modal should be openend based on the search params.
 */
export const useShouldOpenModal = (modal: TSearchParamModals) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openModal = searchParams.get(SearchParamKeys.OPEN_MODAL);
  const shouldOpen = openModal === modal;

  // Clean up the URL after reading the search params
  useEffect(() => {
    if (shouldOpen) setSearchParams(removeSearchParams(SearchParamKeys.OPEN_MODAL), { replace: true });
  }, [setSearchParams, shouldOpen]);

  return shouldOpen;
};
