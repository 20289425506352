import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, FloatingActionButton, PageHeader } from '~/components';
import { routes } from '~/providers/RouterProvider/router.routes';

import { ClientsTable } from './ClientsTable';

export const ClientsOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['clients', 'common']);

  return (
    <>
      <PageHeader description={t('clients:header.description')} title={t('clients:header.title')}>
        <Button icon="Groups" onClick={() => navigate(routes.createClient)}>
          {t('clients:header.actionBlock.button')}
        </Button>
      </PageHeader>

      <ClientsTable />

      <FloatingActionButton options={[{ text: t('clients:header.actionBlock.button'), onClick: () => navigate(routes.createClient) }]} />
    </>
  );
};
