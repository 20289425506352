import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18next from 'i18next';

dayjs.extend(customParseFormat);

export const getGreetingMessage = (currentTime: Date): string => {
  if (!currentTime || !dayjs(currentTime).isValid()) {
    return i18next.t('common:greetings.common');
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = dayjs(currentTime).hour();

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return i18next.t('common:greetings.noon');
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return i18next.t('common:greetings.evening');
  }
  // Between dawn and noon
  return i18next.t('common:greetings.morning');
};

export const selectBestRelativeTimeFormat = (
  date: string,
  options: { granularity: 'date' | 'datetime' } = { granularity: 'date' },
): [number, Intl.RelativeTimeFormatUnit] => {
  const now = dayjs();

  const seconds = dayjs(date).diff(now, 'seconds');
  const minutes = dayjs(date).diff(now, 'minutes');
  const hours = dayjs(date).diff(now, 'hours');
  const days = dayjs(date).diff(now, 'days');
  const weeks = dayjs(date).diff(now, 'weeks');
  const months = dayjs(date).diff(now, 'months');
  const years = dayjs(date).diff(now, 'years');

  if (Math.abs(seconds) < 5 && options.granularity === 'datetime') return [0, 'seconds'];
  if (Math.abs(seconds) < 60 && options.granularity === 'datetime') return [Math.round(seconds), 'seconds'];
  if (Math.abs(minutes) < 60 && options.granularity === 'datetime') return [Math.round(minutes), 'minutes'];
  if (Math.abs(hours) < 24 && options.granularity === 'datetime') return [Math.round(hours), 'hours'];
  if (Math.abs(days) < 7) return [Math.round(days), 'days'];
  if (Math.abs(weeks) < 4) return [Math.round(weeks), 'weeks'];
  if (Math.abs(months) < 12) return [Math.round(months), 'months'];

  return [Math.round(years), 'years'];
};
