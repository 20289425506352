import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router';

import { Icon } from '~/components/SVG';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { useScroll } from '~/hooks/useScroll';

import type { PageHeaderProps as Props } from './types';

import { headerVariants, titleVariants } from './motion';
import styles from './PageHeader.module.scss';

export const PageHeader = ({ backRoute, children, description, title }: Props) => {
  const screen = useScreenWidth();
  const { isTop } = useScroll();

  return (
    <AnimatePresence initial={false}>
      <motion.section
        animate={!screen.isMobile && isTop ? 'big' : 'small'}
        className={classNames(styles['page-header'], { [styles.scrolled]: !isTop })}
        initial={false}
        variants={headerVariants}
      >
        <motion.div className={styles.intro}>
          <div className="flex gap-2">
            {backRoute && (
              <Link className={styles.back} to={backRoute}>
                <Icon name="ArrowBack" />
              </Link>
            )}

            <motion.h1 animate={!screen.isMobile && isTop ? 'big' : 'small'} variants={titleVariants}>
              {title}
            </motion.h1>
          </div>

          {description && !screen.isMobile && isTop && (
            <motion.div animate={{ opacity: 1 }} className={styles.description} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
              {description}
            </motion.div>
          )}
        </motion.div>

        {children && <div className={classNames(styles['action-block'], { [styles.small]: !isTop })}>{children}</div>}
      </motion.section>
    </AnimatePresence>
  );
};
