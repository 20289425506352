import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput } from '~/components';
import { Blocker } from '~/components/Functional';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { EuropeanOrganisationWithOtherNumberFormType, CreateEuropeanOrganisationWithOtherNumberProps as Props } from './types';

import { FormAddressCity } from '../../shared/FormAddressCity';
import { FormAddressNumber } from '../../shared/FormAddressNumber';
import { FormAddressStreet } from '../../shared/FormAddressStreet';
import { FormAddressZipCode } from '../../shared/FormAddressZipCode';
import { FormCompanyName } from '../../shared/FormCompanyName';
import { mapVatCountryCodeToCountryCode } from '../../utils';
import { useCreateClientContext } from '../context';
import { useCreateOrganisationContext } from './context';
import { useIsBlocked } from './hooks';
import { SwitchNumberType } from './shared/SwitchNumberType';
import { VatCountryCodeSelect } from './shared/VatCountryCodeSelect';

export const CreateEuropeanOrganisationWithOtherNumber = ({ switchToVatNumber }: Props) => {
  const { organisation, setOrganisation } = useCreateClientContext();
  const { vatCountryCode } = useCreateOrganisationContext();

  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation(['clients', 'validation']);

  const { control, handleSubmit } = useForm<EuropeanOrganisationWithOtherNumberFormType>({
    defaultValues: {
      otherNumber: organisation?.otherNumber ?? '',
      companyName: organisation?.companyName ?? '',
      address: {
        street: organisation?.address.street ?? '',
        number: organisation?.address.number ?? '',
        zipCode: organisation?.address.zipCode ?? '',
        city: organisation?.address.city ?? '',
        countryCode: organisation?.address.countryCode ?? mapVatCountryCodeToCountryCode(vatCountryCode),
      },
    },
  });

  const isBlocked = useIsBlocked(control);

  const onSubmit = handleSubmit((data) => {
    setOrganisation({ clientType: 'organisation', ...data });
    navigate({ pathname: routes.createClientContactStep, search }, { state: { blockable: false } });
  });

  return (
    <>
      <Blocker isBlocked={isBlocked} message={t('clients:create.blocker')} />

      <form onSubmit={onSubmit}>
        <div className="grid gap-4 grid-cols-3">
          <div className="col-span-full lg:col-span-1">
            <VatCountryCodeSelect />
          </div>

          <div className="col-span-full lg:col-span-2">
            <FormControl control={control} name="otherNumber" rules={{ required: true }}>
              <FormLabel>{t('clients:fields.otherNumber.label')}</FormLabel>
              <FormTextInput autoFocus />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>
          </div>
        </div>

        <SwitchNumberType i18nKey="clients:create.company.switchToVatNumber" onSwitch={switchToVatNumber} />

        <div className="grid gap-4 grid-cols-3">
          <FormCompanyName className="col-span-full" control={control} />
          <FormAddressStreet className="col-span-full lg:col-span-2" control={control} />
          <FormAddressNumber className="col-span-full lg:col-span-1" control={control} />
          <FormAddressZipCode className="col-span-full lg:col-span-1" control={control} />
          <FormAddressCity className="col-span-full lg:col-span-2" control={control} />
        </div>

        <div className="mt-4 flex justify-end">
          <Button isSubmit>{t('clients:create.next')}</Button>
        </div>
      </form>
    </>
  );
};
