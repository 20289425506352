import dayjs from 'dayjs';
import { Fragment, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import { useClients } from '~/api/clients';
import { useCreditNotes } from '~/api/creditNotes';
import { useInvoices, usePreviewInvoice, useUpdateInvoice } from '~/api/invoices';
import { useUser } from '~/api/user';
import {
  Attachment,
  Button,
  CollapsibleSection,
  ErrorModal,
  FloatingActionButton,
  FormCheckbox,
  FormControl,
  FormErrorMessage,
  FormHelpText,
  FormLabel,
  FormSelect,
  FormTextArea,
  FormTextInput,
  IconTooltip,
  Label,
  Modal,
  PageHeader,
  PreviewIFrame,
  TextInput,
  UploadInput,
} from '~/components';
import { Blocker } from '~/components/Functional';
import {
  AutoSaveIndicator,
  CompleteAccountModal,
  FormClientContact,
  Lines,
  LinesTotal,
  ViewConditionModal,
} from '~/components/Templates/InvoiceQuotation';
import { env } from '~/constants/env';
import { AnchorTargets, SearchParamKeys, SearchParamModals } from '~/constants/url';
import { useDropdownOptions } from '~/hooks/InvoiceQuotationForm/useDropdownOptions';
import { useModal, useModalWithData } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { Language } from '~/types/app';
import { BillingPeriod } from '~/types/invoice';
import { getRevenueByYear } from '~/utils/invoices';
import { flattenFormSectionsToLines } from '~/utils/invoicesQuotations';
import { qs } from '~/utils/searchParams';
import { generateEntryNumber } from '~/utils/string';
import { isAccountCompleted } from '~/utils/user';

import type { InvoiceConfirmationData } from '../Modals/types';
import type { CreateEditInvoiceFormType, CreateEditInvoiceFormProps as Props } from './types';

import { InvoiceConfirmationModal } from '../Modals/InvoiceConfirmationModal';
import { VAT_EXEMPT_REVENUE_FIRST_WARNING, VatExemptLimitModal } from '../Modals/VatExemptLimitModal';
import { isDuplicateEntryNumber } from '../utils';
import { useAttachment, useAutoSave, useInvoiceDefaultValues } from './hooks';
import { InvoiceFormCommandMenuItems } from './InvoiceFormCommandMenuItems';

export const CreateEditInvoiceForm = ({ invoice, isEdit }: Props) => {
  const { data: user } = useUser();
  const { data: clients } = useClients();
  const { data: invoices } = useInvoices();
  const { data: creditNotes } = useCreditNotes();

  const previewMutation = usePreviewInvoice();
  const updateInvoiceMutation = useUpdateInvoice(invoice.id);

  // i18n
  const { t } = useTranslation(['common', 'invoices', 'settings', 'validation']);

  // React router
  const navigate = useNavigate();

  // Local state
  const confirmationModal = useModal();
  const completeAccountModal = useModal();
  const conditionModal = useModalWithData<number>();
  const vatExemptLimitModal = useModal();
  const totalCoTooLowModal = useModal();
  const [showMinimumCommissionWarning, setShowMinimumCommissionWarning] = useState(false);
  const totalRevenue = useMemo(() => getRevenueByYear(invoices, dayjs().year()), [invoices]);

  // React hook form
  const defaultValues = useInvoiceDefaultValues(invoice);
  const form = useForm<CreateEditInvoiceFormType>({ defaultValues });
  const { control, getValues, handleSubmit, setValue, watch } = form;

  const isAutoSaving = useAutoSave(invoice.id, control);

  // Numbering series
  const watchNumberingSeriesId = watch('numberingSeriesId');
  const selectedNumberingSeries = user.numberingSeries.find((series) => series.id === watchNumberingSeriesId) ?? null;

  // Client and contact
  const watchContactId = watch('contactId');
  const selectedContact = clients.flatMap(({ contacts }) => contacts).find((contact) => contact.id === watchContactId) ?? null;
  const contactHasCustomAgreement = (selectedContact?.customAgreements ?? []).length > 0;

  // Condition
  const watchCondition = watch('condition');

  // Billing period
  const [watchBillingPeriod, watchBillingPeriodEndOfMonth] = watch(['billingPeriod', 'billingPeriodEndOfMonth']);

  // Attachment
  const attachment = useAttachment(invoice.id);

  const dropdownOptions = useDropdownOptions({ customAgreements: selectedContact?.customAgreements ?? [] });

  /**
   * Handles the modal submit (creates the invoice)
   */
  const onModalSubmit = ({ approvalType, entryNumber, nextNumber }: InvoiceConfirmationData) => {
    const { condition, contactId, sections, ...data } = getValues();

    if (!isEdit && isDuplicateEntryNumber(entryNumber, invoices, creditNotes)) {
      return toast.error(t('invoices:alerts.duplicateEntryNumber'));
    }

    // Narrow types of required fields
    invariant(condition, 'Expected condition to be defined.');
    invariant(data.numberingSeriesId || isEdit, 'Expected numberingSeriesId to be defined.');

    updateInvoiceMutation.mutate(
      {
        ...data,
        ...(!isEdit && { entryNumber, nextNumber }),
        conditionId: condition.type === 'condition' ? condition.id : null,
        customAgreementId: condition.type === 'customAgreement' ? data.customAgreementId : null,
        customConditionId: condition.type === 'customCondition' ? condition.id : null,
        clientContactId: contactId,
        approvalType,
        lockedByUser: false,
        draft: false,
        final: true,
        invoiceLines: flattenFormSectionsToLines(sections),
      },
      {
        onSuccess: () => {
          navigate(r(routes.showInvoice, { invoiceId: invoice.id }), { state: { blockable: false }, replace: true });
          toast.success(t(`invoices:alerts.success${isEdit ? 'Updated' : 'Created'}`));
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  /**
   * Handles the form submit after validation by React Hook Form
   */
  const onSubmit = handleSubmit(() => {
    // Account completion
    if (!isAccountCompleted(user)) return completeAccountModal.open();

    // Minimum commission
    setShowMinimumCommissionWarning(invoice.calculationData.isMinimumCommissionApplied);

    // VAT exempt revenue limit
    if (user.vatExempt && totalRevenue >= VAT_EXEMPT_REVENUE_FIRST_WARNING) return vatExemptLimitModal.open();

    // CO below minimum commisison, meaning FC would be €0
    if (invoice.calculationData.fcExclVat === 0) return totalCoTooLowModal.open();

    confirmationModal.open();
  });

  return (
    <>
      <PageHeader
        backRoute={isEdit ? r(routes.showInvoice, { invoiceId: invoice.id }) : routes.invoices}
        title={t(`invoices:createEdit.title.${isEdit ? 'edit' : 'create'}`)}
      >
        <div className="flex flex-col flex-wrap justify-end gap-2 ml-auto sm:flex-row">
          <AutoSaveIndicator isSaving={isAutoSaving} />

          <Button
            data-pf-id="invoice-preview-button"
            extraClasses="mb-2 sm:mb-0 sm:mr-2"
            hasSpinner
            icon="Visibility"
            isLoading={previewMutation.isPending}
            onClick={() => previewMutation.mutate(invoice.id, { onError: () => toast.error(t('common:error')) })}
            type="secondary"
          >
            {t('invoices:createEdit.preview')}
          </Button>

          <Button icon="Send" onClick={onSubmit}>
            {t(`invoices:createEdit.submit.${isEdit ? 'edit' : 'create'}`)}
          </Button>
        </div>
      </PageHeader>

      <Blocker isBlocked={isEdit} leaveText={t('invoices:createEdit.blocker.leave')} message={t('invoices:createEdit.blocker.message')} />

      <FormProvider {...form}>
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          {/* Basic info */}
          <CollapsibleSection description={t('invoices:createEdit.basicInfo.description')} title={t('invoices:createEdit.basicInfo.title')}>
            <div className="grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-4">
              {/* Title */}
              <FormControl control={control} name="title" rules={{ required: true, maxLength: 255, validate: (value) => !!value.trim() }}>
                <FormLabel>{t('invoices:fields.title.label')}</FormLabel>
                <FormTextInput placeholder={t('invoices:fields.title.placeholder')} />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('invoices:fields.title.label'), max: 255 })}
                  required={t('validation:required')}
                  validate={t('validation:required')}
                />
              </FormControl>

              {/* Numbering series */}
              {isEdit ? (
                <div>
                  <Label id="numberingSeriesId">{t('invoices:fields.entryNumber.label')}</Label>
                  <TextInput disabled id="numberingSeriesId" onChange={() => null} value={invoice.entryNumber} />
                </div>
              ) : (
                <FormControl control={control} name="numberingSeriesId" rules={{ required: true }}>
                  <FormLabel
                    tooltip={
                      <Trans
                        components={{ a: <a /> }}
                        i18nKey={
                          dropdownOptions.numberingSeries.length > 0
                            ? 'invoices:fields.numberingSeriesId.labelTooltip'
                            : 'invoices:fields.numberingSeriesId.tooltipCreate'
                        }
                      />
                    }
                  >
                    {t('invoices:fields.numberingSeriesId.label')}
                  </FormLabel>
                  <FormSelect
                    Footer={
                      <Button
                        extraClasses="relative after:absolute after:inset-x-0 after:-inset-y-2" // Increase vertical hitbox to cover padding area
                        icon="Add"
                        onClick={() =>
                          navigate({
                            pathname: routes.settingsGeneral,
                            search: qs({ [SearchParamKeys.OPEN_MODAL]: SearchParamModals.ADD_NUMBERING_SERIES }),
                            hash: AnchorTargets.GENERAL_NUMBERING_SERIES,
                          })
                        }
                        type="tertiary"
                      >
                        {t('settings:general.numberingSeries.add')}
                      </Button>
                    }
                    options={dropdownOptions.numberingSeries}
                    placeholder={t('invoices:fields.numberingSeriesId.placeholder')}
                  />
                  {selectedNumberingSeries && (
                    <FormHelpText tooltip={t('invoices:fields.numberingSeriesId.tooltip')}>
                      <Trans
                        components={[<strong className="text-dark-gray" key={null} />]}
                        i18nKey="invoices:fields.numberingSeriesId.helpText"
                        values={{ entryNumber: generateEntryNumber(selectedNumberingSeries) }}
                      />
                    </FormHelpText>
                  )}
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>
              )}

              {/* Client and contact */}
              {/* z-index is used to prevent the dropdown being painted below the other inputs, as this element has its own stacking context */}
              <div className="lg:row-span-2 z-1">
                <FormClientContact page="invoice" />
              </div>

              {/* Payment term */}
              <FormControl control={control} name="billingPeriod" rules={{ required: true }}>
                <FormLabel>{t('invoices:fields.billingPeriod.label')}</FormLabel>

                <div className="flex flex-col sm:flex-row sm:items-center gap-x-4 gap-y-2">
                  <FormSelect
                    options={[BillingPeriod.FIFTEEN, BillingPeriod.THIRTY, BillingPeriod.FORTY_FIVE, BillingPeriod.SIXTY].map(
                      (billingPeriod) => ({
                        value: billingPeriod,
                        label: t(`invoices:fields.billingPeriod.options.${billingPeriod}`),
                      }),
                    )}
                  />

                  {![BillingPeriod.FORTY_FIVE, BillingPeriod.SIXTY].includes(watchBillingPeriod) && (
                    <div className="flex-shrink-0">
                      <FormControl as={Fragment} control={control} name="billingPeriodEndOfMonth">
                        <FormCheckbox
                          label={t('invoices:fields.billingPeriodEndOfMonth.label')}
                          tooltip={t('invoices:fields.billingPeriodEndOfMonth.tooltip', { days: watchBillingPeriod })}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>

                <FormHelpText>
                  <Trans
                    components={[<strong className="text-dark-gray" key={null} />]}
                    i18nKey="invoices:fields.billingPeriod.helpText"
                    values={{
                      date: dayjs()
                        .endOf(watchBillingPeriodEndOfMonth ? 'month' : 'millisecond')
                        .add(watchBillingPeriod, 'days')
                        .format('DD/MM/YYYY'),
                    }}
                  />
                </FormHelpText>

                <FormErrorMessage required={t('validation:required')} />
              </FormControl>

              {/* Brand */}
              {user.brands.length > 0 && (
                <FormControl control={control} name="brandId" rules={{ required: true }}>
                  <FormLabel>{t('invoices:fields.brandId.label')}</FormLabel>
                  <FormSelect options={dropdownOptions.brands} placeholder={t('invoices:fields.brandId.placeholder')} />
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>
              )}

              {/* Custom agreement */}
              {selectedContact && selectedContact.customAgreements.length > 1 && (
                <div>
                  <FormControl control={control} name="customAgreementId" rules={{ required: true }}>
                    <FormLabel>{t('invoices:fields.customAgreementId.label')}</FormLabel>
                    <FormSelect
                      onAfterChange={({ newValue: customAgreementId }) => {
                        const customAgreement =
                          selectedContact.customAgreements.find(({ id }) => id === customAgreementId) ??
                          selectedContact.customAgreements[0];

                        setValue(
                          'specialConditions',
                          t('invoices:fields.specialConditions.custom', {
                            date: dayjs(customAgreement.date).format('DD-MM-YYYY'),
                            lng: selectedContact.language,
                          }),
                        );
                      }}
                      options={dropdownOptions.customAgreements}
                      placeholder={t('invoices:fields.customAgreementId.placeholder')}
                    />
                    <FormErrorMessage required={t('validation:required')} />
                  </FormControl>
                </div>
              )}
            </div>
          </CollapsibleSection>

          {/* Assignment */}
          <CollapsibleSection
            description={t('invoices:createEdit.assignment.description')}
            title={t('invoices:createEdit.assignment.title')}
          >
            <div className="form-grid-2">
              {/* Description */}
              <div className="col-span-full">
                <FormControl as={Fragment} control={control} name="description" rules={{ maxLength: 2000 }}>
                  <FormLabel optional>{t('invoices:fields.description.label')}</FormLabel>
                  <FormTextArea placeholder={t('invoices:fields.description.placeholder')} />
                  <FormErrorMessage
                    maxLength={t('validation:maxLength', { attribute: t('invoices:fields.description.label'), max: 2000 })}
                  />
                </FormControl>
              </div>

              {/* Order reference */}
              <FormControl control={control} name="orderReference" rules={{ maxLength: 255 }}>
                <FormLabel optional>{t('invoices:fields.orderReference.label')}</FormLabel>
                <FormTextInput placeholder={t('invoices:fields.orderReference.placeholder')} />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('invoices:fields.orderReference.label'), max: 255 })}
                />
              </FormControl>
            </div>
          </CollapsibleSection>

          {/* Royalties */}
          <CollapsibleSection description={t('invoices:createEdit.royalties.description')} title={t('invoices:createEdit.royalties.title')}>
            <div className="form-grid-2">
              {/* Condition */}
              <div>
                <FormControl control={control} name="condition" rules={{ required: true }}>
                  <FormLabel>
                    {t('invoices:fields.condition.label')}
                    {watchCondition?.type === 'customCondition' && selectedContact && selectedContact.language !== Language.DUTCH && (
                      <span className="ml-2">
                        <IconTooltip color="text-error-500" iconName="Warning" text={t('invoices:createEdit.royalties.tooltip')} />
                      </span>
                    )}
                  </FormLabel>
                  <FormSelect
                    by={(a, b) => a?.id === b?.id && a?.type === b?.type}
                    disabled={contactHasCustomAgreement}
                    options={dropdownOptions.conditions}
                    placeholder={t('invoices:fields.condition.placeholder')}
                  />
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>

                {watchCondition?.type === 'condition' && (
                  <Button extraClasses="mt-4" icon="Article" onClick={() => conditionModal.open(watchCondition.id)} type="tertiary">
                    {t('invoices:fields.condition.link')}
                  </Button>
                )}
              </div>

              {/* Special conditions */}
              <FormControl control={control} name="specialConditions">
                <FormLabel optional>{t('invoices:fields.specialConditions.label')}</FormLabel>
                <FormTextArea
                  disabled={contactHasCustomAgreement}
                  placeholder={t('invoices:fields.specialConditions.placeholder')}
                  rows={1}
                />
              </FormControl>
            </div>
          </CollapsibleSection>

          {/* Invoice lines */}
          <CollapsibleSection description={t('invoices:createEdit.lines.description')} title={t('invoices:createEdit.lines.title')}>
            <Lines type="invoice" />
            <LinesTotal calculationData={invoice.calculationData} isLoading={isAutoSaving} />
          </CollapsibleSection>

          {/* Remark */}
          <CollapsibleSection description={t('invoices:createEdit.remark.description')} title={t('invoices:createEdit.remark.title')}>
            <FormControl control={control} name="remark">
              <FormLabel optional>{t('invoices:fields.remark.label')}</FormLabel>
              <FormTextArea placeholder={t('invoices:fields.remark.placeholder')} />
            </FormControl>
          </CollapsibleSection>

          {/* Attachment(s) */}
          <CollapsibleSection
            description={t('invoices:createEdit.attachments.description')}
            title={t('invoices:createEdit.attachments.title')}
          >
            <div className="form-grid-2">
              <div>
                {attachment.exists ? (
                  <Attachment
                    isLoading={attachment.isRemoving}
                    onDeleteClick={attachment.remove}
                    onViewClick={attachment.open}
                    text={t('invoices:createEdit.attachments.attachment')}
                  />
                ) : (
                  <UploadInput
                    fileTypes="application/pdf"
                    isLoading={attachment.isUploading}
                    maxFileSizeInMegaByte={8}
                    onChange={attachment.upload}
                    smallText={t('invoices:createEdit.attachments.smalltext')}
                  />
                )}
              </div>
            </div>
          </CollapsibleSection>
        </form>

        {(env.IS_LOCAL_DEV_ENV || env.IS_STAGING_ENV) && <InvoiceFormCommandMenuItems />}
      </FormProvider>

      {/* FAB */}
      <FloatingActionButton
        isMultiple
        options={[
          {
            text: t(`invoices:createEdit.submit.${isEdit ? 'edit' : 'create'}`),
            onClick: onSubmit,
            disabled: updateInvoiceMutation.isPending,
            icon: 'Send',
          },
          {
            text: t('invoices:createEdit.preview'),
            onClick: () => previewMutation.mutate(invoice.id, { onError: () => toast.error(t('common:error')) }),
            icon: 'Visibility',
            style: 'secondary',
          },
        ]}
      />

      {/* Confirmation modal */}
      {confirmationModal.isOpen && (
        <InvoiceConfirmationModal
          allowsAutomaticApproval={invoice.allowsAutomaticApproval}
          disabled={updateInvoiceMutation.isPending}
          isEdit={isEdit}
          numberingSeries={selectedNumberingSeries}
          onClose={confirmationModal.close}
          onConfirm={onModalSubmit}
        >
          <p>
            {invoice.allowsAutomaticApproval
              ? t('invoices:createEdit.automaticApprovalModal.description')
              : isEdit
                ? t('invoices:createEdit.confirmation.descriptionEdit')
                : t('invoices:createEdit.confirmation.description')}
          </p>

          {showMinimumCommissionWarning && (
            <small className="block mb-5 text-warning-500">{t('invoices:createEdit.confirmation.minCommission')}</small>
          )}
        </InvoiceConfirmationModal>
      )}

      {/* Complete account modal */}
      {completeAccountModal.isOpen && (
        <CompleteAccountModal
          onClose={completeAccountModal.close}
          onComplete={() => {
            completeAccountModal.close();
            onSubmit();
          }}
        />
      )}

      {/* Preview modal */}
      {previewMutation.data && (
        <Modal onClose={previewMutation.reset} variant="preview">
          <PreviewIFrame srcDoc={previewMutation.data} />
        </Modal>
      )}

      {/* View condition modal */}
      {conditionModal.isOpen && <ViewConditionModal conditionId={conditionModal.data} onClose={conditionModal.close} />}

      {/* Vat exempt revenue limit modals */}
      {vatExemptLimitModal.isOpen && (
        <VatExemptLimitModal
          onClose={(isBlocked) => {
            vatExemptLimitModal.close();
            if (!isBlocked) confirmationModal.open();
          }}
          revenue={totalRevenue}
        />
      )}

      {/* CO too low modal */}
      {totalCoTooLowModal.isOpen && (
        <ErrorModal
          buttonText={t('invoices:createEdit.coTooLowModal.dismiss')}
          onClose={totalCoTooLowModal.close}
          title={t('invoices:createEdit.coTooLowModal.title')}
        >
          <p>{t('invoices:createEdit.coTooLowModal.description')}</p>
        </ErrorModal>
      )}
    </>
  );
};
