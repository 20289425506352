import * as stylex from '@stylexjs/stylex';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { colorThemes } from '~/api/user/constants';
import { SearchParamKeys } from '~/constants/url';
import { brandColorThemes } from '~/styles/themes';
import { includes } from '~/utils/arrays';

import { useCraftLocalStorage } from './useCraftLocalStorage';

export const useActiveColorTheme = () => {
  const [storedColorTheme] = useCraftLocalStorage('colorTheme');

  const [searchParams] = useSearchParams();
  const colorThemeSearchParam = searchParams.get(SearchParamKeys.COLOR_THEME);
  const urlColorTheme = includes(colorThemes, colorThemeSearchParam) ? colorThemeSearchParam : null;

  return storedColorTheme ?? urlColorTheme ?? 'green-pink';
};

/** Apply the active color theme by appending the correct classes to the body. */
export const useAppColorTheme = () => {
  const colorTheme = useActiveColorTheme();

  useEffect(() => {
    // Remove existing color theme classes
    colorThemes
      .map((colorTheme) => (stylex.attrs(brandColorThemes[colorTheme]).class ?? '').split(' ').filter((className) => className !== ''))
      .forEach((classList) => document.body.classList.remove(...classList));

    // Add new color theme class
    const classList = (stylex.attrs(brandColorThemes[colorTheme]).class ?? '').split(' ').filter((className) => className !== '');
    document.body.classList.add(...classList);

    // Legacy theming
    colorThemes.forEach((colorTheme) => document.body.classList.remove(colorTheme));
    document.body.classList.add(colorTheme);
  }, [colorTheme]);
};
