import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import invariant from 'tiny-invariant';

import type { Params } from '~/providers/RouterProvider/router.types';

import { Button, Modal } from '~/components';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import type { InvoiceConfirmationModal as Props } from './types';

import { EditEntryNumberForm } from './EditEntryNumberForm';
import { useEntryNumberForm } from './hooks';

export const InvoiceConfirmationModal = ({
  allowsAutomaticApproval,
  children,
  disabled,
  isEdit,
  numberingSeries,
  onClose,
  onConfirm,
}: Props) => {
  const { entryNumber, form } = useEntryNumberForm(numberingSeries);
  const { t } = useTranslation(['common', 'invoices']);

  const { invoiceId } = useParams<Params<typeof routes.editInvoice>>();
  invariant(invoiceId, 'Unexpected missing invoiceId in route params');
  const navigate = useNavigate();

  const confirm = (approvalType: 'manual' | 'automatic' | null) => {
    onConfirm({ approvalType, entryNumber, nextNumber: form.getValues('nextNumber') + 1 });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title icon={allowsAutomaticApproval ? 'Celebration' : undefined}>
        {allowsAutomaticApproval
          ? t('invoices:createEdit.automaticApprovalModal.title')
          : isEdit
            ? t('invoices:createEdit.confirmation.titleEdit')
            : t('invoices:createEdit.confirmation.title')}
      </Modal.Title>

      {children}

      {!isEdit && numberingSeries && (
        <EditEntryNumberForm entryNumber={entryNumber} form={form} numberingSeries={numberingSeries} type="invoice" />
      )}

      <Modal.Actions>
        {allowsAutomaticApproval ? (
          <>
            <Button disabled={disabled} onClick={() => confirm('manual')} type="secondary">
              {t('invoices:createEdit.automaticApprovalModal.manual')}
            </Button>
            <Button disabled={disabled} icon="Send" onClick={() => confirm('automatic')}>
              {t('invoices:createEdit.automaticApprovalModal.automatic')}
            </Button>
          </>
        ) : isEdit ? (
          <>
            <Button
              disabled={disabled}
              icon="Save"
              onClick={() => navigate(r(routes.showInvoice, { invoiceId: +invoiceId }), { state: { blockable: false } })}
              type="secondary"
            >
              {t('invoices:createEdit.confirmation.submit.saveAndExit')}
            </Button>
            <Button disabled={disabled} icon="Send" onClick={() => confirm(null)}>
              {t('invoices:createEdit.confirmation.submit.edit')}
            </Button>
          </>
        ) : (
          <Button hasSpinner icon="Send" isLoading={disabled} onClick={() => confirm(null)}>
            {t('invoices:createEdit.confirmation.submit.create')}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
