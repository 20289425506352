import type { ReactNode } from 'react';

import * as Sentry from '@sentry/react';
import { MotionGlobalConfig, useReducedMotion } from 'framer-motion';
import { useEffect } from 'react';

import { useUser } from '~/api/user';
import { env } from '~/constants/env';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { useScrollReset } from '~/hooks/useScrollReset';
import { isAdminLogin } from '~/utils/auth';

import { useThrowSuspenseErrors } from './hooks';
import styles from './Layout.module.scss';
import { NavigationDesktop } from './NavigationDesktop/NavigationDesktop';
import { NavigationMobile } from './NavigationMobile/NavigationMobile';
import { ProductFruits } from './ProductFruits/ProductFruits';
import { Prompts } from './Prompts/Prompts';
import { TopBar } from './TopBar/TopBar';

export const Layout = ({ children }: { children: ReactNode }) => {
  const { data: user } = useUser();

  const [, setColorTheme] = useCraftLocalStorage('colorTheme');
  const [, setLanguage] = useCraftLocalStorage('language');

  useThrowSuspenseErrors();

  // Scroll to top on navigation (or scroll to anchor if hash is set)
  useScrollReset();

  // Set the app theme color theme
  useEffect(() => {
    setColorTheme((old) => user.appSettings.theme?.appColor ?? old);
  }, [setColorTheme, user.appSettings.theme?.appColor]);

  // Set the app language
  useEffect(() => {
    setLanguage(user.lang);
  }, [setLanguage, user.lang]);

  // Identify Sentry
  useEffect(() => {
    const name = isAdminLogin() ? `Admin – ${user.firstName} ${user.lastName}` : `${user.firstName} ${user.lastName}`;

    Sentry.setUser({
      id: user.id,
      username: name,
      email: user.email,
      ip_address: '{{auto}}',
    });
  }, [user.email, user.firstName, user.id, user.lastName]);

  // Disable animations
  const shouldReduceMotion = useReducedMotion();
  useEffect(() => {
    MotionGlobalConfig.skipAnimations = !!shouldReduceMotion;
  }, [shouldReduceMotion]);

  return (
    <div className={styles.App}>
      <NavigationDesktop />

      <div className={styles.ContentWrapper}>
        <TopBar />

        <main className={styles.Content}>{children}</main>

        <Prompts />

        {env.PRODUCT_FRUITS_WORKSPACE_CODE && <ProductFruits workspaceCode={env.PRODUCT_FRUITS_WORKSPACE_CODE} />}
      </div>

      <NavigationMobile />
    </div>
  );
};
