import * as stylex from '@stylexjs/stylex';

import { fontSize, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  lastUpdated: {
    fontStyle: 'italic',
    fontSize: fontSize.tiny,
    color: systemColors.textMuted,
  },
});
