import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import type { routes } from '~/providers/RouterProvider/router.routes';
import type { Params } from '~/providers/RouterProvider/router.types';

import { useAllowQuotationByClient, useQuotationForClient } from '~/api/quotations';
import { Button, SpinnerLogo } from '~/components';
import { CreativeShelterHorizontal } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';
import { getQuotationStatus, QuotationStatus } from '~/pages/Quotations/utils';

import styles from './ClientQuotationView.module.scss';

export const ClientQuotationView = () => {
  const { quotationAllowKey = '' } = useParams<Params<typeof routes.clientQuotationView>>();

  const { data, isError, isLoading } = useQuotationForClient(quotationAllowKey);
  const mutation = useAllowQuotationByClient(quotationAllowKey);

  const { client, quotation, user } = data ?? {};

  const { formatCurrency } = useIntl(client?.language);
  const { i18n, t } = useTranslation(['common', 'lines', 'quotations']);

  const quotationStatus = quotation ? getQuotationStatus(quotation) : null;

  useEffect(() => {
    if (client) i18n.changeLanguage(client.language);
  }, [client, i18n]);

  const handleAllow = () => {
    mutation.mutate(undefined, {
      onError: () => toast.error(t('common:error')),
    });
  };

  invariant(!quotation || quotation.allowed, 'Quotation has not been submitted yet.');

  return (
    <>
      <div className={styles.Topbar}>
        <CreativeShelterHorizontal />
      </div>

      <div className={styles.Container}>
        {isLoading && (
          <div className={styles.Center}>
            <SpinnerLogo />
            <p>{t('common:loading')}</p>
          </div>
        )}

        {isError && (
          <div className={styles.Center}>
            <p>{t('common:error')}</p>
          </div>
        )}

        {quotation && client && user && (
          <div className={styles.ClientQuotation}>
            <div className={styles.AcceptCard}>
              <h3>{t('quotations:clientAccept.card.title', { user: user.companyName })}</h3>
              {quotationStatus === QuotationStatus.ALLOWED && <p>{t('quotations:clientAccept.card.description.accepted')}</p>}
              {quotationStatus === QuotationStatus.DECLINED && <p>{t('quotations:clientAccept.card.description.declined')}</p>}
              {quotationStatus === QuotationStatus.EXPIRED && <p>{t('quotations:clientAccept.card.description.expired')}</p>}
              {quotationStatus === QuotationStatus.SENT && (
                <>
                  <p>{t('quotations:clientAccept.card.description.accept', { user: user.companyName })}</p>
                  <Button hasSpinner icon="Check" isLoading={mutation.isPending} onClick={handleAllow}>
                    {t('quotations:clientAccept.card.button')}
                  </Button>
                </>
              )}
            </div>

            <div className={styles.Preview}>
              {/* HEADER */}
              <div className={styles.Header}>
                <p>
                  <strong>{t('quotations:clientAccept.preview.createdBy')}: </strong>
                  <br />
                  <br />
                  <strong>Creative Shelter BV</strong>
                  <br />
                  Adolf Baeyensstraat 134 F, 9040 Gent
                  <br />
                  BE0536939936
                  <br />
                  BE53 7370 3980 0153
                  <br />
                  <a href="mailto:info@creativeshelter.be">info@creativeshelter.be</a>
                  <br />
                  <a href="https://www.creativeshelter.be" rel="noreferrer" target="_blank">
                    creativeshelter.be
                  </a>
                </p>

                <div>
                  <h1>{t('common:models.quotation').toUpperCase()}</h1>
                  <p>
                    <strong>{t('quotations:clientAccept.preview.expirationDate')}: </strong>{' '}
                    {dayjs(quotation.expirationDate).format('DD/MM/YYYY')}
                  </p>
                </div>
              </div>

              {/* INTRODUCTION */}
              <div>
                <h2 className={styles.Title}>{t('quotations:clientAccept.preview.title')}</h2>
                <p>{quotation.title}</p>
              </div>

              {/* FREELANCER & CLIENT */}
              <div className={styles.FreelancerClient}>
                <div>
                  <h3>{t('quotations:clientAccept.preview.freelancer')}</h3>
                  <p>
                    <strong>{user.companyName}</strong>
                    <br />
                    {user.lastName} {user.firstName}
                    <br />
                    {user.address.street} {user.address.number}, {user.address.zipCode} {user.address.city}
                    <br />
                    {user.vatNumber}
                  </p>
                </div>

                <div>
                  <h3>{t('quotations:clientAccept.preview.client')}</h3>
                  <p>
                    <strong>{client.name}</strong>
                    <br />
                    {client.address.street} {client.address.number}, {client.address.zipCode} {client.address.city}
                    <br />
                    {client.vatNumber ?? client.otherNumber}
                  </p>
                </div>
              </div>

              {/* INTRODUCTION */}
              <div>
                <h2 className={styles.Title}>{t('quotations:clientAccept.preview.introduction')}</h2>
                {parse(quotation.introduction ?? '')}
              </div>

              {/* DESCRIPTION */}
              <div>
                <h2 className={styles.Title}>{t('quotations:clientAccept.preview.description')}</h2>
                {parse(quotation.description ?? '')}
              </div>

              {/* ASSIGNMENT */}
              <div>
                <h2 className={styles.Title}>{t('quotations:clientAccept.preview.assignment.title')}</h2>
                <table className={styles.QuotationLines}>
                  <thead>
                    <tr>
                      <th>{t('lines:description.header')}</th>
                      <th>{t('lines:price.header')}</th>
                      <th>{t('lines:quantity.header')}</th>
                      <th>{t('lines:lineTotal.header')}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {quotation.quotationLines.map((line, index) =>
                      line.type === 'entry' ? (
                        <tr data-entry-line key={index}>
                          <td>
                            {line.productName}
                            {!!line.productName && !!line.description && <br />}
                            {line.description}
                          </td>
                          <td>{formatCurrency(line.price ?? 0)}</td>
                          <td>{line.quantity}</td>
                          <td>{formatCurrency(line.total ?? 0)}</td>
                        </tr>
                      ) : (
                        <tr data-text-line key={index}>
                          <td colSpan={4}>{line.description}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>

                <div className={styles.Totals}>
                  <p>
                    <strong>
                      {t('lines:subtotal')} <br />
                    </strong>
                  </p>
                  <p>
                    {formatCurrency(quotation.calculationData.coExclVat)} <br />
                  </p>
                  <p>
                    <strong>
                      {t('lines:totalVat')} <br />
                    </strong>
                  </p>
                  <p>
                    {formatCurrency(quotation.calculationData.coVat)} <br />
                  </p>
                  <p>
                    <strong>
                      {t('lines:total')} <br />
                    </strong>
                  </p>
                  <p>
                    {formatCurrency(quotation.calculationData.coInclVat)} <br />
                  </p>
                </div>
              </div>

              {/* CONDITIONS */}
              {quotation.conditionUrl && (
                <div>
                  <h2 className={styles.Title}>{t('quotations:clientAccept.preview.conditions.title')}</h2>
                  <a href={quotation.conditionUrl} rel="noreferrer" target="_blank">
                    {t('quotations:clientAccept.preview.conditions.description')}
                  </a>
                </div>
              )}

              {/* SPECIAL CONDITIONS */}
              {quotation.specialConditions && (
                <div>
                  <h2 className={styles.Title}>{t('quotations:clientAccept.preview.specialConditions')}</h2>
                  <p>{quotation.specialConditions}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
