import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { PlanId } from '~/api/plans/constants';
import { useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { routes } from '~/providers/RouterProvider/router.routes';

import styles from './CommissionInfo.module.scss';
import { CommissionProgress } from './CommissionProgress';
import { CurrentCommission } from './CurrentCommission';
import { EarlyAdopterInfo } from './EarlyAdopterInfo';

export const CommissionInfo = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['settings']);

  const hasFixedCreativePercentage =
    user.planId === PlanId.earlyAdopter ||
    (user.commissionPercentages.percentagesCreative[1] === user.commissionPercentages.percentagesCreative[2] &&
      user.commissionPercentages.percentagesCreative[2] === user.commissionPercentages.percentagesCreative[3]);

  const activePremiumForFreeDiscount = user.inviteeDiscount?.type === 'PremiumForFree';

  return (
    <div
      className={classNames(
        styles.CommissionInfo,
        hasFixedCreativePercentage && user.planId !== PlanId.earlyAdopter && styles.SingleColumn,
      )}
    >
      <CurrentCommission user={user} />

      {!hasFixedCreativePercentage && <CommissionProgress user={user} />}

      {user.planId === PlanId.earlyAdopter && <EarlyAdopterInfo />}

      <div className={styles.InvitationCTA}>
        <div className={styles.InvitationCTA__Icon}>
          <Icon name="Sell" />
        </div>

        <p className={styles.InvitationCTA__Text}>
          <span>
            {activePremiumForFreeDiscount
              ? t('settings:paymentPlan.freeInvitationCTA.text', { count: user.inviteeDiscount?.daysActive })
              : t('settings:paymentPlan.invitationCTA.text')}
          </span>
          {!activePremiumForFreeDiscount && <Link to={routes.settingsInvitations}> {t('settings:paymentPlan.invitationCTA.link')}</Link>}
        </p>
      </div>
    </div>
  );
};
