import type { ComponentProps } from 'react';
import type { LinkProps } from 'react-router';

import { Link } from 'react-router';

import type { WithoutStyleProps } from '../../types';

import { Button } from '../Button/Button';

type DelegatedButtonProps = Pick<ComponentProps<typeof Button>, 'children' | 'size' | 'variant' | 'scale' | 'icon' | 'styles'>;
type Props = WithoutStyleProps<LinkProps> & DelegatedButtonProps;

export const LinkButton = (delegated: Props) => {
  return (
    <Button
      as={Link}
      // The Button component will render a Link, but TypeScript expects button properties
      // to be passed to the Button component, not knowing that we can pass Link properties.
      {...(delegated as DelegatedButtonProps)}
    />
  );
};
