import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type { SubmittedQuotation } from '~/api/quotations/types';

import { useCreateInvoiceFromQuotation } from '~/api/invoices';
import { useDownloadQuotation, useDuplicateQuotation } from '~/api/quotations';
import { DropdownMenu } from '~/components';
import { useModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';

import { getQuotationStatus, QuotationStatus } from '../utils';
import { AllowQuotationModal } from './Modals/AllowQuotationModal';
import { DeclineQuotationModal } from './Modals/DeclineQuotationModal';

export const QuotationsTableOptions = ({ quotation }: { quotation: SubmittedQuotation }) => {
  const createInvoiceFromQuotationMutation = useCreateInvoiceFromQuotation();
  const duplicateMutation = useDuplicateQuotation();
  const downloadMutation = useDownloadQuotation();

  const allowModal = useModal();
  const declineModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  const status = getQuotationStatus(quotation);
  const isAllowed = status === QuotationStatus.ALLOWED;
  const isDeclined = status === QuotationStatus.DECLINED;
  const isAllowedOrDeclined = isAllowed || isDeclined;

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        isLoading={createInvoiceFromQuotationMutation.isPending || duplicateMutation.isPending || downloadMutation.isPending}
        items={[
          ...insertIf(!isAllowedOrDeclined, {
            iconName: 'Edit',
            text: t('quotations:overview.rowActions.edit.label'),
            onClick: () => navigate(r(routes.editQuotation, { quotationId: quotation.id })),
          }),
          ...insertIf(!isAllowedOrDeclined, {
            iconName: 'Check',
            text: t('quotations:overview.rowActions.allow'),
            onClick: allowModal.open,
          }),
          ...insertIf(!isAllowedOrDeclined, {
            iconName: 'Close',
            text: t('quotations:overview.rowActions.decline'),
            onClick: declineModal.open,
          }),
          ...insertIf(isAllowed, {
            iconName: 'RequestQuote',
            text: t('quotations:overview.rowActions.invoice'),
            onClick: () =>
              createInvoiceFromQuotationMutation.mutate(quotation.id, {
                onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
                onError: () => toast.error(t('common:error')),
              }),
          }),
          {
            iconName: 'ContentCopy',
            text: t('quotations:overview.rowActions.duplicate'),
            onClick: () =>
              duplicateMutation.mutate(quotation.id, {
                onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
                onError: () => toast.error(t('common:error')),
              }),
          },
          {
            iconName: 'Download',
            text: t('quotations:overview.rowActions.download'),
            onClick: () =>
              downloadMutation.mutate(quotation.id, {
                onError: () => toast.error(t('common:error')),
              }),
          },
        ]}
      />

      {allowModal.isOpen && <AllowQuotationModal onClose={allowModal.close} quotation={quotation} />}
      {declineModal.isOpen && <DeclineQuotationModal onClose={declineModal.close} quotation={quotation} />}
    </>
  );
};
