import * as stylex from '@stylexjs/stylex';
import { styles } from 'App.styles';
import { Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { env } from '~/constants/env';
import { useAppColorTheme } from '~/hooks/useAppColorTheme';
import { useAppLanguage } from '~/hooks/useAppLanguage';
import { useForceRefresh } from '~/hooks/useForceRefresh';
import { AppCommandMenuItems } from '~/providers/CommandMenuProvider/AppCommandMenuItems';
import '~/styles/app.scss';

export const App = () => {
  useAppColorTheme();
  useAppLanguage();
  useForceRefresh();

  return (
    <>
      <div {...stylex.props(styles.isolate)}>
        <Outlet />
      </div>

      <ToastContainer autoClose={10000} limit={3} pauseOnFocusLoss={false} position="top-center" />

      {(env.IS_LOCAL_DEV_ENV || env.IS_STAGING_ENV) && <AppCommandMenuItems />}
    </>
  );
};
