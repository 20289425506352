import type { FormEvent } from 'react';
import type { Control } from 'react-hook-form';

import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { toast } from 'react-toastify';

import type { Invoice } from '~/types/invoice';

import { useClients } from '~/api/clients';
import { useConditions, useCustomConditions } from '~/api/conditions';
import { useInvoiceAttachment, useRemoveInvoiceAttachment, useUpdateDraftInvoice, useUploadInvoiceAttachment } from '~/api/invoices';
import { useUser } from '~/api/user';
import { SearchParamKeys } from '~/constants/url';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { BillingPeriod } from '~/types/invoice';
import { flattenFormSectionsToLines, groupLinesInFormSections } from '~/utils/invoicesQuotations';
import { removeSearchParams } from '~/utils/searchParams';

import type { CreateEditInvoiceFormType } from './types';

export const useInvoiceDefaultValues = (invoice: Invoice): CreateEditInvoiceFormType => {
  const { data: clients } = useClients();
  const { data: conditions } = useConditions();
  const { data: customConditions } = useCustomConditions();

  const {
    data: { brands, numberingSeries },
  } = useUser();

  // Get initial values from the URL search params
  const [searchParams, setSearchParams] = useSearchParams();
  const newClientId = searchParams.get(SearchParamKeys.CLIENT_ID);
  const newContactId = searchParams.get(SearchParamKeys.CONTACT_ID);

  // Verify existence of id's
  const brandId =
    invoice.brandId === 0
      ? 0
      : (brands.find(({ id }) => id === invoice.brandId)?.id ?? brands.find(({ isFavorite }) => isFavorite)?.id ?? 0);
  const conditionId = conditions.find(({ id }) => id === invoice.conditionId)?.id ?? null;
  const customConditionId = customConditions.find(({ id }) => id === invoice.customConditionId)?.id ?? null;
  const numberingSeriesId =
    numberingSeries.find((series) => series.id === invoice.numberingSeriesId)?.id ||
    numberingSeries.find((series) => series.enabledForInvoices)?.id ||
    null;

  const existingClients = clients.filter(({ deleted }) => !deleted);
  const client =
    (newContactId
      ? existingClients.find(({ contacts }) => contacts.filter(({ deleted }) => !deleted).some(({ id }) => id === +newContactId))
      : newClientId
        ? existingClients.find(({ id }) => id === +newClientId)
        : existingClients.find(({ id }) => id === invoice.clientId)) ?? null;
  const clientId = client?.id ?? null;

  const existingContacts = client?.contacts.filter(({ deleted }) => !deleted) ?? [];
  const contact =
    (newContactId
      ? existingContacts.find(({ id }) => id === +newContactId)
      : existingContacts.find(({ id }) => id === invoice.contactId)) ?? (existingContacts.length === 1 ? existingContacts[0] : null);
  const contactId = contact?.id ?? null;

  const customAgreementId = contact?.customAgreements?.find(({ id }) => id === invoice.customAgreementId)?.id ?? null;

  const hasCondition = conditionId || customConditionId || customAgreementId;

  // Clean up the URL after reading the search params
  useEffect(() => {
    if (newClientId) setSearchParams(removeSearchParams(SearchParamKeys.CLIENT_ID), { replace: true });
    if (newContactId) setSearchParams(removeSearchParams(SearchParamKeys.CONTACT_ID), { replace: true });
  }, [newClientId, newContactId, setSearchParams]);

  return {
    ...invoice,
    numberingSeriesId,
    condition: hasCondition
      ? {
          id: customAgreementId ? 0 : (conditionId ?? customConditionId ?? 0),
          type: customAgreementId ? 'customAgreement' : customConditionId ? 'customCondition' : 'condition',
        }
      : null,
    billingPeriod: invoice.billingPeriod ?? BillingPeriod.THIRTY,
    billingPeriodEndOfMonth: invoice.billingPeriodEndOfMonth ?? false,
    brandId,
    clientId,
    contactId,
    sections: groupLinesInFormSections(invoice.invoiceLines),
  };
};

/**
 * Save the draft invoice when input changes (debounced)
 */
export function useAutoSave(id: number, control: Control<CreateEditInvoiceFormType>, isEnabled = true) {
  const { isPending, mutate: updateDraftInvoice } = useUpdateDraftInvoice(id);

  const values = useWatch({ control });
  const [debouncedValues] = useDebouncedState(values);

  const autoSave = useCallback(
    (values: CreateEditInvoiceFormType) => {
      const { condition, contactId, sections, ...data } = values as CreateEditInvoiceFormType;
      const invoiceLines = flattenFormSectionsToLines(sections);

      updateDraftInvoice({
        ...data,
        conditionId: condition?.type === 'condition' ? condition.id : null,
        customAgreementId: condition?.type === 'customAgreement' ? data.customAgreementId : null,
        customConditionId: condition?.type === 'customCondition' ? condition.id : null,
        clientContactId: contactId,
        invoiceLines,
        final: false,
        approvalType: null,
      });
    },
    [updateDraftInvoice],
  );

  useEffect(() => {
    if (!isEnabled) return;

    autoSave(debouncedValues as CreateEditInvoiceFormType);
  }, [debouncedValues, autoSave, isEnabled]);

  return isPending;
}

/**
 * Handle the invoice attachment
 */
export const useAttachment = (id: number) => {
  const { data: attachment } = useInvoiceAttachment(id);
  const { isPending: isUploading, mutate: uploadAttachment } = useUploadInvoiceAttachment(id);
  const { isPending: isRemoving, mutate: removeAttachment } = useRemoveInvoiceAttachment(id);

  const { t } = useTranslation(['invoices']);

  const upload = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const fileList = event.currentTarget.files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      const formData = new FormData();
      formData.append('file', file, file.name);

      uploadAttachment(formData, {
        onError: () => toast.error(t('invoices:createEdit.attachments.error.upload')),
      });
    },
    [t, uploadAttachment],
  );

  const remove = useCallback(() => {
    removeAttachment(undefined, {
      onError: () => toast.error(t('invoices:createEdit.attachments.error.delete')),
    });
  }, [t, removeAttachment]);

  const open = useCallback(() => {
    if (!attachment) return;

    window.open(URL.createObjectURL(attachment), '_blank');
  }, [attachment]);

  return useMemo(
    () => ({
      exists: !!attachment,
      upload,
      isUploading,
      remove,
      isRemoving,
      open,
    }),
    [attachment, upload, isUploading, remove, isRemoving, open],
  );
};
