import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';

import type { OrganisationClient } from '~/api/clients/types';

import { useRefreshVatInformation } from '~/api/clients';
import { Button, IconTooltip } from '~/components';
import { Flex } from '~/components/UI';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { selectBestRelativeTimeFormat } from '~/utils/dates';
import { qs } from '~/utils/searchParams';

import { isClientEditable, isClientRefreshable } from '../utils';
import { styles } from './OrganisationActions.styles';

type Props = {
  client: OrganisationClient;
};

export const OrganisationActions = ({ client }: Props) => {
  const refreshVatInformationMutation = useRefreshVatInformation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { formatRelativeTime } = useIntl();
  const { t } = useTranslation(['clients', 'common']);

  const isEditable = isClientEditable(client);
  const isRefreshable = isClientRefreshable(client);

  return (
    <Flex alignItems="center" gap="small" justifyContent="end" wrap="reverse">
      {isRefreshable && (
        <>
          <Flex alignItems="center" gap="xxsmall">
            <time dateTime={client.vatInformationLastUpdatedAt} {...stylex.props(styles.lastUpdated)}>
              {t('clients:detail.info.refreshVatInformation.lastUpdated', {
                timeAgo: formatRelativeTime(...selectBestRelativeTimeFormat(client.vatInformationLastUpdatedAt)),
              })}
            </time>

            <IconTooltip
              text={
                <Trans
                  components={{
                    kboLink: (
                      <a
                        href={`https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${client.vatNumber.slice(2)}`}
                        target="_blank"
                      />
                    ),
                  }}
                  i18nKey="clients:detail.info.refreshVatInformation.tooltip"
                />
              }
            />
          </Flex>

          <Button
            disabled={dayjs().diff(client.vatInformationLastUpdatedAt, 'days') < 7}
            hasSpinner
            icon="Sync"
            isLoading={refreshVatInformationMutation.isPending}
            onClick={() => refreshVatInformationMutation.mutate(client.id, { onError: () => toast.error(t('common:error')) })}
            type="secondary"
          >
            {t('clients:detail.info.refreshVatInformation.action')}
          </Button>
        </>
      )}

      {isEditable && (
        <Button
          icon="Edit"
          onClick={() =>
            navigate({
              pathname: r(routes.editClient, { clientId: client.id }),
              search: qs({ [SearchParamKeys.REDIRECT_PATH]: searchParams.get(SearchParamKeys.REDIRECT_PATH) ?? undefined }),
            })
          }
          type="secondary"
        >
          {t('clients:detail.info.edit.action')}
        </Button>
      )}
    </Flex>
  );
};
