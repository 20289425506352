import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, fontWeight, radius } from '~/styles/tokens.stylex';

const outlineBounce = stylex.keyframes({
  '0%': { outlineOffset: '5px' },
  '100%': { outlineOffset: '1px' },
});

export const styles = stylex.create({
  link: {
    fontSize: '1em',
    fontWeight: fontWeight.medium,
    lineHeight: '1em',
    color: {
      default: brandColors.primary500,
      ':hover': brandColors.primary400,
      ':focus-visible': brandColors.primary400,
    },
    textDecoration: 'underline',
    borderRadius: radius.xxxsmall, // Rounding of the outline
    outlineStyle: {
      default: 'none',
      ':focus-visible': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: 'currentColor',
    animationName: {
      default: null,
      ':focus-visible': outlineBounce,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
    transitionProperty: 'color',
    transitionDuration: animationSpeed.fast,
    cursor: 'pointer',
  },
});
