import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { IconName } from '~/components/SVG';
import type { MutuallyExclusive } from '~/types/utils';

import { useLogout } from '~/api/auth';
import { useClients } from '~/api/clients';
import { useConditions, useCustomConditions } from '~/api/conditions';
import { useCreditNotes } from '~/api/creditNotes';
import { shouldThrowError } from '~/api/errors';
import { useInvoices } from '~/api/invoices';
import { usePlans } from '~/api/plans';
import { useProducts } from '~/api/products';
import { useQuotations } from '~/api/quotations';
import { useUser } from '~/api/user';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { routes } from '~/providers/RouterProvider/router.routes';

type MenuItem = MutuallyExclusive<
  {
    'data-pf-id'?: string;
    iconName: IconName;
    newFeature?: boolean;
    route: string;
    text: string;
  },
  {
    iconName: IconName;
    onClick: () => void;
    text: string;
  }
>;

export const useNavigationMenuItems = () => {
  const { t } = useTranslation(['common']);
  const screen = useScreenWidth();

  return useMemo<(MenuItem & { group: 'invoicing' | 'social'; route: string })[]>(
    () => [
      // Dashboard
      {
        iconName: 'Analytics',
        text: t('common:sidebar.dashboard'),
        route: routes.dashboard,
        group: 'invoicing' as const,
      },

      // Quotations
      {
        iconName: 'Description',
        text: t('common:sidebar.quotations'),
        route: routes.quotations,
        group: 'invoicing' as const,
      },

      // Invoices and creditnotes
      {
        iconName: 'RequestQuote',
        text: t('common:sidebar.invoices'),
        route: routes.invoices,
        group: 'invoicing' as const,
      },

      // Products
      {
        iconName: 'DesignServices',
        text: t('common:sidebar.products'),
        route: routes.products,
        group: 'invoicing' as const,
      },

      // Clients
      {
        iconName: 'Groups',
        text: t('common:sidebar.clients'),
        route: routes.clients,
        group: 'invoicing' as const,
      },

      // Community
      {
        iconName: 'Contacts',
        text: screen.isMobile ? t('common:sidebar.community_mobile') : t('common:sidebar.community'),
        route: routes.community,
        group: 'social' as const,
      },
    ],
    [screen.isMobile, t],
  );
};

export const useSettingsMenuItems = () => {
  const logoutMutation = useLogout();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'invitations', 'settings']);

  const handleLogout = useCallback(() => {
    logoutMutation.mutate(undefined, {
      onSettled: () => navigate(routes.login),
    });
  }, [logoutMutation, navigate]);

  return useMemo<MenuItem[]>(
    () => [
      // Profile
      {
        iconName: 'AccountCircle',
        text: t('settings:profile.title'),
        route: routes.settingsProfile,
        'data-pf-id': 'topbar-dropdown-profile',
      },

      // General settings
      {
        iconName: 'Settings',
        text: t('settings:general.title'),
        route: routes.settingsGeneral,
        'data-pf-id': 'topbar-dropdown-settings',
      },

      // Invitations
      {
        iconName: 'GroupAdd',
        text: t('invitations:navigation.label'),
        route: routes.settingsInvitations,
        'data-pf-id': 'topbar-dropdown-invitations',
      },

      // Documents
      {
        iconName: 'Inventory2',
        text: t('settings:documents.title'),
        route: routes.settingsDocuments,
        'data-pf-id': 'topbar-dropdown-documents',
      },

      // Integrations
      {
        iconName: 'Webhook',
        text: t('settings:integrations.title'),
        route: routes.settingsIntegrations,
        'data-pf-id': 'topbar-dropdown-integrations',
      },

      // Payment plan
      {
        iconName: 'EuroSymbol' as const,
        text: t('settings:paymentPlan.header.title'),
        route: routes.settingsPaymentPlan,
        'data-pf-id': 'topbar-dropdown-subscription',
      },

      // Logout
      {
        iconName: 'Logout',
        text: t('common:topbar.dropdown.logout'),
        onClick: handleLogout,
      },
    ],
    [t, handleLogout],
  );
};

/**
 * Suspense queries don't throw errors if there is data available in the cache.
 * To throw suspense errors, manually check the query error state.
 * https://tanstack.com/query/latest/docs/framework/react/guides/suspense#throwonerror-default
 *
 * In our case, we want to throw errors if the user is authenticated, so it can be handled by <RouteErrorBoundary />.
 * For non-suspense queries and mutations, this is defined by the predicate passed to `throwOnError` of the query client.
 */
export const useThrowSuspenseErrors = () => {
  const suspenseQueryErrors = [
    useUser().error,
    useQuotations().error,
    useInvoices().error,
    useCreditNotes().error,
    useProducts().error,
    useClients().error,
    useConditions().error,
    useCustomConditions().error,
    usePlans().error,
  ];

  const throwableError = suspenseQueryErrors.find((error) => shouldThrowError(error));

  if (throwableError) throw throwableError;
};
