import * as stylex from '@stylexjs/stylex';

import { animationSpeed, radius, spacing, statusColors } from '~/styles/tokens.stylex';

import { alertVariables } from './Alert.stylex';

const outlineBounce = stylex.keyframes({
  '0%': { outlineOffset: '4px' },
  '100%': { outlineOffset: '0px' },
});

export const styles = stylex.create({
  alert: {
    padding: spacing.xsmall,
    display: 'flex',
    alignItems: 'start',
    gap: spacing.xsmall,
    borderRadius: radius.xxxsmall,
    borderWidth: 1,
    color: alertVariables.variantColor,
    borderColor: alertVariables.variantColor,
  },

  success: {
    [alertVariables.variantColor]: statusColors.successDark,
  },

  info: {
    [alertVariables.variantColor]: statusColors.infoDark,
  },

  warning: {
    [alertVariables.variantColor]: statusColors.warningDark,
  },

  error: {
    [alertVariables.variantColor]: statusColors.errorDark,
  },

  iconWrapper: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxsmall,
  },

  closeButton: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: radius.xxxsmall,
    outlineStyle: {
      default: 'none',
      ':is([data-focus])': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: alertVariables.variantColor,
    animationName: {
      default: null,
      ':focus-visible': outlineBounce,
    },
    animationTimingFunction: 'linear',
    animationDuration: animationSpeed.fast,
  },
});
