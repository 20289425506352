import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { Product } from '~/api/products/types';

import { DropdownMenu } from '~/components';
import { useModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { DeleteProductModal } from './DeleteProductModal';

export const ProductsTableOptions = ({ product }: { product: Product }) => {
  const deleteModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        items={[
          {
            iconName: 'Edit',
            text: t('common:datatable.edit'),
            onClick: () => navigate(r(routes.editProduct, { productId: product.id })),
          },
          {
            iconName: 'Delete',
            text: t('common:datatable.delete'),
            onClick: deleteModal.open,
          },
        ]}
      />

      {deleteModal.isOpen && <DeleteProductModal onClose={deleteModal.close} product={product} />}
    </>
  );
};
