import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { clientSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

export const useRefreshVatInformation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (clientId: number) => {
      const token = getAccessToken();
      const response = await request(`/clients/${clientId}/refresh-vat-information`, { method: 'POST', token });

      const { data: client } = apiResourceSchema(clientSchema).parse(response);

      return client;
    },
    onSuccess: (updatedClient) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) =>
        (clients ?? []).map((client) => (client.id === updatedClient.id ? updatedClient : client)),
      );

      return queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
