import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';

import { useAuthorizeBillit, useUser } from '~/api/user';
import { SpinnerLogo } from '~/components';
import { SearchParamKeys } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';

import styles from './OauthBillit.module.scss';

export const OauthBillit = () => {
  const { data: user } = useUser();
  const { mutate: authorizeBillit } = useAuthorizeBillit();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get(SearchParamKeys.BILLIT_CODE);

  const { t } = useTranslation(['common', 'settings']);

  useEffect(() => {
    if (!code || user.integrations.billit) {
      navigate(routes.settingsIntegrations);
      return;
    }

    authorizeBillit(code, {
      onSuccess: () => toast.success(t('settings:alerts.integrations.successBillitPaired')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => navigate(routes.settingsIntegrations),
    });
  }, [authorizeBillit, code, navigate, t, user.integrations.billit]);

  return (
    <section className={styles['loading-wrapper']}>
      <div className={styles.spinner}>
        <SpinnerLogo />
      </div>
      <h2>{t('settings:integrations.processing')}</h2>
    </section>
  );
};
