import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';
import { useLocation } from 'react-router';
import { match } from 'ts-pattern';

import { useHeartbeat } from '~/api/application';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './AuthLayout.styles';
import { AuthLayoutFooter } from './AuthLayoutFooter';
import { AuthLayoutHeader } from './AuthLayoutHeader';
import { AuthLayoutSidebar } from './AuthLayoutSidebar';

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  useHeartbeat();

  const location = useLocation();

  return (
    <div {...stylex.props(styles.authLayout)}>
      <AuthLayoutHeader />

      <AuthLayoutSidebar
        variant={match(location.pathname)
          .with(routes.register, () => 'kiia' as const)
          .with(routes.registerAccountInformation, () => 'testimonials' as const)
          .otherwise(() => 'checklist' as const)}
      />

      {children}

      <AuthLayoutFooter />
    </div>
  );
};
